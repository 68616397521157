import React, { Fragment } from "react";
import { ProgressBar, Image, Glyphicon } from "react-bootstrap";
// import PdfImage from "../../assets/img/pdf-img.png"
import { getExtensionNameWrapper } from "../ReusableFunctions";

const FilePreview = props => {
    const { classes, files, uploadProgress, columns, onRemove } = props;
    return (
        files.map(fileInfo => {
            const progress = uploadProgress[fileInfo.id];
            // const url = fileInfo.mimeType === "application/pdf" ? PdfImage : fileInfo.url;
            console.log('fileInfo', fileInfo);
            let attachmentWrapper = getExtensionNameWrapper(fileInfo.name, fileInfo.url, "option-card-extension ");

            return (
                <Fragment>
                    {
                        !progress &&
                        <div className="uploaded-img-wrapper">
                            {/* <Image src={url} responsive className="image-preview" /> */}
                            {attachmentWrapper}
                            <Glyphicon glyph="remove-circle" onClick={onRemove} />
                        </div>
                    }
                    {
                        progress && (
                            <ProgressBar
                                striped
                                bsStyle="info"
                                now={progress}
                            />
                        )
                    }
                </Fragment>
            )
        })
    )
}

export default FilePreview