import React, { useState, useRef } from 'react';
import Slider from "react-slick";
import { materialColors } from "../../../utils/Constant"
import Favourite from './Favourite';
import { Image } from 'react-bootstrap';
import leftArrowIcon from '../../../assets/icons/product-left-nav.svg';
import rightArrowIcon from '../../../assets/icons/product-right-nav.svg';
import { getToken } from '../../../utils/ManageToken';


const ProductItem = ({ product }) => {
  const productRef = useRef(null);

  const defaultValue = product.material_colour_list.findIndex(item => item.is_default);
  const [selectedColorIndex, setSelectedColorIndex] = useState(defaultValue);

  const [playSlider, setPlaySlider] = useState(false);

  // if (product.sku_number === '1121415002078') {
  //   console.log("selected color index", selectedColorIndex);
  //   console.log("default color value", defaultValue);
  //   console.log("color orders", product.material_colour_list);
  // }

  const productSliderSettings = {
    dots: true,
    arrows: true,
    autoplaySpeed: 4000,
    autoplay: playSlider,
    pauseOnHover: false,
    nextArrow: <Arrow img={rightArrowIcon} />,
    prevArrow: <Arrow img={leftArrowIcon} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          dots: false,
          swipe: false,
        }
      },
    ]
  }

  const changeColorHandle = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedColorIndex(index);
    setPlaySlider(true);
  }

  function Arrow(props) {
    const { className, style, onClick, img } = props;
    return (
      <Image
        src={img}
        className={className}
        style={{ ...style, width: '3rem', height: '3rem', backgroundColor: 'white' }}
        onClick={onClick}
      />
    );
  }

  return (
    <div
      className="product-item-wrapper"
      onMouseEnter={() => productRef.current.slickPlay()}
      onMouseLeave={() => productRef.current.slickPause()}
    >
      <Slider
        {...productSliderSettings}
        ref={productRef}
      >
        {
          product.material_colour_list[selectedColorIndex].images.map((slide, slide_index) => (
            <div key={slide_index}>
              <img src={slide} alt="" />
            </div>
          ))}
      </Slider>
      <h4>{product.sku_number}</h4>
      <h5>{product.name}</h5>
      {
        getToken() &&
        <h6>{product.currency + product.costing}</h6>
      }
      <div className="colors-wrapper">
        {
          product.material_colour_list.map((item, index) => (
            <span
              key={index}
              onClick={(e) => changeColorHandle(e, index)}
              className={selectedColorIndex === index ?
                `${materialColors.getText(item.material_colour).toLowerCase()}-color active`
                :
                `${materialColors.getText(item.material_colour).toLowerCase()}-color`
              }
            >
            </span>
          ))
        }
      </div>
      <Favourite productSku={product.sku_number} isFavourite={product.is_favourite} />
    </div>
  );
};

export default ProductItem;