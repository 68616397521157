import React from 'react';
import { CustomNavbar, CustomButton } from '../common';
import { Image } from 'react-bootstrap';
import PaymentSuccessImage from '../../assets/images/payment-success-illustration.svg';
import PaymentFailureImage from '../../assets/images/payment-failed-illustration.svg';

const PaymentStatus = props => {
  const orderObj = {
    success: props.location.state.success,
    orderId: props.location.state.orderId
  }
  // const { success, orderId } = props.location.state;
  const { success, orderId } = orderObj;
  return (
    <>
      <CustomNavbar hideCategories={true} />
      {
        success ?
          <div className="payment-status-wrapper">
            <Image src={PaymentSuccessImage} />
            <h5 className="h2-desktop">Order Placed Successfully!</h5>
            <h6 className="subtitle-desktop">
              {`Your order has been placed successfully. Your reference Order Id is ${orderId}`}
            </h6>
            <div className="buttons-wrapper">
              <CustomButton href="/" inverse>Back to home</CustomButton>
              <CustomButton href={`/profile/order/${orderId}`}>Track order</CustomButton>
            </div>
          </div>
          :
          <div className="payment-status-wrapper">
            <Image src={PaymentFailureImage} />
            <h5 className="h2-desktop">Payment Failed!</h5>
            <h6 className="subtitle-desktop">
              Your order has been not been placed. Please try again.
            </h6>
            <div className="buttons-wrapper">
              <CustomButton href="/" inverse>Back to home</CustomButton>
              {/* <CustomButton>Retry</CustomButton> */}
            </div>
          </div>
      }
    </>
  );
};

export default PaymentStatus;