import { getHomePageData } from "./HomeAction";
import { PreLoginInstance, PostLoginInstance } from "../../utils";
import { getToken } from "../../utils/ManageToken";

export const getHomePageDataApi = () => {
  const instance = getToken() ? PostLoginInstance : PreLoginInstance;
  return function (dispatch) {
    return instance.post('customer/home_page/get-home-page-data')
      .then(res => {
        if (!res.data.error) {
          dispatch(getHomePageData(res.data.data));
        } else {
          console.log("Error ", res.data.error)
        }
        return res;
      }).catch(err => {
        console.log("error", err);
      });
  };
}