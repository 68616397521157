import { PreLoginInstance, PostLoginInstance } from "../../utils";
import { toast } from 'react-toastify';


export const signupApi = (data, cb) => {
  PreLoginInstance.post('/customer/account/create-account', data)
    .then(res => {
      if (!res.data.error) {
        cb();
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err);
      toast.error(err.message);
    });
}


export const getAccountDetailsApi = (cb) => {
  PostLoginInstance.post('/customer/account/get-account-details')
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err);
      toast.error(err.message);
    });
}


export const updateAccountDetailsApi = (data, cb) => {
  PostLoginInstance.post('/customer/account/update-account-details', data)
    .then(res => {
      if (!res.data.error) {
        cb();
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err);
      toast.error(err.message);
    });
}
