import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { getCartDataApi } from '../Api';
import { connect } from 'react-redux';
import { toggleCart, toggleLoginSidebar } from '../CommonAction';
import buynowIcon from '../../../assets/icons/icon-buynow-button.svg'
import { getToken } from '../../../utils/ManageToken';
import emptyCartImage from '../../../assets/images/empty-cart-illustration.svg';
import EmptyState from './EmptyState';
import { withRouter } from "react-router-dom";
import CartItem from './CartItem';


const Cart = props => {

  useEffect(() => {
    if (getToken())
      props.getCartDataApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { cartData } = props.commonState;
  return (
    <>

      <div className="cart-wrapper">

        {
          getToken() ?
            cartData && cartData.cart_items.length > 0 ?
              // when logged in and cart is filled
              <>
                <h4 className="title">Cart</h4>
                <div className="cart-wrapper-item">
                  {
                    cartData.cart_items.map((item) => (
                      <CartItem product={item} key={item.code} />
                    ))
                  }
                </div>
                <div style={{ flex: 1 }}></div>
                <div className="btn-wrapper sticky-bottom-buttons">
                  <CustomButton
                    className="buy-now-button"
                    onClick={() => { props.toggleCart(); props.history.push('/buy/address') }}>
                    <Image src={buynowIcon} />  Buy now
                  </CustomButton>
                </div>
              </>
              :
              // when logged in but cart is empty
              <EmptyState
                title="Your cart is empty!"
                img={emptyCartImage} />
            :
            // when NOT logged in
            <EmptyState
              title="Login to Add Products!"
              img={emptyCartImage}
              buttonText="Login"
              buttonHandle={() => { props.toggleCart(); props.toggleLoginSidebar() }} />
        }
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  commonState: state.CommonState
});

const mapDispatchToProps = {
  getCartDataApi,
  toggleCart,
  toggleLoginSidebar
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));