import React, { useState } from 'react';
import deleteIcon from '../../../assets/icons/cart-delete-icon.svg'
import { Glyphicon, Image, Modal } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { updateCartItemQuantityApi, deleteCartItemApi } from '../Api';
import { connect } from 'react-redux';
import { toggleEditCartSidebar, toggleCart } from '../CommonAction';



const CartItem = ({ product, ...props }) => {

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const updateCartItemQuantity = (code, quantity) => {
    const data = new URLSearchParams();
    data.append("code", code);
    data.append("quantity", quantity);
    props.updateCartItemQuantityApi(data);
  }

  const toggleDeleteConfirmation = (id) => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
    setDeleteId(id);
  }

  const deleteItem = () => {
    const data = new URLSearchParams();
    data.append("code", deleteId);
    props.deleteCartItemApi(data, () => toggleDeleteConfirmation(null));
  }

  return (
    <>
      {
        showDeleteConfirmation &&
        <Modal
          show={showDeleteConfirmation}
          onHide={() => toggleDeleteConfirmation(null)}
          dialogClassName="custom-modal delete-cart-item-modal">
          <Modal.Body>
            <h5 className="h2-desktop">Are you sure?</h5>
            <h6 className="paragraph-desktop">This item will be removed from cart.</h6>
            <CustomButton onClick={deleteItem}>Yes</CustomButton>
            <CustomButton inverse onClick={() => toggleDeleteConfirmation(null)}>No</CustomButton>
          </Modal.Body>
        </Modal>
      }

      <div className="cart-item"
        onClick={() => { props.commonState.showCart && props.toggleCart(); props.toggleEditCartSidebar(product.code) }}>
        <Image className="delete-icon" src={deleteIcon} onClick={(e) => {
          e.stopPropagation();
          toggleDeleteConfirmation(product.code);
        }} />
        <Image src={product.images[0]} responsive className="cart-product-img" />
        <h4 className="product-code">{product.sku}</h4>
        <h3 className="product-card-title">{product.material.name} {product.name}</h3>

        <h5 className="dark-desktop">
          $ {Number(product.total_price_per_unit * product.quantity).toFixed(2)}
        </h5>
        <div className="quantity-wrapper">
          <Glyphicon glyph="plus" onClick={(e) => {
            e.stopPropagation();
            updateCartItemQuantity(product.code, Number(product.quantity) + 1)
          }} />
          <span className="product-card-title quantity">{product.quantity}</span>
          <Glyphicon glyph="minus" onClick={(e) => {
            e.stopPropagation();
            if (product.quantity > 1)
              updateCartItemQuantity(product.code, product.quantity - 1)
          }} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  commonState: state.CommonState
});

const mapDispatchToProps = {
  updateCartItemQuantityApi,
  deleteCartItemApi,
  toggleEditCartSidebar,
  toggleCart
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);