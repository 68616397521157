import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, ProductItem, Pagination, PageHeader, EmptyState } from '../common';
import { Grid } from "react-bootstrap";
import { getDigitalStoreApi } from './Api';
import { Link } from 'react-router-dom';
import { getPageCount } from "../../utils/ReusableFunctions";
import { getToken } from '../../utils/ManageToken';
import emptyDigitalStore from '../../assets/images/digital-store-empty-state.svg';
import { toggleLoginSidebar } from '../common/CommonAction';

class DigitalStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      digitalStore: null,
      totalPages: null,
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'My Digital Store',
          active: true
        },
      ]
    }
  }

  componentDidMount() {
    if (getToken()) {
      // Call Favourite data on load if user is logged in
      this.getDigitalStoreData();
    }
  }

  getDigitalStoreData = () => {
    const data = new URLSearchParams(this.props.location.search);
    data.append('page_no', data.get("p") || 1);
    getDigitalStoreApi(data, this.digitalStoreCallback);
  }

  componentDidUpdate(prevProps) {
    // search string inside location is unique for every new params in routes.
    if (this.props.location.search !== prevProps.location.search) {
      // Call Favourite data when page number changes
      this.getDigitalStoreData();
    }
  }

  digitalStoreCallback = (data) => {
    this.setState({
      digitalStore: data.products,
      totalPages: getPageCount(data.products_count)
    })
  }


  render() {
    const params = new URLSearchParams(this.props.location.search);
    const { totalPages, digitalStore } = this.state;
    return (
      <>

        <CustomNavbar />
        <Grid>
          <PageHeader title="My Digital Store" breadcrumbData={this.state.breadcrumbData} />
          {
            getToken() ?

              digitalStore && digitalStore.length > 0 ?
                <>
                  <p style={{ color: '#d4b195', textAlign: 'right' }}>*Retail Prices Displayed.</p>

                  <div className="digital-store">
                    {
                      digitalStore.map((product) => (
                        <Link to={`/product/${product.permalink}`} key={product.product_id}>
                          <ProductItem product={product} />
                        </Link>
                      ))
                    }
                  </div>
                </>
                :
                <EmptyState
                  title="No products found!"
                  subtitle="You haven’t marked any product to your digital store."
                  img={emptyDigitalStore} />

              :
              <EmptyState
                title="Login to view ‘My Digital Store’!"
                img={emptyDigitalStore}
                buttonText="Login"
                buttonHandle={this.props.toggleLoginSidebar} />
          }

          {
            totalPages && totalPages > 1 &&
            <Pagination
              currentPage={params.get("p") ? Number(params.get("p")) : 1}
              totalPages={totalPages}
              history={this.props.history}
            />
          }
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  digitalStoreState: state.DigitalStoreState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  toggleLoginSidebar,
}


export default connect(mapStateToProps, mapDispatchToProps)(DigitalStore);