import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { CustomButton, CustomNavbar } from '../common';
import { CustomTextControl, Form, FormElement, FormSubmitButton, FormValidator, BaseReactComponent, RadioGroupControl } from '../../utils/form';
import { Row, Col, Grid } from 'react-bootstrap';
import { addUpdateMarkupApi, deleteMarkupApi, getMarkupApi } from './Api';

class Markups extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      markupType: "1",
      isMarkupAvailable: false,
      suggestions: [
        {
          label: '1.50X',
          value: '1.50'
        },
        {
          label: '2X',
          value: '2'
        },
        {
          label: '2.25X',
          value: '2.25'
        },
        {
          label: '2.50X',
          value: '2.50'
        },
        {
          label: '3X',
          value: '3'
        }
      ]
    }
  }

  componentDidMount() {
    getMarkupApi(this.getMarkupCallBack)
  }

  getMarkupCallBack = (data) => {
    if (data && data.length > 0)
      if (data[0].type === 1) {
        this.setState({
          markupType: "1",
          markupValue: data[0].markup,
          isMarkupAvailable: true,
        })
      } else {
        let dataObj = {}
        data.map(item => {
          dataObj[`MarkupValue-${item.category_id}`] = item.markup
        })
        this.setState({
          markupType: "2",
          isMarkupAvailable: true,
          ...dataObj
        })
      }
    else {
      this.setState({
        markupType: "1",
        markupValue: "",
        isMarkupAvailable: false
      })
    }
  }

  suggestionPills = (key) => {
    return <div className="markup-suggestions-wrapper">
      {
        this.state.suggestions.map(item => (
          <span
            onClick={() => this.setState({ [key]: item.value })}
            className={`suggestions-pill ${Number(this.state[key]) === item.value ? "active" : ""}`}>
            {item.label}
          </span>
        ))
      }
    </div>
  }

  onValidSubmit = () => {
    const data = new URLSearchParams();
    data.append('markup_type', this.state.markupType);
    if (this.state.markupType === "1") {
      data.append('markup_details', JSON.stringify([
        {
          markup: this.state.markupValue
        }
      ]))
    } else {
      data.append('markup_details', JSON.stringify(
        this.props.commonState.categoryData.categories_and_tags.map(category => (
          {
            category_id: category.id,
            markup: this.state[`MarkupValue-${category.id}`]
          }
        ))
      ))
    }

    addUpdateMarkupApi(data, this);
  }

  render() {
    return (
      <>
        <CustomNavbar />
        <Grid>
          <div className="markups-wrapper">
            <h3 className="h3-desktop">Markup Details</h3>
            <Form onValidSubmit={this.onValidSubmit}>
              <FormElement
                valueLink={this.linkState(this, "markupType")}
                label="Markup Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Markup type cannot be empty"
                  }
                ]}
                control={{
                  type: RadioGroupControl,
                  settings: {
                    columns: 1,
                    options: [
                      {
                        key: 1,
                        label: "Set Default Markup"
                      },
                      {
                        key: 2,
                        label: "Set Markup Category Wise"
                      }
                    ]
                  }
                }}
              />
              {
                this.state.markupType === "1" ?
                  <Row>
                    <Col md={5}>
                      <FormElement
                        valueLink={this.linkState(this, "markupValue")}
                        label="Markup Multiple (X)"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          },
                          {
                            validate: FormValidator.isPositiveDecimal,
                            message: "Enter a positive decimal number"
                          },
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Example - 120",
                          }
                        }}
                      />
                      {
                        this.suggestionPills("markupValue")
                      }

                    </Col>
                  </Row>
                  :
                  this.props.commonState.categoryData &&
                  this.props.commonState.categoryData.categories_and_tags.map(category => (
                    <Row>
                      <Col md={5} key={category.id}>
                        <FormElement
                          valueLink={this.linkState(this, `MarkupValue-${category.id}`)}
                          label={`${category.name} Markup Multiple (X)`}
                          required
                          validations={[
                            {
                              validate: FormValidator.isRequired,
                              message: "This field is mandatory"
                            },
                            {
                              validate: FormValidator.isPositiveDecimal,
                              message: "Enter a positive decimal number"
                            },
                          ]}
                          control={{
                            type: CustomTextControl,
                            settings: {
                              placeholder: "Example - 120",
                            }
                          }}
                        />
                        {
                          this.suggestionPills(`MarkupValue-${category.id}`)
                        }

                      </Col>
                    </Row>
                  ))
              }
              {
                this.state.isMarkupAvailable &&
                <CustomButton inverse onClick={() => deleteMarkupApi(this.getMarkupCallBack)}>Reset</CustomButton>
              }
              <FormSubmitButton customClass="custom-btn" formError={this.state.inProgress}
                progressMessage="Validating..">
                Save
              </FormSubmitButton>

            </Form>
          </div>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  markupsState: state.MarkupsState,
  commonState: state.CommonState,
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
}
Markups.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Markups);