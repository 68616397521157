import React from 'react';
import { Modal } from 'react-bootstrap';

const VideoModal = props => {
  return (
    <Modal show={props.show} onHide={props.handleClose} dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <video src={props.video} autoPlay controls />
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;