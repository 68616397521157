import { CLEAR_SEARCH_PRODUCTS, GET_SEARCH_PRODUCTS } from "./ActionTypes";
import { getPageCount } from "../../utils/ReusableFunctions";

const INITIAL_STATE = {
    searchProducts: null
};
const SearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SEARCH_PRODUCTS:
            return {
                ...state,
                searchProducts: action.payload.products,
                totalPages: getPageCount(action.payload.total_product_count)
            };
        case CLEAR_SEARCH_PRODUCTS:
            return {
                ...state,
                searchProducts: null,
                totalPages: null
            };
        default:
            return state
    }
};
export default SearchReducer