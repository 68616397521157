import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from "react-select";
import { Checkbox, FormControl, HelpBlock, FormGroup } from "react-bootstrap";
import { setCustomizationOption } from './ProductDetailsAction';
import { CustomButton } from '../common';
import { Glyphicon } from 'react-bootstrap';
import { getToken } from '../../utils/ManageToken';
import { RingSizeOptions } from '../../utils/Constant';


class CustomizationOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedValidation: false,
    }
  }

  componentDidMount() {

  }

  handleSelectChange = (selectedOption, { name }) => {
    this.props.setCustomizationOption({
      name,
      value: selectedOption
    })
  }

  handleCheckbox = (e) => {
    const { name } = e.target;
    this.props.setCustomizationOption({
      name,
      value: !this.props.productDetailsState[name]
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.props.setCustomizationOption({
      name,
      value
    })
  }

  validate = () => {
    const { laserEngravingName, laserEngraving } = this.props.productDetailsState;
    if (laserEngraving) {
      // laser engraving is checked
      if (laserEngravingName.trim()) {
        this.setState({ failedValidation: false }, this.props.addProductHandle);
      } else {
        this.setState({ failedValidation: true });
      }
    } else {
      this.props.addProductHandle();
    }
  }

  showCertificates = (e) => {
    e.preventDefault();
    this.props.toggleCertificateModal();
  }



  render() {

    const { productDetailsState } = this.props;

    return (
      <>
        <div className={this.props.page ? "cus-opt-wrapper page" : "cus-opt-wrapper"}>
          <div className={this.props.page ? "cus-opt page" : "cus-opt"} >
            <h6 className={this.props.page ? "page" : ""}>Customization Options</h6>
            <div className="select-wrapper">
              {
                productDetailsState.centerStone &&
                <>
                  <label>Center Stone Quality</label>
                  <Select
                    className="cus-opt-btn custom-select-container"
                    classNamePrefix="custom-select"
                    value={productDetailsState.centerStone}
                    isClearable={false}
                    isSearchable={false}
                    name="centerStone"
                    options={this.props.centerStoneOptions}
                    onChange={this.handleSelectChange}
                    hideSelectedOptions={true}
                    components={{ IndicatorSeparator: () => null }}
                  />
                </>
              }

              {
                productDetailsState.meleeStone &&
                <>
                  <label>Melee Stone Quality</label>
                  <Select
                    className="cus-opt-btn custom-select-container"
                    classNamePrefix="custom-select"
                    value={productDetailsState.meleeStone}
                    isClearable={false}
                    isSearchable={false}
                    name="meleeStone"
                    options={this.props.meleeStoneOptions}
                    onChange={this.handleSelectChange}
                    hideSelectedOptions={true}
                    components={{ IndicatorSeparator: () => null }}
                  />
                </>
              }
              {
                productDetailsState.productDetails.size_guide_required &&
                <>
                  {
                    this.props.page &&
                    <span className="size-guide-link" onClick={this.props.sizeGuideHandle}>Size Guide</span>
                  }
                  <label>Ring Size</label>
                  <Select
                    className="cus-opt-btn custom-select-container"
                    classNamePrefix="custom-select"
                    value={productDetailsState.ringSize}
                    isClearable={false}
                    isSearchable={false}
                    name="ringSize"
                    options={RingSizeOptions}
                    onChange={this.handleSelectChange}
                    hideSelectedOptions={true}
                    components={{ IndicatorSeparator: () => null }}
                  />
                </>
              }


            </div>
            {
              this.props.page ?
                null :
                (
                  <>
                    {
                      productDetailsState.productDetails.is_laser_engraving &&
                      <Checkbox
                        onChange={this.handleCheckbox}
                        name="laserEngraving"
                        checked={productDetailsState.laserEngraving}
                      >
                        <span className="checkmark"></span>
                        <span>Laser Engraving - ${productDetailsState.productDetails.cost_of_laser_engraving} Additional</span>
                      </Checkbox>
                    }

                    {
                      productDetailsState.laserEngraving &&
                      <FormGroup validationState={this.state.failedValidation ? "error" : null}>
                        <FormControl
                          type="text"
                          value={productDetailsState.laserEngravingName}
                          name="laserEngravingName"
                          placeholder="Max 10 Characters"
                          onChange={this.handleChange}
                        />
                        {
                          this.state.failedValidation &&
                          <HelpBlock type="invalid">
                            This field is mandatory
                          </HelpBlock>
                        }

                      </FormGroup>
                    }
                    <Checkbox
                      onChange={this.handleCheckbox}
                      name="certificationCharge"
                      checked={productDetailsState.certificationCharge}
                    >
                      <span className="checkmark"></span>
                      <span>Certification Charge - ${productDetailsState.productDetails.credit_card_certificate} Additional</span>
                      <Glyphicon glyph="info-sign" onClick={this.showCertificates} />
                    </Checkbox>
                    <label className="note">Personal Note</label>
                    <FormControl
                      value={productDetailsState.personalNote}
                      onChange={this.handleChange}
                      name="personalNote"
                      componentClass="textarea"
                      placeholder="Please include any information to reference this order for your personal use"
                      rows={5} />

                    <div className="quantity-wrapper">
                      <h5>Quantity</h5>
                      <div className="quantity">
                        <Glyphicon glyph="plus" onClick={() =>
                          this.props.setCustomizationOption({
                            name: 'quantity',
                            value: productDetailsState.quantity + 1
                          })
                        } />
                        <span className="product-card-title">{productDetailsState.quantity}</span>
                        <Glyphicon glyph="minus" onClick={() => {
                          if (productDetailsState.quantity > 1)
                            this.props.setCustomizationOption({
                              name: 'quantity',
                              value: productDetailsState.quantity - 1
                            })
                        }} />
                      </div>
                    </div>


                  </>
                )
            }
          </div>
          <div style={{ flex: 1 }}></div>
          {
            !this.props.page &&
            <div className="bottom-button-wrapper sticky-bottom-buttons">
              <div className="total-price">
                {
                  getToken() &&
                  <>
                    <span>Total</span>
                    <h5 className="product-price">${productDetailsState.totalCost}</h5>
                  </>
                }
              </div>
              <CustomButton onClick={this.validate}>
                {productDetailsState.customizationButtonText}
              </CustomButton>
            </div>
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  commonState: state.CommonState,
  productDetailsState: state.ProductDetailsState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  setCustomizationOption,
}



export default connect(mapStateToProps, mapDispatchToProps)(CustomizationOption);
