import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, ProductItem, BreadCrumb, Pagination, CustomButton, EmptyState } from '../common';
import Filter from './Filter';
import { Link } from "react-router-dom";
import Select from "react-select";
import { SortBy, WeightRangeType, StoneShape, StoneSize } from '../../utils/Constant';
import { getProductListApi } from './Api';
import { handleParamsWithMultipleValue } from '../../utils/ReusableFunctions'
import { toggleLoginSidebar } from '../common/CommonAction';
import { Glyphicon } from "react-bootstrap";
import MobileFilter from './MobileFilter';
import { clearProductList, isProductListServerApiCalled } from './ProductListAction';
import MobileSort from './MobileSort';
import emptyProductsState from "../../assets/images/product-list-empty-state.svg";
import { getCategoryDataApi } from '../common/Api';
import { FilterProductsBy } from "../../utils/Constant";
import SigninRibbon from '../common/_utils/SigninRibbon';
import { getToken } from '../../utils/ManageToken';

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileFilter: false,
      showMobileSort: false,
    }
  }

  componentDidMount() {
    // This will be true for the first time when loaded from server, in that case dont call api
    // Else call the api
    if (!this.props.productListState.isProductListServerApiCalled) {
      console.log("Inside did mount 1");
      this.productList();
    }
    // When rendered from server and switched to client side make it false explicitely
    if (this.props.productListState.isProductListServerApiCalled) {
      console.log("Inside did mount false");
      this.props.isProductListServerApiCalled(false);
    }

  }

  static loadData(match, dispatch) {
    const path = match.url.split("?");
    // If path array length is greater than 1 means 2nd argument has search params
    const data = new URLSearchParams(path.length > 0 ? path[1] : "");
    if (data.get('ct_weight_range_type')) {
      data.set('ct_weight_range_type', handleParamsWithMultipleValue(data.get('ct_weight_range_type')));
    }
    if (data.get('stone_shapes')) {
      data.set('stone_shapes', handleParamsWithMultipleValue(data.get('stone_shapes')));
    }
    if (data.get('stone_sizes')) {
      data.set('stone_sizes', handleParamsWithMultipleValue(data.get('stone_sizes')));
    }
    data.append('page_no', data.get("p") || 1);

    if (!match.serverParams.categoryId) {
      if (!data.get('sort_by')) {
        // SortBy[3] represents New Arrival value.
        data.append('sort_by', (Number(data.get("sort_by")) || SortBy[3].value));
      }
    }

    if (match.serverParams.subcategory) {
      // For product list by sub category i.e from navbar menu
      data.append('tag_permalink', match.serverParams.subcategory);
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    } else if (match.serverParams.stoneId) {
      // For product list by stone on home page
      data.append('stone_shape', match.serverParams.stoneId);
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    } else if (match.serverParams.collectionPermalink) {
      // For product list by collection
      data.append('collection_permalink', match.serverParams.collectionPermalink);
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    } else if (match.serverParams.categoryId) {
      // Split the ID & filter by paramter from URL
      let [categoryID, filterBy] = match.serverParams.categoryId.split("-");
      data.append('category_id', categoryID);
      data.append('filter_by', filterBy);
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    } else if (match.serverParams.categoryIdOnly) {
      // on clicking category name from navbar
      data.append('category_id', match.serverParams.categoryIdOnly);
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    } else if (match.serverParams.navStoneId) {
      // For product list by stone in navbar
      data.append('stone_shape', match.serverParams.navStoneId);
      data.append('category_id', match.serverParams.navCategoryId);
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    } else {
      return Promise.all([dispatch(getProductListApi(data)), dispatch(getCategoryDataApi())]);
    }
  }

  productList = () => {
    const { params } = this.props.match;
    const data = new URLSearchParams(this.props.location.search);
    if (data.get('ct_weight_range_type')) {
      data.set('ct_weight_range_type', handleParamsWithMultipleValue(data.get('ct_weight_range_type')));
    }
    if (data.get('stone_shapes')) {
      data.set('stone_shapes', handleParamsWithMultipleValue(data.get('stone_shapes')));
    }
    if (data.get('stone_sizes')) {
      data.set('stone_sizes', handleParamsWithMultipleValue(data.get('stone_sizes')));
    }
    data.append('page_no', data.get("p") || 1);

    if (!params.categoryId) {
      if (!data.get('sort_by')) {
        // SortBy[3] represents New Arrival value.
        data.append('sort_by', (Number(data.get("sort_by")) || SortBy[3].value));
      }
    }

    if (params.subcategory) {
      // For product list by sub category i.e from navbar menu
      data.append('tag_permalink', params.subcategory);
      this.props.getProductListApi(data);
    } else if (params.stoneId) {
      // For product list by stone on home page
      data.append('stone_shape', params.stoneId);
      this.props.getProductListApi(data);
    } else if (params.collectionPermalink) {
      // For product list by collection
      data.append('collection_permalink', params.collectionPermalink);
      this.props.getProductListApi(data);
    } else if (params.categoryId) {
      // Split the ID & filter by paramter from URL
      let [categoryID, filterBy] = params.categoryId.split("-");
      data.append('category_id', categoryID);
      data.append('filter_by', filterBy);
      this.props.getProductListApi(data);
    } else if (params.categoryIdOnly) {
      // on clicking category name in navbar
      data.append('category_id', params.categoryIdOnly);
      this.props.getProductListApi(data);
    } else if (params.navStoneId) {
      // for product list by stone in navbar
      data.append('stone_shape', params.navStoneId);
      data.append('category_id', params.navCategoryId);
      this.props.getProductListApi(data);
    } else {
      // For new arrival page
      this.props.getProductListApi(data);
    }
    if (window) window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  componentDidUpdate(prevProps) {
    // key inside location is unique for every new params in routes.
    if (this.props.location.search !== prevProps.location.search || this.props.location.pathname !== prevProps.location.pathname) {
      console.log("Inside product list");
      this.productList();
    }
  }

  showMobileFilter = () => {
    this.setState({ showMobileFilter: !this.state.showMobileFilter });
  }

  showMobileSort = () => {
    this.setState({ showMobileSort: !this.state.showMobileSort });
  }

  handleSortChange = (selectedOption) => {
    const params = new URLSearchParams(this.props.location.search);
    params.set("sort_by", selectedOption.value);
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: params.toString()
    })
  }

  formatLabel = (data) => {
    const params = new URLSearchParams(this.props.location.search);
    return (
      <span>
        {
          /* eslint eqeqeq: 0 */
          data.value == (params.get("sort_by") || 4) ? `Sort: ${data.label}` : data.label
        }
      </span>
    )
  }

  handleClearFilter = (name, value) => {
    const filterVal = value.toString();
    const params = new URLSearchParams(this.props.location.search);
    let newValue = params.get(name).split(",").filter(val => val !== filterVal);
    if (newValue.length > 0) {
      params.set(name, newValue.join(","));
    } else {
      params.delete(name);
    }
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: params.toString()
    })
  }

  showSelectedFilter = () => {
    const selectedFilter = [];
    const data = new URLSearchParams(this.props.location.search);
    if (data.get('ct_weight_range_type')) {
      JSON.parse(handleParamsWithMultipleValue(data.get('ct_weight_range_type'))).forEach(val => {
        selectedFilter.push(
          <div className="filter-summary light-desktop">
            {WeightRangeType.getText(val)}<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('ct_weight_range_type', val)} />
          </div>
        )
      });
    }
    if (data.get('stone_shapes')) {
      JSON.parse(handleParamsWithMultipleValue(data.get('stone_shapes'))).forEach(val => {
        selectedFilter.push(
          <div className="filter-summary light-desktop">
            {StoneShape.getText(val)}<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('stone_shapes', val)} />
          </div>
        )
      });
    }
    if (data.get('stone_sizes')) {
      JSON.parse(handleParamsWithMultipleValue(data.get('stone_sizes'))).forEach(val => {
        selectedFilter.push(
          <div className="filter-summary light-desktop">
            {StoneSize.getText(val)}<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('stone_sizes', val)} />
          </div>
        )
      });
    }
    if (data.get('is_lab_grown')) {
      selectedFilter.push(
        <div className="filter-summary light-desktop">
          Lab-Grown Diamonds<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('is_lab_grown', true)} />
        </div>
      )
    }
    if (data.get('has_video')) {
      selectedFilter.push(
        <div className="filter-summary light-desktop">
          Video Available<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('has_video', true)} />
        </div>
      )
    }
    return selectedFilter;
  }

  render() {

    const params = new URLSearchParams(this.props.location.search);
    const { params: matchParams } = this.props.match;

    let sortByValue;
    // If category id is present then dont calculate sort by as we need to hide from UI
    matchParams.categoryId ? sortByValue = null :
      sortByValue = params.get("sort_by") ? SortBy.find(item => item.value == params.get("sort_by")) : SortBy[3];
    const { productList, totalPages, categoryDetails, collectionName } = this.props.productListState;

    const breadCrumb = [{ text: 'home', url: '/' }];
    if (matchParams.category) {
      breadCrumb.push({ text: categoryDetails ? categoryDetails.name : "" });
      breadCrumb.push({ text: categoryDetails ? categoryDetails.tag_name : "", active: true });
    } else if (matchParams.collectionPermalink) {
      breadCrumb.push({ text: collectionName, active: true });
    } else if (matchParams.stoneId) {
      breadCrumb.push({ text: StoneShape.getText(matchParams.stoneId), active: true })
    } else if (matchParams.navStoneId) {
      let categoryName = "";
      if (this.props.commonState.categoryData)
        categoryName = this.props.commonState.categoryData.categories_and_tags.find(item => item.id.toString() === matchParams.navCategoryId).name;
      breadCrumb.push({ text: categoryName, url: `/products/category/${matchParams.navCategoryId}` })
      breadCrumb.push({ text: StoneShape.getText(matchParams.navStoneId), active: true })
    } else if (matchParams.categoryId) {
      let [categoryID, filterBy] = matchParams.categoryId.split("-");
      let categoryName = "";
      if (this.props.commonState.categoryData)
        categoryName = this.props.commonState.categoryData.categories_and_tags.find(item => item.id.toString() === categoryID).name;
      breadCrumb.push({ text: categoryName, url: `/products/category/${categoryID}` })
      breadCrumb.push({ text: (FilterProductsBy.getText(filterBy) || "Newly Added"), active: true });
    } else if (matchParams.categoryIdOnly) {
      let categoryName = "";
      if (this.props.commonState.categoryData)
        categoryName = this.props.commonState.categoryData.categories_and_tags.find(item => item.id.toString() === matchParams.categoryIdOnly).name;
      breadCrumb.push({ text: categoryName, active: true })
    } else {
      breadCrumb.push({ text: "New Arrival", active: true });
    }


    return (
      <>
        <CustomNavbar />
        <SigninRibbon />
        {
          this.state.showMobileFilter &&
          <MobileFilter history={this.props.history} handleClose={this.showMobileFilter} matchParams={matchParams} />
        }
        {
          this.state.showMobileSort &&
          <MobileSort history={this.props.history} handleClose={this.showMobileSort} />
        }
        <div className="product-banner"
          style={categoryDetails ? { backgroundImage: `url(${categoryDetails.image_url})` } : {}}
        >

          <h2 className="subtitle-desktop">
            {
              matchParams.category ?
                categoryDetails ? categoryDetails.name : "" :
                matchParams.categoryId ?
                  (
                    this.props.commonState.categoryData ?
                      this.props.commonState.categoryData.categories_and_tags.find(item => item.id.toString() === matchParams.categoryId.split('-')[0]).name : ""
                  ) :
                  matchParams.navCategoryId ?
                    (
                      this.props.commonState.categoryData ?
                        this.props.commonState.categoryData.categories_and_tags.find(item => item.id.toString() === matchParams.navCategoryId.split('-')[0]).name : ""
                    ) :
                    null
            }
          </h2>

          <h1 className="h1">
            {
              matchParams.subcategory ?
                categoryDetails ? categoryDetails.tag_name : "" :
                matchParams.collectionPermalink ?
                  collectionName :
                  matchParams.stoneId ?
                    StoneShape.getText(matchParams.stoneId) :
                    matchParams.navStoneId ?
                      StoneShape.getText(matchParams.navStoneId) :
                      matchParams.categoryId ?
                        FilterProductsBy.getText(matchParams.categoryId.split("-")[1]) || "Newly Added" :
                        matchParams.categoryIdOnly ?
                          (
                            this.props.commonState.categoryData ?
                              this.props.commonState.categoryData.categories_and_tags.find(item => item.id.toString() === matchParams.categoryIdOnly).name : ""
                          )
                          : "New Arrival"
            }
          </h1>
        </div>
        <div className="custom-container">
          {
            !this.props.commonState.isMobile ?
              <div className="top-wrapper">
                <div className="breadcrumb-wrapper">
                  <h6 className="custom-breadcrumb">
                    {<BreadCrumb data={breadCrumb} />}
                  </h6>
                </div>
                <div className="sort-wrapper">
                  <div className="left">
                    {this.showSelectedFilter().map(item => item)}
                  </div>
                  {
                    matchParams.categoryId ?
                      null :
                      <div className="right">
                        <Select
                          className="custom-select-container"
                          classNamePrefix="custom-select"
                          value={sortByValue}
                          isClearable={false}
                          isSearchable={false}
                          name="sort_by"
                          options={SortBy}
                          onChange={this.handleSortChange}
                          formatOptionLabel={this.formatLabel}
                          hideSelectedOptions={true}
                          components={{ IndicatorSeparator: () => null }}
                        />
                      </div>
                  }
                </div>
                <p className="product-desc">
                  *Default Price Listing in 14KT Gold &amp; Natural HI-I1 Diamonds.
                  <br /><span style={{ color: '#d4b195', fontWeight: 'bold' }}>*Retail Prices Displayed.</span>
                </p>
              </div>
              :
              <div className="mobile-btns sticky-mobile-bottom-buttons" style={!this.props.commonState.token && !getToken() ? { bottom: '9.5rem' } : {}}>
                {
                  matchParams.categoryId ?
                    null :
                    <CustomButton inverse onClick={this.showMobileSort}>Sort</CustomButton>
                }
                <CustomButton inverse onClick={this.showMobileFilter}>Filter</CustomButton>
              </div>
          }
          <div className="content-wrapper">
            {
              !this.props.commonState.isMobile ?
                <div className="filter-wrapper">
                  <Filter history={this.props.history} matchParams={matchParams} />
                </div>
                :
                null
            }
            <div className="main">
              {
                productList ?
                  productList.length > 0 ?
                    <div className="product-list">
                      {
                        productList.map((product) => (
                          <Link to={`/product/${product.permalink}`} key={product.product_id}>
                            <ProductItem product={product} />
                          </Link>
                        ))
                      }
                    </div>
                    :
                    <EmptyState
                      title="No Product Found!"
                      subtitle="Please adjust your filters to see more product."
                      img={emptyProductsState} />
                  :
                  null
              }
              {
                totalPages ?
                  totalPages > 1 ?
                    <Pagination
                      currentPage={params.get("p") ? Number(params.get("p")) : 1}
                      totalPages={totalPages}
                      history={this.props.history}
                    />
                    : null
                  : null
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  productListState: state.ProductListState,
  commonState: state.CommonState
});
const mapDispatchToProps = {
  getProductListApi,
  toggleLoginSidebar,
  clearProductList,
  isProductListServerApiCalled
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);