import React, { useState } from 'react';
import { PanelGroup, Panel, Glyphicon, Image } from 'react-bootstrap';
import { StoneShape } from '../../../utils/Constant';

const ProductsBought = ({ products }) => {

  const [openedIndex, setOpenedIndex] = useState(null);

  return (
    <div className="products-bought">
      <h3>Product Details</h3>
      <PanelGroup accordion id="accordion-example" onSelect={(eventKey) => setOpenedIndex(eventKey)}>
        {
          products.map((product, index) => {

            const {
              item_basic_details: basicDetails,
              item_customization_details: customizationDetails,
              item_stone_configuration_details: stoneConfigurations,
              item_stone_quality_details: stoneQuality,
            } = product;

            return <Panel eventKey={index} key={index}>
              <Panel.Heading>
                <Panel.Title toggle>
                  <div className="title-wrapper">
                    <Image src={customizationDetails.material_color.images[0]} />
                    <h4>
                      {customizationDetails.material.customization_value_text + ' '}
                      {customizationDetails.material_color.customization_value_text + ' '}
                      {basicDetails.product_name}
                    </h4>
                  </div>
                  <Glyphicon glyph={openedIndex === index ? "chevron-up" : "chevron-down"} />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <h2>Basic Details</h2>
                <div className="content-wrapper">
                  <div className="content-item">
                    <h5>Style Number/SKU Number</h5>
                    <h6>{basicDetails.product_sku}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Material</h5>
                    <h6>{customizationDetails.material.customization_value_text}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Color</h5>
                    <h6>{customizationDetails.material_color.customization_value_text}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Assembly in Lab-grown Diamonds?</h5>
                    <h6>{basicDetails.assembly_inlab_grown ? 'YES' : 'NO'}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Material Weight (gm)</h5>
                    <h6>{basicDetails.material_weight_grams}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Total Weight in CT</h5>
                    <h6>{basicDetails.total_weight_ct}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Ring size</h5>
                    <h6>{basicDetails.ring_size}</h6>
                  </div>
                  <div className="content-item">
                    <h5>Laser Engraving</h5>
                    <h6>
                      {
                        basicDetails.opted_for_laser_engraving ? 'YES ' : 'NO'
                      }
                      {
                        basicDetails.opted_for_laser_engraving &&
                        "(" + basicDetails.laser_engraving_message + ")"
                      }
                    </h6>
                  </div>
                </div>
                {
                  stoneConfigurations.primary && stoneConfigurations.primary.length > 0 &&
                  <>
                    <h2>Primary Stone Details</h2>
                    {
                      stoneConfigurations.primary.map(stone => (
                        <div className="content-wrapper">
                          <div className="content-item">
                            <h5>Primary Stone Shape</h5>
                            <h6>{StoneShape.getText(stone.stone_shape)}</h6>
                          </div>
                          <div className="content-item">
                            <h5>Primary Stone Size (CT)</h5>
                            <h6>{stone.stone_size_ct}</h6>
                          </div>
                          <div className="content-item">
                            <h5>Primary Stone Size (mm)</h5>
                            <h6></h6>
                          </div>
                          <div className="content-item">
                            <h5>No. of Stones</h5>
                            <h6>{stone.no_of_stones}</h6>
                          </div>
                          <div className="content-item">
                            <h5>Diamond Quality</h5>
                            <h6>{stone.stone_fleet_code}</h6>
                          </div>
                        </div>
                      ))
                    }
                  </>

                }

                {
                  stoneConfigurations.mele && stoneConfigurations.mele.length > 0 &&
                  <>
                    <h2>Melee Stone Details</h2>
                    {
                      stoneConfigurations.mele.map(stone => (
                        <div className="content-wrapper">
                          <div className="content-item">
                            <h5>Melee Stone Shape</h5>
                            <h6>{StoneShape.getText(stone.stone_shape)}</h6>
                          </div>
                          <div className="content-item">
                            <h5>Melee Stone Size (CT)</h5>
                            <h6>{stone.stone_size_ct}</h6>
                          </div>
                          <div className="content-item">
                            <h5>Melee Stone Size (mm)</h5>
                            <h6></h6>
                          </div>
                          <div className="content-item">
                            <h5>No. of Stones</h5>
                            <h6>{stone.no_of_stones}</h6>
                          </div>
                          <div className="content-item">
                            <h5>Diamond Quality</h5>
                            <h6>{stone.stone_fleet_code}</h6>
                          </div>
                        </div>
                      ))
                    }
                  </>
                }

                <h2>Customization charges</h2>
                <div className="content-wrapper">
                  <div className="content-item">
                    <h5>Material Price</h5>
                    <h6>${customizationDetails.material.amount}</h6>
                  </div>
                  {
                    stoneQuality.primary &&
                    <div className="content-item">
                      <h5>Primary Stone Price</h5>
                      <h6>${stoneQuality.primary.amount}</h6>
                    </div>
                  }
                  {
                    stoneQuality.mele &&
                    <div className="content-item">
                      <h5>Melee Stone Price</h5>
                      <h6>${stoneQuality.mele.amount}</h6>
                    </div>
                  }
                  {
                    basicDetails.opted_for_certification &&
                    <div className="content-item">
                      <h5>Credit Card Certification</h5>
                      <h6>${basicDetails.certification_charge}</h6>
                    </div>
                  }
                  {
                    basicDetails.opted_for_laser_engraving &&
                    <div className="content-item">
                      <h5>Laser Engraving</h5>
                      <h6>${basicDetails.cost_of_laser_engraving}</h6>
                    </div>
                  }
                </div>
              </Panel.Body>
            </Panel>
          })
        }
      </PanelGroup>
    </div>
  );
};

export default ProductsBought;