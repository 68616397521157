import axios from 'axios';
import { getToken, deleteToken, deleteCookie } from "./ManageToken";

const PostLoginInstance = axios.create(
  {
    baseURL: process.env.REACT_APP_API_BASEURL,
    // baseURL: 'http://sweb.jewelfleet.com/api/',  // Url for Dev
    // baseURL: 'https://api.jewelfleet.com/api/',  // Url for Prod
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
);
// SET THE AUTH TOKEN FOR ANY REQUEST
PostLoginInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = getToken();
  return config;
});
// INTERCEPT RESPONSE TO CHECK IF TOKEN HAS EXPIRED AND IF YES THEN REDIRECT TO LOGIN OR HOME
PostLoginInstance.interceptors.response.use(undefined, (error) => {
  console.log("error inside post login--->", error);
  if (error.response.status === 401) {
    deleteToken();
    deleteCookie();
    window.location = "/";
  }
  return Promise.reject(error);
});

export default PostLoginInstance;