import { PreLoginInstance } from "../../utils";


export const verifyEmailApi = (data, cb) => {
  PreLoginInstance.post('/customer/account/verify-email', data)
    .then(res => {
      if (!res.data.error) {
        cb("Email verified successfully.");
      } else {
        cb(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      // when token is manipulated from address bar.
      console.log(err.response.data.message);
      cb("Please click on the link provided in the email.");
    });
}