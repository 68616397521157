import { PostLoginInstance } from "../../utils";
import { toast } from 'react-toastify';

export const addUpdateMarkupApi = (data, ctx) => {
  return PostLoginInstance.post('/customer/account/add-update-markup', data)
    .then(res => {
      if (!res.data.error) {
        toast.success("Markup saved successfully!!");
        ctx.setState({ isMarkupAvailable: true });
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message);
    });
}

export const getMarkupApi = (cb) => {
  return PostLoginInstance.post('/customer/account/get-markups')
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data)
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message);
    });
}


export const deleteMarkupApi = (cb) => {
  return PostLoginInstance.post('/customer/account/delete-markup')
    .then(res => {
      if (!res.data.error) {
        toast.success("Markups deleted successfully!");
        getMarkupApi(cb)
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message);
    });
}