import React from "react";
import PropTypes from "prop-types";
import { HelpBlock, FormGroup } from "react-bootstrap";
import { ControlLabel } from 'react-bootstrap';
import BaseReactComponent from "./BaseReactComponent";

class FormElementComponent extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      failedValidation: null
    };
  }

  UNSAFE_componentWillMount() {
    this.props.formContext.registerElement(this);
  }

  componentWillUnmount() {
    this.props.formContext.unRegisterElement(this);
  }

  validate = (stateValue = null) => {
    const { valueLink, validations } = this.props;
    let failedValidation = null;

    if (validations) {
      failedValidation = validations.find(
        validation => {
          return validation.validate(valueLink.value || stateValue) === false
        }
      );
      failedValidation =
        failedValidation !== undefined ? failedValidation : null;
    }

    // THE STATE VALUE IS PASSED FROM COMPONENT CALLBACK METHOD IN CASE OF REACT SELECT.
    if (Array.isArray(stateValue) && stateValue.length > 0) {
      failedValidation = false;
    }

    this.setState({ failedValidation });
    return failedValidation === null;
  };

  render() {
    const {
      classes,
      valueLink,
      label,
      required,
      disabled,
      hint,
      helpText,
      control: { type, settings }
    } = this.props;

    const { failedValidation } = this.state;
    const FormElementControl = type;

    const requiredStyle = {
      color: "#d0021b"
    };

    return (
      <>
        {
          label && (
            required ?
              (
                <ControlLabel className={classes.label}>{label} <span style={requiredStyle}>*</span></ControlLabel>
              )
              :
              (
                <ControlLabel className={classes.label} > {label}</ControlLabel>
              )

          )
        }
        <FormGroup
          controlId={Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
          validationState={failedValidation ? "error" : null}
          className={classes.formGroup}
        >
          <FormElementControl
            valueLink={valueLink}
            onBlur={this.validate}
            failedValidation={this.state.failedValidation}
            classes={classes}
            disabled={disabled}
            {...settings}
          />
          {
            (failedValidation || helpText) &&
            <HelpBlock type="invalid">
              {failedValidation ? failedValidation.message : helpText}
            </HelpBlock>
          }

        </FormGroup>
      </>

    );
  }
}

FormElementComponent.propTypes = {
  classes: PropTypes.object,
  formContext: PropTypes.object.isRequired,
  valueLink: PropTypes.object.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.object,
  helpText: PropTypes.string,
  validations: PropTypes.array,
  control: PropTypes.object.isRequired
};

FormElementComponent.defaultProps = {
  label: null,
  required: false,
  hint: null,
  helpText: "",
  validations: [],
  classes: {}
};

export default FormElementComponent;