import { PostLoginInstance } from "../../utils";
import { PAGE_LIMIT } from "../../utils/Constant";
import { toast } from 'react-toastify';

export const getDigitalStoreApi = (data, cb) => {
  data.append('limit', PAGE_LIMIT);
  PostLoginInstance.post('order/favourite/get-favourite-products', data)
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      toast.error(err.message);
    });
}