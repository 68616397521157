import React from 'react';
import { Switch } from 'react-router-dom';
import routes from "./routes";
import { Footer, GlobalComponent } from './app/common';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <GlobalComponent />
      <Switch>
        {
          routes.map((prop, key) => {
            return <prop.type exact path={prop.path} key={key} component={prop.component} />
          })
        }
      </Switch>
      <Footer />
      <ToastContainer
        toastClassName="custom-toastify"
        position="top-right"
        hideProgressBar />
    </>
  );
}

export default App;
