import React from 'react';
import { Cart, SideDrawer, InformationModal, EditCart } from '..';
import { Login } from '../../login';
import { ForgotPassword } from '../../forgotPassword';
import { Image } from "react-bootstrap";
import mailSentImage from '../../../assets/images/mail-sent-illustration.svg';
import { connect } from 'react-redux';
import {
  toggleLoginSidebar, toggleCart,
  toggleForgotPasswordSidebar, toggleEmailSentModal,
  toggleEditCartSidebar
} from "../CommonAction";
import CustomButton from './CustomButton';
import { withRouter } from 'react-router-dom';

const GlobalComponent = props => {
  return (
    <>
      {
        props.commonState.showCart &&
        <SideDrawer
          isOpen={props.commonState.showCart}
          toggleHandle={props.toggleCart}
        >
          <Cart />
        </SideDrawer>
      }

      {
        props.commonState.loginSidebar &&
        <SideDrawer
          isOpen={props.commonState.loginSidebar}
          toggleHandle={props.toggleLoginSidebar}
        >
          <Login />
        </SideDrawer>
      }

      {
        props.commonState.forgotPasswordSidebar &&
        <SideDrawer
          isOpen={props.commonState.forgotPasswordSidebar}
          toggleHandle={props.toggleForgotPasswordSidebar}
        >
          <ForgotPassword />
        </SideDrawer>
      }

      {
        props.commonState.emailSentModal &&
        <InformationModal
          show={props.commonState.emailSentModal}
          toggleHandle={props.toggleEmailSentModal}
          hideDefaultButton
        >
          <Image src={mailSentImage} />
          <h5 className="h2-desktop">We have sent you an email!</h5>
          <h6 className="paragraph-desktop">Please check your mail. We have sent you a link.</h6>
          <CustomButton onClick={() => { props.toggleEmailSentModal(); props.history.push("/") }}>OK</CustomButton>
        </InformationModal>
      }

      {
        props.commonState.showEditCart &&
        <SideDrawer
          isOpen={props.commonState.showEditCart}
          toggleHandle={props.toggleEditCartSidebar}
        >
          <EditCart />
        </SideDrawer>
      }
    </>
  );
};

const mapStateToProps = state => ({
  commonState: state.CommonState
});
const mapDispatchToProps = {
  toggleLoginSidebar,
  toggleCart,
  toggleForgotPasswordSidebar,
  toggleEmailSentModal,
  toggleEditCartSidebar
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GlobalComponent));