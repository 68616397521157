import React from 'react';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { CustomNavbar, PageHeader } from '../common';
import { Grid } from "react-bootstrap";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = (props) => {
  return (
    <>
      <CustomNavbar hideCategories={true} />
      <Grid>
        <PageHeader title="Provide Payment Details" showBackArrow={false} />
        <Elements stripe={promise}>
          <div className="payment-form-wrapper">
            <CheckoutForm {...props} />
          </div>
        </Elements>
      </Grid>
    </>
  );
}

const mapStateToProps = state => ({
  // ...state
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
}
export default connect(mapStateToProps, mapDispatchToProps)(Payment);