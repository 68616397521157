import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, CustomButton, InformationModal, PageHeader } from '../common';
import AddEditAddress from './AddEditAddress';
import { Grid, Glyphicon, Image } from 'react-bootstrap';
import { AddressType } from '../../utils/Constant';
import { getAddressByType, deleteAddressApi } from './Api';
import continueIcon from '../../assets/icons/icon-buynow-button.svg';
import { toast } from 'react-toastify';
import CartItem from '../common/_utils/CartItem';


class Address extends Component {
  constructor(props) {
    super(props);
    // Match URL to identify if its address page and change UI accordingly
    const isAddressPage = this.props.match.path === "/profile/address";
    this.state = {
      selectedAddressId: 0,
      addAddressModal: false,
      editAddress: null,
      isAddressPage,
      showDeleteConfirmation: false,
      deleteAddressId: null,
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'Shipping Address',
          active: true
        },
      ]
    }
  }

  componentDidMount() {
    // Get shipping address
    this.props.getAddressByType(AddressType.SHIPPING);
  }

  toggleAddAddress = () => {
    this.setState({ editAddress: null, addAddressModal: !this.state.addAddressModal })
  }

  toggleEditAddress = (id) => {
    const { addresses } = this.props.addressState;
    const address = addresses.find(address => address.id === id);
    this.setState({ editAddress: address, addAddressModal: true });
  }

  handleAddressClick = (id) => {
    this.setState({ selectedAddressId: id });
  }

  toggleDeleteConfirmation = (id) => {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
      deleteAddressId: id
    })
  }

  deleteAddress = () => {
    const data = new URLSearchParams();
    data.append('id', this.state.deleteAddressId);
    this.props.deleteAddressApi(data, () => this.toggleDeleteConfirmation(null));
  }

  handleSubmit = () => {
    const { selectedAddressId } = this.state;
    if (selectedAddressId) {
      this.props.history.push({
        pathname: "/buy/payment",
        state: {
          selectedAddressId: selectedAddressId
        }
      });
    } else {
      toast.warn("Please select address");
    }
  }

  render() {
    const { addresses } = this.props.addressState;
    return (
      <>
        {
          this.state.showDeleteConfirmation &&
          <InformationModal
            show={this.state.showDeleteConfirmation}
            toggleHandle={() => this.toggleDeleteConfirmation(null)}
            hideDefaultButton
          >
            <h5 className="h2-desktop">Are you sure?</h5>
            <h6 className="paragraph-desktop">This action cannot be undone.</h6>
            <CustomButton onClick={this.deleteAddress}>Yes</CustomButton>
            <CustomButton inverse onClick={() => this.toggleDeleteConfirmation(null)}>No</CustomButton>
          </InformationModal>
        }

        <CustomNavbar hideCategories={!this.state.isAddressPage} />
        {
          this.state.addAddressModal &&
          <AddEditAddress
            show={this.state.addAddressModal}
            handleClose={this.toggleAddAddress}
            address={this.state.editAddress}
          />
        }
        <section className="shipping-address">
          <Grid>
            {
              this.state.isAddressPage ?
                <PageHeader title="Shipping Address" breadcrumbData={this.state.breadcrumbData} />
                :
                <PageHeader title="Select Shipping Address" />
            }
            <h3 className="subtitle-desktop">Deliver to</h3>
            <div className="address-wrapper">
              {
                addresses && addresses.map(address => (
                  <div
                    onClick={() => !this.state.isAddressPage ? this.handleAddressClick(address.id) : {}}
                    className={address.id === this.state.selectedAddressId ? "address-item selected" : "address-item"}
                    key={address.id}>
                    <div className="top-line">
                      <h4 className="product-card-title">{address.name}</h4>
                      {
                        this.state.isAddressPage &&
                        <div className="icon-wrapper">
                          <Glyphicon glyph="pencil" onClick={e => { e.stopPropagation(); this.toggleEditAddress(address.id) }} />
                          <Glyphicon glyph="trash" onClick={e => { e.stopPropagation(); this.toggleDeleteConfirmation(address.id) }} />
                        </div>
                      }
                    </div>
                    <h5 className="product-code">{address.phone_number}</h5>
                    <h6 className="paragraph-desktop">{`${address.store_name}, ${address.street}, ${address.city}, ${address.state}, ${address.pincode}`}</h6>
                  </div>
                ))
              }
              <div
                className="add-address-item"
                onClick={this.toggleAddAddress}
              >
                <h4 className="product-card-title">ADD NEW ADDRESS</h4>
              </div>

            </div>
            {
              !this.state.isAddressPage &&
              <>
                <h3 className="subtitle-desktop">Products</h3>
                <div className="shipping-address-cart">
                  {
                    this.props.commonState.cartData &&
                    this.props.commonState.cartData.cart_items.map((item) => (
                      <CartItem product={item} key={item.code} />
                    ))
                  }
                </div>

                <h3 className="subtitle-desktop">Amount Breakup</h3>
                <div className="amount-breakup">
                  <div className="amount-breakup-item">
                    <h6 className="paragraph-desktop">Cart Total</h6>
                    <span className="product-price">$
                      {
                        this.props.commonState.cartData &&
                        this.props.commonState.cartData.cart_items.map(item => item.quantity * item.total_price_per_unit).reduce((prev, next) => prev + next)
                      }
                    </span>
                  </div>
                  <div className="amount-breakup-item">
                    <h6 className="paragraph-desktop">Shipping Charges</h6>
                    <span className="product-price">+$
                      {
                        this.props.commonState.cartData &&
                        this.props.commonState.cartData.shipping_charges
                      }
                    </span>
                  </div>
                  <div className="amount-breakup-item">
                    <h6 className="paragraph-desktop">Grand Total</h6>
                    <span className="product-price">$
                      {
                        this.props.commonState.cartData &&
                        this.props.commonState.cartData.cart_items.map(item => item.quantity * item.total_price_per_unit).reduce((prev, next) => prev + next) + this.props.commonState.cartData.shipping_charges
                      }
                    </span>
                  </div>

                </div>


                <div className="sticky-mobile-bottom-buttons">
                  <CustomButton className="continue-btn" onClick={this.handleSubmit}>
                    <Image src={continueIcon} />
                    Continue
                  </CustomButton>
                </div>
              </>
            }
          </Grid>
        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  addressState: state.AddressState,
  commonState: state.CommonState
});
const mapDispatchToProps = {
  getAddressByType,
  deleteAddressApi
}
export default connect(mapStateToProps, mapDispatchToProps)(Address);