import { getProductListByCategory } from "./ProductListAction";
import { PreLoginInstance, PostLoginInstance } from "../../utils";
import { PAGE_LIMIT } from "../../utils/Constant";
import { getToken } from "../../utils/ManageToken";
import { toast } from "react-toastify";

export const getProductListApi = (data) => {
  data.append('limit', PAGE_LIMIT);
  const instance = getToken() ? PostLoginInstance : PreLoginInstance;
  let url;
  if (data.get('tag_permalink')) {
    url = "offering/product/get-product-listing-by-category";
  } else if (data.get('stone_shape')) {
    url = "offering/product/get-product-listing-by-stone-shape";
  } else if (data.get('collection_permalink')) {
    url = "offering/product/get-product-listing-by-collection";
  } else if (data.get('category_id')) {
    url = "offering/product/get-product-listing-by-category-id";
  } else {
    url = "offering/product/get-new-arrival-product-listing";
  }
  return function (dispatch) {
    return instance.post(url, data)
      .then(res => {
        console.log("response ---> ", res.data);
        if (!res.data.error) {
          dispatch(getProductListByCategory(res.data.data));
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }).catch(err => {
        console.log("error", err);
        toast.error(err.message || "Something went wrong");
      });
  };
}