import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import { CustomNavbar } from '../common';

class TermsAndConditions extends Component {
  render() {
    return (
      <section className="terms-and-conditions">
        <CustomNavbar />
        <Grid>
          <h1 className="h1">Terms &amp; Conditions</h1>
          <p className="paragraph-desktop">
            Product weights and dimensions are approximate.
          </p>
          <p className="paragraph-desktop">
            Our total diamond weight may have a standard +/-10% tolerance.
          </p>
          <p className="paragraph-desktop">
            Our total gold weight may have a standard +/-10% tolerance.
          </p>
          <p className="paragraph-desktop">
            All prices on this website are subject to change without notice. Whilst we make every effort to provide you with the most accurate &amp; up-to-date information, yet occasionally, one or more items on our website may be priced incorrectly. In the event that a product is listed at an incorrect price due to typographical, photographic, or technical error, we reserve the right to cancel said order.
          </p>
          <p className="paragraph-desktop">
            We do not accept returns on any orders as all products manufactured are custom orders and built-to-suit your requirements
          </p>
          <p className="paragraph-desktop">
            We do not currently service repairs across our products. For any issues with product delivered, please email us at <span>order@jewelfleet.com</span> with your Order ID Number.
          </p>
        </Grid>
      </section>
    )
  }
}

export default TermsAndConditions;