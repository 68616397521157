import { GET_ADDRESSES } from "./ActionTypes";

const INITIAL_STATE = {
    adresses: null
};

const AddressReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ADDRESSES:
            return { ...state, addresses: action.payload };
        default:
            return state
    }
};
export default AddressReducer