import { PostLoginInstance, PreLoginInstance } from "../../utils";
import { toast } from 'react-toastify';


export const profileApi = (cb) => {
  PostLoginInstance.post('/customer/account/get-profile')
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err)
      toast.error(err.message);
    });
}


export const addUpdateProfilePhoto = (data, toastId, preview, cb) => {
  PostLoginInstance.post('/customer/account/add-update-profile-image', data)
    .then(res => {
      if (!res.data.error) {
        toast.update(toastId, {
          render: 'Profie photo updated successfully!',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000
        });
        cb(preview);
      } else {
        toast.update(toastId, {
          render: res.data.message || "Something went wrong",
          type: toast.TYPE.ERROR,
          autoClose: 3000
        });
      }
    })
    .catch(err => {
      console.log('error', err)
      toast.update(toastId, {
        render: err.message,
        type: toast.TYPE.ERROR,
        autoClose: 3000
      });
    });
}


export const uploadProfilePhoto = (data, cb) => {
  data.append('module', 'profile');
  data.append('sub_module', 'images');
  data.append('media_type', 'IMAGE');
  const toastId = toast.info("Uploading profile photo. Please wait...", { autoClose: false });
  PreLoginInstance.post('/common/attachment/upload-attachment', data)
    .then(res => {
      if (!res.data.error) {
        const addUpdateProfileData = new URLSearchParams();
        addUpdateProfileData.append('profile_image_id', res.data.data.id);
        addUpdateProfilePhoto(addUpdateProfileData, toastId, res.data.data.preview, cb);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err)
      toast.error(err.message);
    });
}



