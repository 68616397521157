import { PreLoginInstance } from "../../utils";
import { setCookie } from "../../utils/ReusableFunctions";
import { toast } from 'react-toastify';

export const loginApi = (data, cb) => {
  PreLoginInstance.post('/customer/account/sign-in', data)
    .then(res => {
      if (!res.data.error) {
        localStorage.setItem('token', res.data.data.user_details.token);
        // Example of use:
        setCookie('token', res.data.data.user_details.token, { 'expires': 15, 'sameSite': 'strict' })
        // setCookie('token', res.data.data.user_details.token, { 'max-age': 36000, 'sameSite': 'strict', 'secure': true });
        // document.cookie = `token=${res.data.data.user_details.token};max-age=3600;path=/;`;
        cb();
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err);
      toast.error(err.message);
    });
}