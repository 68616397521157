import {
  TOGGLE_LOGIN_SIDEBAR,
  CATEGORY_DATA,
  TOGGLE_CART,
  TOGGLE_CUSTOMIZATION_OPTION,
  TOGGLE_FORGOT_PASSWORD_SIDEBAR,
  EMAIL_SENT_MODAL,
  CART_DATA,
  TOGGLE_EDIT_CART,
  UPDATE_VALUES_FROM_SERVER
} from "./ActionTypes";
import { isMobile, isMobileAndTabletCheck } from "../../utils/ReusableFunctions";

const INITIAL_STATE = {
  isMobile: isMobile(),
  isMobileAndTablet: isMobileAndTabletCheck(),
  token: null,
  loginSidebar: false,
  showCart: false,
  showCustomizationOption: false,
  categoryData: null,
  forgotPasswordSidebar: false,
  emailSentModal: false,
  cartData: null,
  showEditCart: false,
  editCartId: null,
};

const CommonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOGIN_SIDEBAR:
      return {
        ...state,
        loginSidebar: !state.loginSidebar
      }
    case TOGGLE_CART:
      return {
        ...state,
        showCart: !state.showCart
      }
    case TOGGLE_CUSTOMIZATION_OPTION:
      return {
        ...state,
        showCustomizationOption: !state.showCustomizationOption
      }
    case CATEGORY_DATA:
      return {
        ...state,
        categoryData: action.payload
      }
    case TOGGLE_FORGOT_PASSWORD_SIDEBAR:
      return {
        ...state,
        forgotPasswordSidebar: !state.forgotPasswordSidebar
      }
    case EMAIL_SENT_MODAL:
      return {
        ...state,
        emailSentModal: !state.emailSentModal
      }
    case CART_DATA:
      return {
        ...state,
        cartData: action.payload
      }
    case TOGGLE_EDIT_CART:
      return {
        ...state,
        showEditCart: !state.showEditCart,
        editCartId: action.payload
      }
    case UPDATE_VALUES_FROM_SERVER:
      return {
        ...state,
        isMobile: action.isMobile,
        token: action.token,
        isMobileAndTablet: action.isMobileAndTablet
      }
    default:
      return state
  }
};

export default CommonReducer