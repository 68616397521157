import React, { useEffect, useState } from 'react';
import { Grid, Image, Glyphicon } from 'react-bootstrap';
import logo from "../../../assets/images/jewel_fleet_logo.png";
import searchIcon from "../../../assets/icons/icon-search.svg";
import storeIcon from "../../../assets/icons/icon-digital-store.svg";
import profileIcon from "../../../assets/icons/icon-profile.svg";
import cartIcon from "../../../assets/icons/icon-cart.svg";
import NavbarMenu from './NavbarMenu';
import SecondaryNavbar from './SecondaryNavbar';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../common';

import { connect } from 'react-redux';
import { toggleLoginSidebar, toggleCart } from '../CommonAction';
import { getCategoryDataApi, getCartDataApi } from '../Api';
import { getToken, getCookieValue } from '../../../utils/ManageToken';

import hambugerIcon from '../../../assets/icons/hamburger-menu.svg';
import escapeIcon from '../../../assets/icons/esc.svg';
import { PreLoginInstance } from '../../../utils';
import { withRouter } from 'react-router-dom';

const CustomNavbar = props => {

  const [openedIndex, setOpenedIndex] = useState(null);
  const [showSideNavbar, setShowSideNavbar] = useState(false);

  const toggleSideNavbar = () => {
    setShowSideNavbar(!showSideNavbar);
  }

  const { categoryData, token } = props.commonState;
  useEffect(() => {
    if (!categoryData) {
      props.getCategoryDataApi();
    }
    if (getToken())
      props.getCartDataApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        categoryData &&
        <SecondaryNavbar data={categoryData.material_types} />
      }
      <nav className="custom-navbar">
        <Grid>
          <div className="nav-wrapper">
            {/* ======== HAMBURGER AND LOGO ==========*/}
            {
              !props.hideCategories &&
              <Image
                src={hambugerIcon}
                className="hamburger-icon"
                onClick={toggleSideNavbar} />
            }
            <Link to="/" className="logo-wrapper">
              <Image src={logo} />
            </Link>

            {/* =========== CATEGORIES ============ */}
            <div
              onClick={toggleSideNavbar}
              className={showSideNavbar ? "backdrop open" : "backdrop"}
            >
              {
                !props.hideCategories &&
                <div onClick={(e) => e.stopPropagation()} className="categories-wrapper">
                  <Image
                    src={escapeIcon}
                    className="navbar-close-icon"
                    onClick={() => setShowSideNavbar(false)} />
                  {
                    (getCookieValue() || token) ?
                      null
                      :
                      <span className="mobile-account-buttons-wrapper">
                        <CustomButton onClick={() => { toggleSideNavbar(); props.toggleLoginSidebar() }}>Sign in</CustomButton>
                        <span className="product-code">or</span>
                        <CustomButton inverse href="/signup">Create new account</CustomButton>
                      </span>
                  }
                  {
                    categoryData &&
                    categoryData.categories_and_tags.slice(0, 5).map((category, index) => (
                      <div
                        key={category.id}
                        onMouseEnter={() => props.commonState.isMobileAndTablet ? null : setOpenedIndex(index)}
                        onMouseLeave={() => props.commonState.isMobileAndTablet ? null : setOpenedIndex(null)}

                        className={openedIndex === index ? "category-item open" : "category-item"}>
                        <span className="menu-desktop category-name">
                          <Glyphicon
                            glyph={openedIndex === index ? "chevron-down" : "chevron-right"}
                            onClick={e => {
                              e.stopPropagation();
                              if (openedIndex === index)
                                setOpenedIndex(null)
                              else
                                setOpenedIndex(index)
                            }}
                          />
                          <span
                            className="tag-name"
                            onClick={() => {
                              setOpenedIndex(null);
                              setShowSideNavbar(false);
                              props.history.push(`/products/category/${category.id}`);
                            }}>
                            {category.name}
                          </span>
                        </span>
                        <div className="menu-wrapper">
                          <NavbarMenu
                            categoryId={category.id}
                            categoryName={category.name}
                            tags={category.tag_list}
                            closeHandle={() => { setOpenedIndex(null); setShowSideNavbar(false) }} />
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </div>


            {/* ========== RIGHT SIDE ICONS ============== */}
            <div className="icons-wrapper">
              <Link to="/search">
                <Image src={searchIcon} />
              </Link>
              <Link to="/profile/digital-store">
                <Image src={storeIcon} />
              </Link>
              {
                (token || getToken()) &&
                <Link to="/profile">
                  <Image src={profileIcon} />
                </Link>
              }
              <span className="cart-icon-wrapper" onClick={props.toggleCart}>
                <Image src={cartIcon} />
                {
                  props.commonState.cartData && props.commonState.cartData.cart_items.length > 0 &&
                  <span className="red-dot" />
                }
              </span>
              {
                (token || getToken()) ?
                  null :
                  <div className="account-buttons-wrapper">
                    <CustomButton onClick={props.toggleLoginSidebar}>sign in</CustomButton>
                    <CustomButton inverse href="/signup">sign up</CustomButton>
                  </div>
              }
            </div>
          </div>
        </Grid>
      </nav>
    </>
  );
};


const mapStateToProps = state => ({
  commonState: state.CommonState
});

const mapDispatchToProps = {
  toggleLoginSidebar,
  getCategoryDataApi,
  toggleCart,
  getCartDataApi
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomNavbar));
