import { PostLoginInstance } from "../../utils";
import { PAGE_LIMIT } from "../../utils/Constant";
import { getOrders } from "./OrderListAction";
import { toast } from "react-toastify";

export const getOrdersApi = (data) => {
  data.append('limit', PAGE_LIMIT);
  return function (dispatch) {
    return PostLoginInstance.post('order/get-all-orders', data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getOrders(res.data.data));
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }).catch(err => {
        console.log("error", err);
        toast.error(err.message || "Something went wrong");
      });
  };
}