import React, { Component } from 'react';
import { connect } from "react-redux";
import OrderItem from './_utils/OrderItem';
import { Grid } from 'react-bootstrap';
import { CustomNavbar, PageHeader, EmptyState, Pagination } from "../common"
import emptyOrderImage from '../../assets/images/no-order-illustration.svg';
import { toggleLoginSidebar } from '../common/CommonAction';
import { getOrdersApi } from './Api';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'Orders',
          active: true
        },
      ],
    }
  }

  componentDidMount() {
    // Call Orders api
    this.getOrders();
  }

  getOrders = () => {
    const data = new URLSearchParams(this.props.location.search);
    data.append('page_no', data.get("p") || 1);
    this.props.getOrdersApi(data);
  }

  componentDidUpdate(prevProps) {
    // search string inside location is unique for every new params in routes.
    console.log("search string", this.props.location.search);
    if (this.props.location.search !== prevProps.location.search) {
      this.getOrders();
      if (window) window.scrollTo(0, 0);
    }
  }


  render() {
    const { orders, totalPages } = this.props.orderListState;
    const params = new URLSearchParams(this.props.location.search);
    return (
      <>
        <CustomNavbar />
        <section className="order-list">
          <Grid>
            <PageHeader title="Orders" breadcrumbData={this.state.breadcrumbData} />
            {
              orders ?
                orders.length > 0 ?
                  orders.map(order => (
                    <OrderItem key={order.id} order={order} />
                  ))
                  :
                  <EmptyState
                    title="No Orders Found!"
                    subtitle="You haven't ordered anything yet."
                    img={emptyOrderImage} />
                : null
            }
            {
              totalPages ?
                totalPages > 1 ?
                  <Pagination
                    currentPage={params.get("p") ? Number(params.get("p")) : 1}
                    totalPages={totalPages}
                    history={this.props.history}
                  />
                  : null
                : null
            }
          </Grid>
        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  orderListState: state.OrderListState
});
const mapDispatchToProps = {
  getOrdersApi,
  toggleLoginSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);