import React from 'react';
import { Image } from 'react-bootstrap';
import CustomButton from './CustomButton';

const EmptyState = ({ img, title, subtitle, buttonText, buttonHandle }) => {
  return (
    <div className="empty-state-wrapper">
      <Image src={img} />
      <h5 className="h2-desktop">{title}</h5>
      {
        subtitle &&
        <h6 className="subtitle-desktop">{subtitle}</h6>
      }
      {
        buttonText && buttonHandle &&
        <CustomButton onClick={buttonHandle}>
          {buttonText}
        </CustomButton>
      }
    </div>
  );
};

export default EmptyState;