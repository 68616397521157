import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import certFront from '../../../assets/images/demo_certificate_front.png';
import certBack from '../../../assets/images/demo_certificate_back.png';


const CertificatesModal = props => {
  return (
    <Modal show={props.show} onHide={props.handleClose} dialogClassName="custom-modal certificates-modal">
      <Modal.Header closeButton>
        <Modal.Title>Sample Certificate from Independent Laboratories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={certFront} />
        <Image src={certBack} />
      </Modal.Body>
    </Modal>
  );
};

export default CertificatesModal;