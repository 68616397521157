import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CustomerInfo = ({ customerInfo }) => {
  const { user, address } = customerInfo;
  return (
    <div className="customer-info">
      <h3>Customer Information</h3>
      <Row>
        <Col md={3}>
          <div className="info-item">
            <h5>Customer Name</h5>
            <h6>{user.name}</h6>
          </div>
        </Col>
        <Col md={3}>
          <div className="info-item">
            <h5>Shipping Address</h5>
            <h6>
              {
                `${address.store_name}, ${address.street}, ${address.state} ${address.pincode}`
              }
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerInfo;