import React from 'react';
import { Grid, Image } from 'react-bootstrap';
import Slider from "react-slick";

const FullImageSlider = props => {

  const sliderSettings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000
  }

  return (
    <div className="full-image-slider">
      <Grid>
        <Slider {...sliderSettings}>
          {
            props.data.map((item) => (
              <div key={item.id}>
                {/* <Link to={item.link}> */}
                <a href={`//${item.link}`} rel='noopener noreferrer' target="_blank" style={{ display: "block" }}>
                  <Image src={item.attachment_url} alt={item.attachment_name || ""} />
                </a>
                {/* </Link> */}
              </div>
            ))
          }
        </Slider>
      </Grid>
    </div>
  );
};

export default FullImageSlider;