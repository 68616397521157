/* STORE PROJECT LEVEL CONSTANT FILE SUCH AS ENUM OR COMMON STRING VALUES. */
export const PRICE_MIN = 100;
export const PRICE_MAX = 3000;
export const PAGE_LIMIT = 24;

export const WeightRangeType = {
  RANGE_1: 1,
  RANGE_2: 2,
  RANGE_3: 3,
  RANGE_4: 4,
  RANGE_5: 5,
  RANGE_6: 6,
  RANGE_7: 7,
  _presentable: {
    1: 'Below 0.25 CT',
    2: '0.25 CT - 0.49 CT',
    3: '0.50 CT - 0.69 CT',
    4: '0.70 CT - 0.99 CT',
    5: '1.00 CT - 1.49 CT',
    6: '1.50 CT - 2.99 CT',
    7: '3.00 CT Upwards'
  },
  getText: function (val) { return this._presentable[val] }
}

export const TotalWeight = [];

Object.entries(WeightRangeType).forEach(([key, value], index) => {
  if (index < Object.keys(WeightRangeType).length - 2)
    TotalWeight.push({ value: value, label: WeightRangeType.getText(value) })
});

export const StoneShape = {
  ROUND: 1,
  PRINCESS: 2,
  EMERALD: 3,
  OVAL: 4,
  PEAR: 5,
  // MARQUISE: 6,
  // HEART: 7,
  // CUSHION: 8,
  BAGUETTE: 9,
  // PRINCESS_COMPOSITE: 10,
  // COMPOSITE_OR_CLUSTER: 11,
  // SPECIALITY: 12,
  // OTHER: 13,
  _presentable: {
    1: 'Round',
    2: 'Princess',
    3: 'Emerald',
    4: 'Oval',
    5: 'Pear',
    // 6: 'Marquise',
    // 7: 'Heart',
    // 8: 'Cushion',
    9: 'Baguette',
    // 10: 'Princess Composite',
    // 11: 'Composite Or Cluster',
    // 12: 'Speciality',
    // 13: 'Other'
  },
  getText: function (val) { return this._presentable[val] }
}

export const CenterStoneShape = [];

Object.entries(StoneShape).forEach(([key, value], index) => {
  if (index < Object.keys(StoneShape).length - 2)
    CenterStoneShape.push({ value: value, label: StoneShape.getText(value) })
});

export const orderStatus = {
  ORDER_INITIATED: 10,
  ORDER_PLACED: 20,
  ACCEPTED: 30,
  DISPATCHED: 50,
  REJECTED: 40,
  OUT_FOR_DELIVERY: 60,
  DELIVERED: 70,
  CANCELLED: 80,
  _presentable: {
    10: 'ORDER INITIATED',
    20: 'ORDER PLACED',
    30: 'ACCEPTED',
    50: 'DISPATCHED',
    60: 'OUT FOR DELIVERY',
    70: 'DELIVERED',
    80: 'CANCELLED',
    40: 'REJECTED',
  },
  _presentableTextColor: {
    10: '#65c100',
    20: '#65c100',
    30: '#65c100',
    50: '#65c100',
    60: '#65c100',
    70: '#65c100',
    80: '#d0021b',
    40: '#d0021b',
  },
  _presentableBackgroundColor: {
    10: 'rgba(101, 193, 0, 0.2)',
    20: 'rgba(101, 193, 0, 0.2)',
    30: 'rgba(101, 193, 0, 0.2)',
    50: 'rgba(101, 193, 0, 0.2)',
    60: 'rgba(101, 193, 0, 0.2)',
    70: 'rgba(101, 193, 0, 0.2)',
    80: 'rgba(208, 2, 27, 0.2)',
    40: 'rgba(208, 2, 27, 0.2)',
  },
  getText: function (val) { return this._presentable[val] },
  getTextColor: function (val) { return this._presentableTextColor[val] },
  getBackgroundColor: function (val) { return this._presentableBackgroundColor[val] }
}

export const SortProductBy = {
  LOW_PRICE: 1,
  HIGH_PRICE: 2,
  BEST_SELLER: 3,
  NEWLY_ADDED: 4,
  _presentable: {
    1: "Low Price",
    2: "High Price",
    3: "Best Seller",
    4: "Newly Added"
  },
  getText: function (val) { return this._presentable[val] }
}

export const SortBy = [];
Object.entries(SortProductBy).forEach(([key, value], index) => {
  if (index < Object.keys(SortProductBy).length - 2)
    SortBy.push({ value: value, label: SortProductBy.getText(value) })
});

export const materialColors = {
  WHITE: 1,
  ROSE: 2,
  YELLOW: 3,
  _presentable: {
    1: 'White',
    2: 'Rose',
    3: 'Yellow'
  },
  _presentableHexColor: {
    1: '#e4e2e2',
    2: '#ffdee2',
    3: '#ffe1ac'
  },
  _presentableDarkHexColor: {
    1: '#ffca73',
    2: '#ffca73',
    3: '#ffca73'
  },
  getText: function (val) { return this._presentable[val] },
  getHexColor: function (val) { return this._presentableHexColor[val] },
  getDarkHexColor: function (val) { return this._presentableDarkHexColor[val] }
}

export const StoneSize = {
  CT_1_BY_6: 0.5,
  CT_1_BY_5: 1,
  CT_1_BY_4: 2,
  CT_1_BY_3: 3,
  CT_3_BY_8: 4,
  CT_1_BY_2: 5,
  _presentable: {
    0.5: '1/6 CT',
    1: '1/5 CT',
    2: '1/4 CT',
    3: '1/3 CT',
    4: '3/8 CT',
    5: '1/2 CT',
  },
  getText: function (val) { return this._presentable[val] }
}
export const stoneSizeOptions = [];
Object.entries(StoneSize).forEach(([key, value], index) => {
  if (index < Object.keys(StoneSize).length - 2)
    stoneSizeOptions.push({ value: value, label: StoneSize.getText(value) })
});


export const companyEntityType = {
  CORPORATION: 1,
  PARTNERSHIP: 2,
  SOLE_PROPRIETORSHIP: 3,
  INDIVIDUAL: 4,
  LTD: 5,
  LLP: 6,
  LLC: 7,
  _presentable: {
    1: 'CORPORATION',
    2: 'PARTNERSHIP',
    3: 'SOLE PROPRIETORSHIP',
    4: 'INDIVIDUAL',
    5: 'LTD',
    6: 'LLP',
    7: 'LLC'
  },
  getText: function (val) { return this._presentable[val] }
}
export const companyEntityOptions = [];
Object.entries(companyEntityType).forEach(([key, value], index) => {
  if (index < Object.keys(companyEntityType).length - 2)
    companyEntityOptions.push({ value: value, label: companyEntityType.getText(value) })
});

export const businessType = {
  RETAILER: 1,
  WHOLESALER: 2,
  MANUFACTURER: 3,
  _presentable: {
    1: 'RETAILER',
    2: 'WHOLESALER',
    3: 'MANUFACTURER'
  },
  getText: function (val) { return this._presentable[val] }

}

export const AddressType = {
  SHIPPING: 1,
  BILLING: 2,
  DEFAULT: 3,
  _presentable: {
    1: 'shipping',
    2: 'billing',
    3: 'default'
  },
  getText: function (val) { return this._presentable[val] }
}

export const FilterProductsBy = {
  NEWLY_ADDED: 1,
  RECENTLY_PURCHASED: 2,
  TOP_25: 3,
  _presentable: {
    1: 'Newly Added',
    2: 'Recently Purchased',
    3: 'Top 25'
  },
  getText: function (val) { return this._presentable[val] }
}

export const RingSizes = ['5.00', '5.50', '6.00', '6.50', '7.00', '7.50', '8.00', '8.50', '9.00'];

export const DefaultRingSize = "7.00";

export const RingSizeOptions = RingSizes.map(size => ({
  value: size,
  label: size,
}))