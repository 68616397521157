import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, ProductItemsSlider, FullImageSlider, SideDrawer, BreadCrumb, CustomButton, InformationModal } from '../common';

import ProductImages from './_utils/ProductImages';
import cartIcon from '../../assets/icons/icon-cart-button.svg';
import buynowIcon from '../../assets/icons/icon-buynow-button.svg';

import ProductDetailsAccordion from './_utils/ProductDetailsAccordion';
import { Grid, Button, Image } from 'react-bootstrap';
import { getProductDetailsApi, getSimilarProductsApi } from './Api';
import { materialColors } from '../../utils/Constant';
import CustomizationOption from './CustomizationOption';
import { toggleCustomizationOption } from '../common/CommonAction';
import { setCustomizationOption, clearProductDetails, resetValues } from './ProductDetailsAction';
import { getCategoryDataApi, addUpdateProductToCartApi, getCartDataApi } from '../common/Api';
import { Login } from '../login';
import { getToken } from '../../utils/ManageToken';
import { toast } from 'react-toastify';
import { sortArrayOfObjects } from '../../utils/ReusableFunctions';
import SigninRibbon from '../common/_utils/SigninRibbon';
import sizeGuidePdf from '../../assets/docs/ring_size_guide.pdf';
import CertificatesModal from './_utils/CertificatesModal';
import { Link } from 'react-router-dom';


class ProductDetails extends Component {
  constructor(props) {
    super(props);

    let obj = {
      selectedColor: null,
      selectedMaterial: null,
      breadCrumbData: [],
      centerStoneOptions: null,
      meleeStoneOptions: null,
    };
    if (this.props.productDetailsState.productDetails) {
      obj = this.getProductDetailsCallback(this.props.productDetailsState.productDetails);
    }

    this.state = {
      showCertificates: false,
      showLogin: false,
      showSizeGuide: false,
      ...obj
    }
  }

  componentDidMount() {
    console.log("Inside product did mount", this.props);
    if (!this.props.productDetailsState.productDetails) {
      this.getProductDetails();
    }
  }

  componentWillUnmount() {
    // Clear data on unmount to avoid making duplicate api calls on server & client
    this.props.clearProductDetails();
  }

  static loadData(match, dispatch) {
    const productDetailsData = new URLSearchParams();
    productDetailsData.append('permalink', match.serverParams.permalink);
    const similarProductsData = new URLSearchParams();
    similarProductsData.append('product_permalink', match.serverParams.permalink);
    return Promise.all([
      dispatch(getProductDetailsApi(productDetailsData)),
      dispatch(getSimilarProductsApi(similarProductsData)),
      dispatch(getCategoryDataApi()),
    ]);
  }

  getProductDetails = () => {
    const productDetailsData = new URLSearchParams();
    productDetailsData.append('permalink', this.props.match.params.permalink);
    this.props.getProductDetailsApi(productDetailsData).then(data => {
      if (data) {
        const res = this.getProductDetailsCallback(data);
        this.setState({ ...res });
      }
    });

    const similarProductsData = new URLSearchParams();
    similarProductsData.append('product_permalink', this.props.match.params.permalink);
    this.props.getSimilarProductsApi(similarProductsData);
  }

  getProductDetailsCallback = (data) => {
    const defaultMaterial = data.material_list.find(material =>
      material.is_default === true
    );
    const defaultColor = data.material_colour_list.find(color =>
      color.is_default === true
    )

    let centerStone = null,
      meleeStone = null,
      centerStoneOptions = null,
      meleeStoneOptions = null;

    if (data.center_stone_configurations && data.center_stone_configurations.stone_quality_master_ids)
      centerStoneOptions = data.center_stone_configurations.stone_quality_master_ids.map(stone => {
        if (stone.is_default)
          centerStone = {
            ...stone,
            value: stone.fleet_code_master_id,
            label: stone.fleet_code,
          };

        return {
          ...stone,
          value: stone.fleet_code_master_id,
          label: stone.fleet_code,
        }
      })


    if (data.mele_stone_configurations && data.mele_stone_configurations.stone_quality_master_ids)
      meleeStoneOptions = data.mele_stone_configurations.stone_quality_master_ids.map(stone => {
        if (stone.is_default)
          meleeStone = {
            ...stone,
            value: stone.fleet_code_master_id,
            label: stone.fleet_code,
          };
        return {
          ...stone,
          value: stone.fleet_code_master_id,
          label: stone.fleet_code,
        }
      })

    if (centerStoneOptions)
      sortArrayOfObjects(centerStoneOptions, 'stone_cost', true);
    if (meleeStoneOptions)
      sortArrayOfObjects(meleeStoneOptions, 'stone_cost', true);

    this.props.setCustomizationOption({
      name: 'centerStone',
      value: centerStone
    });
    this.props.setCustomizationOption({
      name: 'meleeStone',
      value: meleeStone
    });

    const breadCrumbData = [
      {
        text: 'home',
        url: '/'
      },
      {
        text: data.category_name,
      },
      {
        text: data.tag_name,
        url: `/products/${data.category_name.replace(/ /g, "-")}/${data.tag_permalink}`
      },
      {
        text: data.name,
        active: true
      }
    ]

    // for resetting all values to default once a product is added to cart.
    const resetValues = {
      defaultColor,
      defaultMaterial,
      centerStone,
      meleeStone,
    }
    this.props.setCustomizationOption({
      name: 'resetValues',
      value: resetValues
    });

    return {
      selectedColor: defaultColor,
      selectedMaterial: defaultMaterial,
      centerStoneOptions,
      meleeStoneOptions,
      isDefaultLoaded: true,
      breadCrumbData,
    }
  }

  resetColorAndMaterial = () => {
    // this fn is called when a product is added to cart
    const { defaultColor, defaultMaterial } = this.props.productDetailsState.resetValues;
    this.setState({
      selectedColor: defaultColor,
      selectedMaterial: defaultMaterial,
    })
  }

  toggleSizeGuide = () => {
    this.setState({
      showSizeGuide: !this.state.showSizeGuide
    })
  }


  calculateTotalCost = () => {
    let total;
    const { productDetailsState } = this.props;
    // calculating initial total charges
    const centerStoneCost = productDetailsState.centerStone ? productDetailsState.centerStone.stone_cost : 0;
    const meleeStoneCost = productDetailsState.meleeStone ? productDetailsState.meleeStone.stone_cost : 0;
    const labourCost = productDetailsState.productDetails.cost_of_labour;
    const materialCost = this.state.selectedMaterial.material_cost;
    total = centerStoneCost + meleeStoneCost + labourCost + materialCost;

    // adding additional markup
    const additionalMarkupPercentage = productDetailsState.productDetails.additional_markup_percentage;
    const additionalMarkupCost = total * additionalMarkupPercentage / 100;
    total += additionalMarkupCost;

    // subtracting discount
    const discountPercentage = productDetailsState.productDetails.discount_percentage;
    const discount = total * discountPercentage / 100;
    total -= discount;

    // adding laser engraving charges
    const laserEngravingCharges = productDetailsState.productDetails.cost_of_laser_engraving;
    const optedForLaserEngraving = productDetailsState.laserEngraving;
    if (optedForLaserEngraving) total += laserEngravingCharges;

    // adding certification charges
    const certificationCharge = productDetailsState.productDetails.credit_card_certificate;
    const optedForCertification = productDetailsState.certificationCharge;
    if (optedForCertification) total += certificationCharge;

    // multiply quantity
    total *= productDetailsState.quantity;

    // multiplying straight or category markup
    const markup = productDetailsState.productDetails.markup;
    if (markup)
      total *= markup;

    // console.log("===========");
    // console.log("center stone cost: ", centerStoneCost);
    // console.log("melee stone cost: ", meleeStoneCost);
    // console.log("labour cost: ", labourCost);
    // console.log("material cost: ", materialCost);
    // console.log("markup percentage: ", additionalMarkupPercentage);
    // console.log("markup cost: ", additionalMarkupCost);
    // console.log("discount percentage: ", discountPercentage);
    // console.log("discount: ", discount);


    this.props.setCustomizationOption({
      name: 'totalCost',
      value: markup ? Math.ceil(total).toFixed(2) : total.toFixed(2)
    })

  }

  componentDidUpdate(prevProps, prevState) {
    const { productDetailsState: newProductDetails } = this.props;
    const { productDetailsState: oldProductDetails } = prevProps;

    if (this.state.isDefaultLoaded &&
      (newProductDetails.meleeStone !== oldProductDetails.meleeStone ||
        newProductDetails.centerStone !== oldProductDetails.centerStone ||
        newProductDetails.laserEngraving !== oldProductDetails.laserEngraving ||
        newProductDetails.certificationCharge !== oldProductDetails.certificationCharge ||
        newProductDetails.quantity !== oldProductDetails.quantity ||

        this.state.selectedMaterial !== prevState.selectedMaterial)) {
      // calculating total cost only when material, laser engraving
      // , certification, centre stone,
      // or melee stone changes. It will also run after first
      // mount because above values will change from null to
      // something.
      this.calculateTotalCost();
    }

    // key inside location is unique for every new params in routes.
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getProductDetails();
      if (window) window.scrollTo(0, 0);
    }

  }

  addProductToCart = (cb) => {
    const {
      productDetails,
      laserEngravingName,
      certificationCharge,
      laserEngraving,
      centerStone,
      meleeStone,
      ringSize,
      personalNote,
      quantity
    } = this.props.productDetailsState;

    let centerStoneObj, meleeStoneObj;
    if (centerStone)
      centerStoneObj = {
        configuration_type: 1, // center stone
        stone_type: 1,  // diamond
        stone_fleet_master_id: centerStone.fleet_code_master_id
      }

    if (meleeStone)
      meleeStoneObj = {
        configuration_type: 2, // melee stone
        stone_type: 1, // diamond
        stone_fleet_master_id: meleeStone.fleet_code_master_id
      }

    const data = new URLSearchParams();
    data.append("sku_number", productDetails.sku_number);
    data.append("quantity", quantity);
    data.append("ring_size", ringSize.value);
    data.append("is_certification_charge_included", certificationCharge);
    data.append("personal_note", personalNote);
    data.append("is_laser_engraving", laserEngraving);
    data.append("laser_engraving_message", laserEngravingName);
    data.append("material_colour", JSON.stringify(this.state.selectedColor));
    data.append("material", JSON.stringify(this.state.selectedMaterial));
    if (centerStone)
      data.append("center_stone_configuration", JSON.stringify(centerStoneObj));
    if (meleeStone)
      data.append("mele_stone_configuration", JSON.stringify(meleeStoneObj));

    if (getToken()) {
      addUpdateProductToCartApi(data, () => this.addProductToCartCallback(cb));
    } else {
      localStorage.setItem("addProductToCart", data.toString());
      this.props.toggleCustomizationOption();
      this.setState({ showLogin: true });
    }
  }

  addProductToCartCallback = (cb) => {
    this.props.getCartDataApi();
    this.props.resetValues(); // reset values from redux store (everything except color and material)
    this.resetColorAndMaterial(); // reset values from local state (color and material)
    toast.success("Product added to cart");
    cb();
  }


  buyNowHandle = () => {
    this.addProductToCart(() => this.props.history.push('/buy/address'));
  }

  toggleCertificateModal = () => {
    this.setState({
      showCertificates: !this.state.showCertificates
    })
  }

  render() {
    const { productDetails, similarProducts, totalCost } = this.props.productDetailsState;
    return (
      <>
        <CustomNavbar />
        <SideDrawer
          isOpen={this.props.commonState.showCustomizationOption}
          toggleHandle={this.props.toggleCustomizationOption}
        >
          {
            productDetails &&
            <CustomizationOption
              toggleCertificateModal={this.toggleCertificateModal}
              addProductHandle={() => this.addProductToCart(this.props.toggleCustomizationOption)}
              centerStoneOptions={this.state.centerStoneOptions || []}
              meleeStoneOptions={this.state.meleeStoneOptions || []} />
          }
        </SideDrawer>

        {
          this.state.showLogin ?
            <SideDrawer
              isOpen={this.state.showLogin}
              toggleHandle={() => {
                localStorage.removeItem("addProductToCart");
                this.setState({ showLogin: false })
              }}
            >
              <Login />
            </SideDrawer>
            : null
        }

        {
          <SigninRibbon />
        }

        {
          this.state.showSizeGuide &&
          <InformationModal
            show={this.state.showSizeGuide}
            toggleHandle={this.toggleSizeGuide}
            hideDefaultButton
            className="size-guide-modal"
          >
            <iframe src={sizeGuidePdf} style={{ width: '100%', height: '70vh' }}></iframe>
            {/* <embed src={sizeGuidePdf}  style={{ width: '70vw', height: '70vh' }}/> */}
          </InformationModal>
        }

        {
          this.state.showCertificates &&
          <CertificatesModal
            show={this.state.showCertificates}
            handleClose={this.toggleCertificateModal} />
        }


        {/* ==== PRODUCT DETAILS ===== */}
        {
          (productDetails && this.state.selectedColor && this.state.selectedMaterial) &&
          <section className="product-details">
            <div className="container">
              <BreadCrumb data={this.state.breadCrumbData} />
              <div className="col-top">
                {
                  this.state.selectedColor &&
                  <ProductImages
                    images={this.state.selectedColor.images}
                    videos={productDetails.videos}
                    sku={productDetails.sku_number}
                    isFavourite={productDetails.is_favourite} />
                }
                <div className="product-customizations">
                  <h3 className="product-code">{productDetails.sku_number}</h3>
                  <h1 className="product-name">
                    {this.state.selectedMaterial.material_name + ' '}
                    {materialColors.getText(this.state.selectedColor.colour_value) + ' '}
                    {productDetails.name}
                  </h1>
                  {
                    getToken() &&
                    <h2 className="product-price">
                      ${totalCost} <br />
                      {
                        productDetails.markup ?
                          <span className="retail-price-text">* Retail Price</span>
                          :
                          <Link to="/profile/markups" className="set-retail-price-link">Set Retail Price</Link>
                      }
                    </h2>
                  }
                  <p className="product-desc">{productDetails.description}</p>
                  <div className="col-product-option">
                    <div className="metal-info">
                      <h4 className="dark-desktop">Metal: {this.state.selectedMaterial.material_name}</h4>
                      {
                        productDetails.material_list.map(material => (
                          <span
                            key={material.material_id}
                            onClick={() => this.setState({ selectedMaterial: material })}
                            className={this.state.selectedMaterial.material_id === material.material_id ? "active" : null}>
                            {material.material_name}
                          </span>
                        ))
                      }
                    </div>
                    <div className="color-info">
                      <h4 className="dark-desktop">Color: {materialColors.getText(this.state.selectedColor.colour_value)}</h4>
                      {
                        productDetails.material_colour_list.map((color) => (
                          <span
                            key={color.colour_value}
                            onClick={() => this.setState({ selectedColor: color })}
                            className={this.state.selectedColor.colour_value === color.colour_value ?
                              `${materialColors.getText(color.colour_value).toLowerCase()}-color active`
                              :
                              `${materialColors.getText(color.colour_value).toLowerCase()}-color`
                            }
                          >
                          </span>
                        ))
                      }
                    </div>
                  </div>

                  <CustomizationOption
                    centerStoneOptions={this.state.centerStoneOptions || []}
                    meleeStoneOptions={this.state.meleeStoneOptions || []}
                    sizeGuideHandle={this.toggleSizeGuide}
                    page={true}
                  />
                  <div className="more-option">
                    <Button
                      bsStyle="link"
                      className="dark-desktop"
                      onClick={() => {
                        this.props.setCustomizationOption({
                          name: "customizationButtonText",
                          value: "Add to Cart"
                        });
                        this.props.toggleCustomizationOption()
                      }}
                    >
                      More Customization Options
                    </Button>
                  </div>

                  <div className="btn-wrapper sticky-mobile-bottom-buttons" style={!this.props.commonState.token && !getToken() ? { bottom: '9.7rem' } : {}}>
                    <CustomButton primary onClick={() => {
                      this.props.setCustomizationOption({
                        name: "customizationButtonText",
                        value: "Continue"
                      });
                      this.props.toggleCustomizationOption();
                    }}>
                      <Image src={cartIcon} /> Add to Cart
                    </CustomButton>
                    <CustomButton onClick={this.buyNowHandle}>
                      <Image src={buynowIcon} /> Buy now
                    </CustomButton>
                  </div>

                </div>
              </div>

              <div className="col-middle">
                <div className="product-inclusions">
                  <h3 className="product-price">WHY BUY FROM JEWEL FLEET</h3>
                  <h6 className="subtitle-desktop">We bring the power of global sourcing, right to your retail store</h6>
                  <p className="subtitle-desktop">Our aim is to be priced at significantly lower rates than our custom jewelry competitors, in any diamond category we service. Diamond rates, setting charges and finishing charges are all pre-negotiated by us, with our base of global partner factories</p>
                  <p className="subtitle-desktop">Speedy 6-8 business days delivery for all products</p>
                  <p className="subtitle-desktop">Hassle-free &amp; endless customization options with up to 95 variants possible for the bulk of our designs, including the use of lab-grown
                diamonds for your eco-conscious customers</p>
                  <p className="subtitle-desktop">We know you’re busy! Our easy-to-navigate website allows you to
                  save products into your own ‘Digital Store’, after which we provide
                  simple 4-step, order updates to provide you a reliable experience
                </p>
                </div>
                <ProductDetailsAccordion data={productDetails} />
              </div>
            </div>
          </section>
        }

        {/* ==== SIMILAR PRODUCTS ====== */}
        {
          similarProducts && similarProducts.similar_products.length > 0 &&
          <section className="similar-products">
            <Grid>
              <h3>Similar Products</h3>
              <h6>Select similar styles OR save your favorites to ‘My Digital Store’ by clicking the ‘HEART’ Icon</h6>
              <ProductItemsSlider data={similarProducts.similar_products} />
            </Grid>
          </section>
        }
        {/* === BANNER ====== */}
        <section>
          {
            similarProducts &&
            <FullImageSlider data={similarProducts.sales_banners} />
          }
        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  productDetailsState: state.ProductDetailsState,
  commonState: state.CommonState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  getProductDetailsApi,
  toggleCustomizationOption,
  setCustomizationOption,
  getSimilarProductsApi,
  clearProductDetails,
  getCartDataApi,
  resetValues
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
