import React from 'react';
import { orderStatus } from '../../../utils/Constant';

const OrderStatus = ({ status }) => {
  return (
    <span className="order-status" style={{
      color: orderStatus.getTextColor(status),
      backgroundColor: orderStatus.getBackgroundColor(status)
    }}>
      {orderStatus.getText(status)}
    </span>
  );
};

export default OrderStatus;