import React, { Component } from 'react';
import { Checkbox, Glyphicon, Button } from "react-bootstrap";
import { TotalWeight, CenterStoneShape, PRICE_MIN, PRICE_MAX, stoneSizeOptions } from '../../utils/Constant';
import { Range } from 'rc-slider';

class Filter extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.history.location.search);
    this.state = {
      min: params.get("from_price_range") ? Number(params.get("from_price_range")) : PRICE_MIN,
      max: params.get("to_price_range") ? Number(params.get("to_price_range")) : PRICE_MAX,
      showPriceRange: true,
      showLabGrown: true,
      showTotalWeight: true,
      showCenterStoneShape: true,
      showCenterStoneSize: true,
      showVideoAvailable: true,
    }
  }

  componentDidMount() {
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const params = new URLSearchParams(this.props.history.location.search);
    if (checked) {
      params.set(name, checked);
    } else {
      params.delete(name);
    }
    // Delete page number from url
    params.delete("p");
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: params.toString()
    })
  }

  handleCheckboxWithValue = (e) => {
    const { name, value } = e.target;
    const params = new URLSearchParams(this.props.history.location.search);
    if (params.get(name) && params.get(name).split(",").includes(value)) {
      let newValue = params.get(name).split(",").filter(val => val !== value);
      if (newValue.length > 0) {
        params.set(name, newValue.join(","));
      } else {
        params.delete(name);
      }
    } else {
      if (params.get(name)) {
        params.set(name, `${params.get(name)},${value}`);
      } else {
        params.set(name, `${value}`);
      }
    }
    // Delete page number from url
    params.delete("p");
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: params.toString()
    })
  }

  handleAfterRangeChange = ([min, max]) => {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("from_price_range", min);
    params.set("to_price_range", max);
    // Delete page number from url
    params.delete("p");
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: params.toString()
    });
  }

  handleRangeChange = ([min, max]) => {
    this.setState({ min, max });
  }

  clearFilter = () => {
    const search = new URLSearchParams();
    if (this.props.page === 'search') {
      const params = new URLSearchParams(this.props.history.location.search);
      search.set('searchText', params.get('searchText'));
    }

    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: search.toString()
    });
    this.setState({ min: PRICE_MIN, max: PRICE_MAX });
  }

  render() {
    const params = new URLSearchParams(this.props.history.location.search);
    return (
      <div className="filter-main-wrapper">
        <div className="filter-title">Filter
        <Button
            bsStyle="link"
            onClick={this.clearFilter}>CLEAR ALL</Button>
        </div>
        <div className={this.state.showPriceRange ? "filter-item-wrapper open" : "filter-item-wrapper"}>
          <div
            className="filter-item-header"
            onClick={() => this.setState(prevState => ({ showPriceRange: !prevState.showPriceRange }))}
          >
            <h3>Price Range</h3>
            <Glyphicon glyph={this.state.showPriceRange ? "minus" : "plus"} />
            <p className="product-desc">14KT Gold &amp; I2 Diamond</p>
          </div>
          <div className="filter-item-options">
            <h5>${this.state.min} - ${this.state.max}{this.state.max === PRICE_MAX ? "+" : ""}</h5>
            <div className="price-range-wrapper">
              <Range
                className="price-range"
                min={PRICE_MIN}
                max={PRICE_MAX}
                defaultValue={[PRICE_MIN, PRICE_MAX]}
                value={[this.state.min, this.state.max]}
                allowCross={false}
                onChange={this.handleRangeChange}
                onAfterChange={this.handleAfterRangeChange}
              />
            </div>
          </div>
        </div>

        <div className={this.state.showLabGrown ? "filter-item-wrapper open" : "filter-item-wrapper"}>
          <div
            className="filter-item-header"
            onClick={() => this.setState(prevState => ({ showLabGrown: !prevState.showLabGrown }))}
          >
            <h3>Lab-Grown Diamonds</h3>
            <Glyphicon glyph={this.state.showLabGrown ? "minus" : "plus"} />
          </div>
          <div className="filter-item-options">
            <Checkbox
              onChange={this.handleCheckbox}
              checked={params.get("is_lab_grown") ? true : false}
              name="is_lab_grown"
            >
              <span className="checkmark"></span>
              YES
            </Checkbox>
          </div>
        </div>
        {
          (this.props.page === "search") || this.props.matchParams.subcategory || this.props.matchParams.categoryId || this.props.matchParams.stoneId || this.props.matchParams.navStoneId || this.props.matchParams.categoryIdOnly ?
            <div className={this.state.showTotalWeight ? "filter-item-wrapper open" : "filter-item-wrapper"}>
              <div
                className="filter-item-header"
                onClick={() => this.setState(prevState => ({ showTotalWeight: !prevState.showTotalWeight }))}
              >
                <h3>Total Weight</h3>
                <Glyphicon glyph={this.state.showTotalWeight ? "minus" : "plus"} />
              </div>
              <div className="filter-item-options">
                {
                  TotalWeight.map((item, index) => (
                    <Checkbox
                      key={index}
                      onChange={this.handleCheckboxWithValue}
                      name="ct_weight_range_type"
                      value={item.value}
                      checked={params.get("ct_weight_range_type") ? params.get("ct_weight_range_type").includes(item.value) : false}
                    >
                      <span className="checkmark"></span>
                      {item.label}
                    </Checkbox>
                  ))
                }
              </div>
            </div>
            :
            null
        }
        {
          (this.props.page === "search") || this.props.matchParams.subcategory || this.props.matchParams.categoryId || this.props.matchParams.categoryIdOnly ?
            <div className={this.state.showCenterStoneShape ? "filter-item-wrapper open" : "filter-item-wrapper"}>
              <div
                className="filter-item-header"
                onClick={() => this.setState(prevState => ({ showCenterStoneShape: !prevState.showCenterStoneShape }))}
              >
                <h3>Center Stone Shape</h3>
                <Glyphicon glyph={this.state.showCenterStoneShape ? "minus" : "plus"} />
              </div>
              <div className="filter-item-options">
                {
                  CenterStoneShape.map((item, index) => (
                    <Checkbox
                      key={index}
                      onChange={this.handleCheckboxWithValue}
                      name="stone_shapes"
                      value={item.value}
                      checked={params.get("stone_shapes") ? params.get("stone_shapes").split(",").includes(item.value.toString()) : false}
                    >
                      <span className="checkmark"></span>
                      {item.label}
                    </Checkbox>
                  ))
                }
              </div>
            </div>
            :
            null
        }
        {
          (this.props.page === "search") || this.props.matchParams.subcategory || this.props.matchParams.categoryId || this.props.matchParams.stoneId || this.props.matchParams.navStoneId || this.props.matchParams.categoryIdOnly ?
            <div className={this.state.showCenterStoneSize ? "filter-item-wrapper open" : "filter-item-wrapper"}>
              <div
                className="filter-item-header"
                onClick={() => this.setState(prevState => ({ showCenterStoneSize: !prevState.showCenterStoneSize }))}
              >
                <h3>Center Stone Size</h3>
                <Glyphicon glyph={this.state.showCenterStoneSize ? "minus" : "plus"} />
              </div>
              <div className="filter-item-options">
                {
                  stoneSizeOptions.map((item, index) => (
                    <Checkbox
                      key={index}
                      onChange={this.handleCheckboxWithValue}
                      value={item.value}
                      name="stone_sizes"
                      checked={params.get("stone_sizes") ? params.get("stone_sizes").split(",").includes(item.value.toString()) : false}
                    >
                      <span className="checkmark"></span>
                      {item.label}
                    </Checkbox>
                  ))
                }
              </div>
            </div>
            :
            null
        }
        <div className={this.state.showVideoAvailable ? "filter-item-wrapper open" : "filter-item-wrapper"}>
          <div
            className="filter-item-header"
            onClick={() => this.setState(prevState => ({ showVideoAvailable: !prevState.showVideoAvailable }))}
          >
            <h3>Video Available</h3>
            <Glyphicon glyph={this.state.showVideoAvailable ? "minus" : "plus"} />
          </div>
          <div className="filter-item-options">
            <Checkbox
              onChange={this.handleCheckbox}
              name="has_video"
              checked={params.get("has_video") ? true : false}
            >
              <span className="checkmark"></span>
              YES
              </Checkbox>
          </div>
        </div>
      </div>
    )
  }
}
export default Filter;