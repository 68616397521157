import { GET_PRODUCT_LIST_BY_CATEGORY, CLEAR_PRODUCT_LIST, IS_PRODUCT_LIST_SERVER_API_CALLED } from "./ActionTypes";
import { getPageCount } from "../../utils/ReusableFunctions";
const INITIAL_STATE = {
  productList: null,
  totalPages: null,
  categoryDetails: null,
  isProductListServerApiCalled: false,
  collectionName: null,
};
const ProductListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_LIST_BY_CATEGORY:
      return {
        ...state,
        productList: [...action.payload.products],
        categoryDetails: action.payload.category_details ? action.payload.category_details : null,
        collectionName: action.payload.home_collection_title || null,
        totalPages: getPageCount(action.payload.total_product_count)
      };
    case CLEAR_PRODUCT_LIST:
      return {
        ...state,
        productList: null,
        categoryDetails: null,
        collectionName: null,
        totalPages: null
      }
    case IS_PRODUCT_LIST_SERVER_API_CALLED:
      return {
        ...state,
        isProductListServerApiCalled: action.payload
      }
    default:
      return state
  }
};
export default ProductListReducer