import React from 'react';
import { Glyphicon } from "react-bootstrap";
import { Link } from "react-router-dom";

const Pagination = (props) => {

  const renderPagination = () => {
    const { currentPage, totalPages } = props;
    const { history } = props;
    const params = new URLSearchParams(history.location.search);
    let data = [];

    // For prev link
    if (currentPage > 1) {
      params.set("p", currentPage - 1);
      data.push(
        <li className="prev" key={0}>
          <Link
            to={{
              pathname: history.location.pathname,
              search: params.toString(),
            }}>
            <Glyphicon glyph="triangle-left" className="left-arrow" /> PREV
        </Link>
        </li>
      )
    }

    // For middle pages
    if (currentPage < 9) {
      for (let i = 1; i <= Math.min(props.totalPages, 9); i++) {
        params.set("p", i);
        data.push(
          <li className={currentPage === i ? "active" : ""} key={i}>
            <Link
              to={{
                pathname: history.location.pathname,
                search: params.toString(),
              }}>{i}</Link>
          </li>
        )
      }
    } else {
      for (let i = currentPage - 5; i <= (currentPage + Math.min(totalPages - currentPage, 5)); i++) {
        params.set("p", i);
        data.push(
          <li className={currentPage === i ? "active" : ""} key={i}>
            <Link
              to={{
                pathname: history.location.pathname,
                search: params.toString(),
              }}>{i}</Link>
          </li>
        )
      }
    }

    // For next link
    if (currentPage < totalPages) {
      params.set("p", currentPage + 1);
      data.push(
        <li className="next" key={totalPages + 1}>
          <Link
            to={{
              pathname: history.location.pathname,
              search: params.toString(),
            }}>
            NEXT <Glyphicon glyph="triangle-right" className="right-arrow" />
          </Link>
        </li>
      )
    }
    return data;
  }

  return (
    <ul className="pagination-wrapper">
      {
        renderPagination().map(pageNumber => pageNumber)
      }
    </ul>
  )
}

export default Pagination;