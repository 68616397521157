const RECEIVE_POSTS = 'RECEIVE_POSTS';
const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
const SET_CUSTOMIZATION_OPTION = 'SET_CUSTOMIZATION_OPTION';
const GET_SIMILAR_PRODUCTS = 'GET_SIMILAR_PRODUCTS';
const CLEAR_PRODUCT_DETAILS = 'CLEAR_PRODUCT_DETAILS';
const RESET_VALUES = 'RESET_VALUES';

export {
  RECEIVE_POSTS,
  GET_PRODUCT_DETAILS,
  SET_CUSTOMIZATION_OPTION,
  GET_SIMILAR_PRODUCTS,
  CLEAR_PRODUCT_DETAILS,
  RESET_VALUES,
}