import React from 'react';
import { CustomButton } from '../../common';
import { Image } from 'react-bootstrap';


const ShopBanner = props => {
  return (
    <div className="shop-banner-item">
      <Image src={props.image} />
      <h5>{props.title}</h5>
      <h6>{props.description}</h6>
      <CustomButton inverse href={props.href}>
        {props.buttonText}
      </CustomButton>
    </div>
  );
};

export default ShopBanner;