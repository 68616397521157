import React, { useState } from 'react';
import { Thumbnail } from 'react-bootstrap';

import diamondShapeInactive1 from '../../../assets/images/diamondshapes/diamond-shape-inactive-1.png'
import diamondShapeActive1 from '../../../assets/images/diamondshapes/diamond-shape-active-1.png'
import diamondShapeInactive2 from '../../../assets/images/diamondshapes/diamond-shape-inactive-2.png'
import diamondShapeActive2 from '../../../assets/images/diamondshapes/diamond-shape-active-2.png'
import diamondShapeInactive3 from '../../../assets/images/diamondshapes/diamond-shape-inactive-3.png'
import diamondShapeActive3 from '../../../assets/images/diamondshapes/diamond-shape-active-3.png'
import diamondShapeInactive4 from '../../../assets/images/diamondshapes/diamond-shape-inactive-4.png'
import diamondShapeActive4 from '../../../assets/images/diamondshapes/diamond-shape-active-4.png'
import diamondShapeInactive5 from '../../../assets/images/diamondshapes/diamond-shape-inactive-5.png'
import diamondShapeActive5 from '../../../assets/images/diamondshapes/diamond-shape-active-5.png'

const DiamondShapesList = props => {

  const data = [
    {
      inactive: diamondShapeInactive1,
      active: diamondShapeActive1,
      href: `/products/stone/${props.categoryId ? props.categoryId + "/" : ""}1`
    },
    {
      inactive: diamondShapeInactive2,
      active: diamondShapeActive2,
      href: `/products/stone/${props.categoryId ? props.categoryId + "/" : ""}2`
    },
    {
      inactive: diamondShapeInactive5,
      active: diamondShapeActive5,
      href: `/products/stone/${props.categoryId ? props.categoryId + "/" : ""}3`
    },
    {
      inactive: diamondShapeInactive3,
      active: diamondShapeActive3,
      href: `/products/stone/${props.categoryId ? props.categoryId + "/" : ""}4`
    },
    {
      inactive: diamondShapeInactive4,
      active: diamondShapeActive4,
      href: `/products/stone/${props.categoryId ? props.categoryId + "/" : ""}5`
    },
  ]


  const [hoveredItem, setHoveredItem] = useState(null);


  return (
    <div className="diamond-shapes-list-wrapper">
      {
        data.map((shape, index) => (
          <Thumbnail
            key={index}
            href={shape.href}
            src={hoveredItem === index ? shape.active : shape.inactive}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
          />
        ))
      }
    </div>
  );
};

export default DiamondShapesList;