import React from 'react';
import Slider from "react-slick";
import { Grid } from 'react-bootstrap';


const BannerSlider = ({ banner }) => {

  const bannerSettings = {
    dots: true,
    arrows: false,
    className: 'banner',
    autoplay: true,
    autoplaySpeed: 5000
  }

  return (
    <section>
      <Slider {...bannerSettings}>
        {
          banner.map((item) => (
            <div key={item.id}>
              <a href={`//${item.link}`} rel='noopener noreferrer' target="_blank">
                <div style={{ backgroundImage: `url(${item.attachment_url})` }} className="background-wrapper">
                  <Grid>
                    <div className="slide-content">
                      {
                        item.title &&
                        <h3>{item.title}</h3>
                      }
                      {
                        item.description &&
                        <h4>{item.description}</h4>
                      }
                    </div>
                  </Grid>
                </div>
              </a>
            </div>
          ))
        }
      </Slider>
    </section>
  );
};

export default BannerSlider;