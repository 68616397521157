import React from 'react';
import { OrderStatus } from '../../common';

const OrderSummary = ({ summary }) => {
  return (
    <div className="order-summary">
      <div className="order-summary-item">
        <h5>Order Id</h5>
        <h6>{summary.code}</h6>
      </div>
      <div className="order-summary-item">
        <h5>order date</h5>
        <h6>{summary.created_on}</h6>
      </div>
      <div className="order-summary-item">
        <h5>Order status</h5>
        <OrderStatus status={summary.status_code} />
      </div>
      <div className="order-summary-item">
        <h5>Payment Reference id</h5>
        <h6>{summary.payment_reference_id}</h6>
      </div>
      <div className="order-summary-item">
        <h5>Order date's gold prices</h5>
        <h6>{summary.goldPrice}</h6>
      </div>
      <div className="order-summary-item">
        <h5>Total price</h5>
        <h6>${summary.total_price}</h6>
      </div>
    </div>
  );
};

export default OrderSummary;