import React, { useState } from 'react';
import { Panel, PanelGroup, Image, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import { StoneShape } from '../../../utils/Constant';


const ProductDetailsAccordion = (props) => {

  const [openedIndex, setOpenedIndex] = useState("1");

  const { productDetails } = props.productDetailsState;
  const { data } = props;

  return (
    <div className="product-details-accordion">
      <PanelGroup accordion id="accordion-example" onSelect={(eventKey) => setOpenedIndex(eventKey)} activeKey={openedIndex}>
        <Panel eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              <span>Product Details</span>
              <Glyphicon glyph={openedIndex === "1" ? "minus" : "plus"} />
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <h4>{data.description}</h4>
            {/* {
              data.productDetails.info.map((item, index) => (
                <div key={index} className="key-value-item-wrapper">
                  <h5>{item.key}</h5>
                  <h6>{item.value}</h6>
                </div>
              ))
            } */}
            <div className="key-value-item-wrapper">
              <h5>Style Number/SKU Number</h5>
              <h6>{productDetails.sku_number}</h6>
            </div>
            {/* {
              data.center_stone_configurations.primary_stone_shapes &&
              data.center_stone_configurations.primary_stone_shapes.length > 0 &&
              <div className="key-value-item-wrapper">
                <h5>Primary Stone Shapes</h5>
                <h6>
                  {
                    data.center_stone_configurations.primary_stone_shapes.map((shape, index) => {
                      if (index === data.center_stone_configurations.primary_stone_shapes.length - 1)
                        return StoneShape.getText(shape)
                      else
                        return StoneShape.getText(shape) + ', '
                    })
                  }
                </h6>
              </div>
            }
            {
              data.center_stone_configurations.primary_stone_size &&
              <div className="key-value-item-wrapper">
                <h5>Primary Stone Size (CT)</h5>
                <h6>{(data.center_stone_configurations.primary_stone_size).toFixed(2)}</h6>
              </div>
            } */}
            {
              data.center_stone_configurations.primary_stone_data &&
              data.center_stone_configurations.primary_stone_data.length > 0 &&
              <>
                <h6 className="product-code" style={{ textTransform: 'capitalize' }}>Primary stone details</h6>
                <div className="primary-stone-table">
                  <div className="stone-table-row">
                    <span>Stone Shape</span>
                    <span>Stone Size (CT)</span>
                    <span>No. of Stones</span>
                  </div>
                  {
                    data.center_stone_configurations.primary_stone_data.map((item, index) => (
                      <div className="stone-table-row">
                        <span>{StoneShape.getText(item.stone_shape)}</span>
                        <span>{item.stone_size.toFixed(2)}</span>
                        <span>{item.no_of_stones}</span>
                      </div>
                    ))
                  }
                </div>
              </>
            }
            <div className="key-value-item-wrapper">
              <h5>Total Diamond Weight (CT)</h5>
              <h6>{(data.total_weight_ct).toFixed(2)}</h6>
            </div>
            <div className="key-value-item-wrapper">
              <h5>Categories</h5>
              <h6>
                {
                  data.tags.map((tag, index) => {
                    if (index === data.tags.length - 1) return tag
                    else return tag + ', '
                  })
                }
              </h6>
            </div>
            <div className="key-value-item-wrapper">
              <h5>Total Number of Stones</h5>
              <h6>{data.total_number_of_stones}</h6>
            </div>
            <div className="key-value-item-wrapper">
              <h5>14KT Equivalent Gold Weight (GMS)</h5>
              <h6>{(data.material_weight_grams).toFixed(2)}</h6>
            </div>
          </Panel.Body>
        </Panel>
        <Panel eventKey="2">
          <Panel.Heading>
            <Panel.Title toggle>
              <span>Product Measurements</span>
              <Glyphicon glyph={openedIndex === "2" ? "minus" : "plus"} />
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            {/* {
              data.productMeasurements.map((item, index) => (
                <div key={index} className="key-value-item-wrapper">
                  <h5>{item.key}</h5>
                  <h6>{item.value}</h6>
                </div>
              ))
            } */}
            <div className="key-value-item-wrapper">
              <h5>Shoulder width</h5>
              <h6>{data.approx_shoulder_width_mm} mm</h6>
            </div>
            <div className="key-value-item-wrapper">
              <h5>Top height</h5>
              <h6>{data.approx_top_height_mm} mm</h6>
            </div>
            <div className="measurement-images">
              {
                data.product_spec_images.map((image, index) => (
                  <div key={index}>
                    <Image src={image} />
                  </div>
                ))
              }
            </div>
            <h4>
              *Product weights and dimensions are approximate. Our total diamond weight may have a +/-10% tolerance. All prices on this website are subject to change without notice. Whilst we make every effort to provide you with the most accurate &amp; up-to-date information, yet occasionally, one or more items on our website may be priced incorrectly. In the event that a product is listed at an incorrect price due to typographical, photographic, or technical error, we reserve the right to cancel said order.
            </h4>
          </Panel.Body>
        </Panel>
        <Panel eventKey="3">
          <Panel.Heading>
            <Panel.Title toggle>
              <span>Additional Notes</span>
              <Glyphicon glyph={openedIndex === "3" ? "minus" : "plus"} />
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <h4>
              {data.additional_note}
            </h4>
          </Panel.Body>
        </Panel>
      </PanelGroup>
    </div>
  );
};

const mapStateToProps = state => ({
  productDetailsState: state.ProductDetailsState
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsAccordion);