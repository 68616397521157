import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, ProductItem, CustomButton, EmptyState, Pagination } from '../common';
import { FormControl, Grid, Glyphicon } from "react-bootstrap";
import { SortBy, WeightRangeType, StoneShape, StoneSize } from '../../utils/Constant';
import { handleParamsWithMultipleValue } from '../../utils/ReusableFunctions';
import { getSearchProductApi } from './Api';
import { Filter, MobileFilter, MobileSort } from '../productList';
import Select from "react-select";
import { Link } from "react-router-dom";
import emptyProductsState from "../../assets/images/product-list-empty-state.svg";
import { clearSearchProducts } from './SearchAction';
import { Image } from "react-bootstrap";
import escapeIcon from '../../assets/icons/esc.svg';
import SigninRibbon from '../common/_utils/SigninRibbon';
import { getToken } from '../../utils/ManageToken';

class Search extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.history.location.search);
    this.state = {
      searchText: params.get("searchText") ? params.get("searchText") : "",
      showMobileFilter: false,
      showMobileSort: false,
    }
  }

  componentDidMount() {
    if (this.state.searchText)
      this.searchProducts();

    // Add key change event to handle escape key event
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    // Clear data on unmount to avoid making duplicate api calls on server & client
    this.props.clearSearchProducts();
    // Remove key change event
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.goBack();
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const params = new URLSearchParams();
    params.set('searchText', this.state.searchText);
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: params.toString()
    })
  }

  searchProducts = () => {
    const data = new URLSearchParams(this.props.location.search);
    if (data.get('ct_weight_range_type')) {
      data.set('ct_weight_range_type', handleParamsWithMultipleValue(data.get('ct_weight_range_type')));
    }
    if (data.get('stone_shapes')) {
      data.set('stone_shapes', handleParamsWithMultipleValue(data.get('stone_shapes')));
    }
    if (data.get('stone_sizes')) {
      data.set('stone_sizes', handleParamsWithMultipleValue(data.get('stone_sizes')));
    }
    data.append('page_no', data.get("p") || 1);

    if (!data.get('sort_by')) {
      // SortBy[3] represents New Arrival value.
      data.append('sort_by', SortBy[3].value);
    }

    data.append('query_parameter', data.get("searchText"));
    this.props.getSearchProductApi(data);
    if (window) window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // search string inside location is unique for every new params in routes.
    if (this.props.location.search !== prevProps.location.search) {
      this.searchProducts();
    }
  }

  showMobileFilter = () => {
    this.setState({ showMobileFilter: !this.state.showMobileFilter });
  }

  showMobileSort = () => {
    this.setState({ showMobileSort: !this.state.showMobileSort });
  }

  handleSortChange = (selectedOption) => {
    const params = new URLSearchParams(this.props.location.search);
    params.set("sort_by", selectedOption.value);
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: params.toString()
    })
  }

  formatLabel = (data) => {
    const params = new URLSearchParams(this.props.location.search);
    return (
      <span>
        {
          /* eslint eqeqeq: 0 */
          data.value == (params.get("sort_by") || 4) ? `Sort: ${data.label}` : data.label
        }
      </span>
    )
  }

  handleClearFilter = (name, value) => {
    const filterVal = value.toString();
    const params = new URLSearchParams(this.props.location.search);
    let newValue = params.get(name).split(",").filter(val => val !== filterVal);
    if (newValue.length > 0) {
      params.set(name, newValue.join(","));
    } else {
      params.delete(name);
    }
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: params.toString()
    })
  }

  showSelectedFilter = () => {
    const selectedFilter = [];
    const data = new URLSearchParams(this.props.location.search);
    if (data.get('ct_weight_range_type')) {
      JSON.parse(handleParamsWithMultipleValue(data.get('ct_weight_range_type'))).forEach(val => {
        selectedFilter.push(
          <div className="filter-summary light-desktop">
            {WeightRangeType.getText(val)}<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('ct_weight_range_type', val)} />
          </div>
        )
      });
    }
    if (data.get('stone_shapes')) {
      JSON.parse(handleParamsWithMultipleValue(data.get('stone_shapes'))).forEach(val => {
        selectedFilter.push(
          <div className="filter-summary light-desktop">
            {StoneShape.getText(val)}<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('stone_shapes', val)} />
          </div>
        )
      });
    }
    if (data.get('stone_sizes')) {
      JSON.parse(handleParamsWithMultipleValue(data.get('stone_sizes'))).forEach(val => {
        selectedFilter.push(
          <div className="filter-summary light-desktop">
            {StoneSize.getText(val)}<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('stone_sizes', val)} />
          </div>
        )
      });
    }
    if (data.get('is_lab_grown')) {
      selectedFilter.push(
        <div className="filter-summary light-desktop">
          Lab-Grown Diamonds<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('is_lab_grown', true)} />
        </div>
      )
    }
    if (data.get('has_video')) {
      selectedFilter.push(
        <div className="filter-summary light-desktop">
          Video Available<Glyphicon glyph="remove" onClick={() => this.handleClearFilter('has_video', true)} />
        </div>
      )
    }
    return selectedFilter;
  }

  handleClearSearch = (e) => {
    e.preventDefault();
    this.setState({ searchText: "" });
    this.nameInput.focus();
  }

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const sortByValue = params.get("sort_by") ? SortBy.find(item => item.value == params.get("sort_by")) : SortBy[3];
    const { searchProducts, totalPages } = this.props.searchState;

    return (
      <>
        <CustomNavbar />
        {
          searchProducts &&
          <SigninRibbon />
        }
        {
          this.state.showMobileFilter &&
          <MobileFilter history={this.props.history} handleClose={this.showMobileFilter} page="search" />
        }
        {
          this.state.showMobileSort &&
          <MobileSort history={this.props.history} handleClose={this.showMobileSort} />
        }
        <Grid>
          <div className="search-form">
            <form autoComplete="off" onSubmit={this.onSubmit}>
              <div className="input-wrapper">
                <Glyphicon glyph="search" />
                <FormControl
                  inputRef={(input) => { this.nameInput = input; }}
                  type="text"
                  value={this.state.searchText}
                  name="searchText"
                  placeholder="Type Product Identifiers (example - Twist, Infinity or Split Shank)"
                  onChange={this.handleChange}
                  autoFocus={true}
                />
                {this.state.searchText && <span className="clear" onClick={this.handleClearSearch}>Clear All</span>}
                <div className="escape-wrapper" onClick={this.goBack}>
                  <Image src={escapeIcon} alt="Escape icon" />
                  <h6>ESC</h6>
                </div>
              </div>

            </form>
          </div>
        </Grid>
        {
          searchProducts ?
            <div className="custom-container">
              {
                !this.props.commonState.isMobile ?
                  <div className="top-wrapper">
                    <div className="breadcrumb-wrapper">
                    </div>
                    <div className="sort-wrapper">
                      <div className="left">
                        {this.showSelectedFilter().map(item => item)}
                      </div>
                      <div className="right">
                        <Select
                          className="custom-select-container"
                          classNamePrefix="custom-select"
                          value={sortByValue}
                          isClearable={false}
                          isSearchable={false}
                          name="sort_by"
                          options={SortBy}
                          onChange={this.handleSortChange}
                          formatOptionLabel={this.formatLabel}
                          hideSelectedOptions={true}
                          components={{ IndicatorSeparator: () => null }}
                        />
                      </div>
                    </div>
                    <p className="product-desc">
                      *Default Price Listing in 14KT Gold &amp; Natural HI-I1 Diamonds.
                  <br /><span style={{ color: '#d4b195', fontWeight: 'bold' }}>*Retail Prices Displayed.</span>
                    </p>
                  </div>
                  :
                  <div className="mobile-btns sticky-mobile-bottom-buttons" style={!this.props.commonState.token && !getToken() ? { bottom: '9.5rem' } : {}}>
                    <CustomButton inverse onClick={this.showMobileSort}>Sort</CustomButton>
                    <CustomButton inverse onClick={this.showMobileFilter}>Filter</CustomButton>
                  </div>
              }
              <div className="content-wrapper">
                {
                  !this.props.commonState.isMobile ?
                    <div className="filter-wrapper">
                      <Filter history={this.props.history} page="search" />
                    </div>
                    :
                    null
                }
                {
                  searchProducts.length > 0 ?
                    <div className="main">
                      <div className="product-list">
                        {
                          searchProducts.map((product) => (
                            <Link to={`/product/${product.permalink}`} key={product.product_id}>
                              <ProductItem product={product} />
                            </Link>
                          ))
                        }
                      </div>
                      {
                        totalPages ?
                          totalPages > 1 ?
                            <Pagination
                              currentPage={params.get("p") ? Number(params.get("p")) : 1}
                              totalPages={totalPages}
                              history={this.props.history}
                            />
                            : null
                          : null
                      }
                    </div> :
                    <div className="main">
                      <EmptyState
                        title="No Product Found!"
                        subtitle="Please adjust your filters to see more product."
                        img={emptyProductsState} />
                    </div>
                }

              </div>
            </div>
            :
            null
        }

      </>

    )
  }
}

const mapStateToProps = state => ({
  searchState: state.SearchState,
  commonState: state.CommonState
});
const mapDispatchToProps = {
  getSearchProductApi,
  clearSearchProducts
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);