import React, { useState, useEffect } from 'react';
import { Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { addToFavouritesApi, removeFromFavouritesApi } from '../Api';
import { getToken } from '../../../utils/ManageToken';
import { connect } from 'react-redux';
import { toggleLoginSidebar } from '../CommonAction';
import { toast } from 'react-toastify';


const Favourite = ({ productSku, isFavourite, bordered, ...props }) => {
  const [favourite, setFavourite] = useState(isFavourite);
  // In order to reset the favourite state.
  useEffect(() => {
    setFavourite(isFavourite);
  }, [isFavourite])

  const toggleFavourites = (e) => {
    e.preventDefault();
    if (getToken()) {
      const data = new URLSearchParams();
      data.append("sku_number", productSku);
      if (favourite) {
        removeFromFavouritesApi(data, toggleFavouritesCallback);
      } else {
        addToFavouritesApi(data, toggleFavouritesCallback);
      }
    } else {
      // If not logged in then dont call api open login sidebar
      props.toggleLoginSidebar();
    }
  }
  const toggleFavouritesCallback = (msg) => {
    toast.success(msg);
    setFavourite(!favourite);
  }
  return (
    <OverlayTrigger
      placement="left"
      overlay={
        favourite ?
          <span></span> // because it is not accepting null
          :
          <Tooltip className="favourites-tooltip">
            {`Save Style to My Digital Store`}
          </Tooltip>
      }
    >
      <div
        className={bordered ? "favourite-wrapper bordered" : "favourite-wrapper"}
        onClick={toggleFavourites}>
        <Glyphicon glyph={favourite ? "heart" : "heart-empty"} />
      </div>
    </OverlayTrigger>
  );
};

const mapDispatchToProps = {
  toggleLoginSidebar
}
export default connect(null, mapDispatchToProps)(Favourite);
