import { toast } from "react-toastify";
import { PostLoginInstance } from "../../utils";
import { PAGE_LIMIT } from "../../utils/Constant";

export const getPreviousOrdersApi = (data, cb) => {
  data.append('limit', PAGE_LIMIT);
  PostLoginInstance.post('order/get-all-previous-orders', data)
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      toast.error(err.message);
    });
}