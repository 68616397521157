import React from 'react';
import { connect } from "react-redux";
import { BaseReactComponent, FormValidator, CustomTextControl, FormElement, Form, FormSubmitButton } from '../../utils/form';
import { toggleForgotPasswordSidebar, toggleEmailSentModal } from '../common/CommonAction'
import { forgotPasswordApi } from './Api';

class ForgotPassword extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    }
  }

  componentDidMount() { }

  onValidSubmit = () => {
    const data = new URLSearchParams();
    data.append('email', this.state.email);
    forgotPasswordApi(data, this.forgotPasswordCallback)
  }

  forgotPasswordCallback = () => {
    this.props.toggleForgotPasswordSidebar();
    this.props.toggleEmailSentModal();
  }

  render() {
    return (
      <>
        <div className="login-wrapper">
          <h3 className="h3-desktop">Forgot Password</h3>
          <Form onValidSubmit={this.onValidSubmit}>
            <FormElement
              valueLink={this.linkState(this, "email")}
              label="Email"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                },
                {
                  validate: FormValidator.isEmail,
                  message: "Invalid Email"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter your Email",
                }
              }}
            />


            <FormSubmitButton customClass="custom-btn" formError={this.state.inProgress}
              progressMessage="Validating..">
              CHANGE PASSWORD
          </FormSubmitButton>

          </Form>
        </div>

      </>
    )
  }
}


const mapStateToProps = state => ({
  commonState: state.CommonState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  toggleForgotPasswordSidebar,
  toggleEmailSentModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);