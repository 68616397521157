import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, PageHeader } from '../common';
import { Grid, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// images
import ordersIcon from '../../assets/images/profile/order-icon.svg';
import accountDetailsIcon from '../../assets/images/profile/account-detail.svg';
import shippingAddressIcon from '../../assets/images/profile/shipping-address.svg';
import digitalStoreIcon from '../../assets/images/profile/my-digital-store.svg';
import previousStylesIcon from '../../assets/images/profile/previous-order-icon.svg';
import logoutIcon from '../../assets/images/profile/logout-icon.svg';
import profileImage from '../../assets/images/profile/profile.svg';
import changePhoto from '../../assets/images/profile/change-photo.svg';
import { profileApi, uploadProfilePhoto } from './Api';
import { deleteToken, deleteCookie } from '../../utils/ManageToken';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.fileUploadRef = React.createRef();
    this.state = {
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          active: true
        },
      ],
      accountName: 'John Doe',
      accountImage: profileImage,
      profileItems: [
        {
          name: 'Orders',
          image: ordersIcon,
          href: '/profile/orders'
        },
        {
          name: 'Account Details',
          image: accountDetailsIcon,
          href: '/profile/account-details'
        },
        {
          name: 'Set Retail Markups',
          image: ordersIcon,
          href: '/profile/markups'
        },
        {
          name: 'My Digital Store',
          image: digitalStoreIcon,
          href: '/profile/digital-store'
        },
        {
          name: 'Previously Ordered Styles',
          image: previousStylesIcon,
          href: '/profile/previous-orders'
        },

        {
          name: 'Shipping Address',
          image: shippingAddressIcon,
          href: '/profile/address'
        },
        {
          name: 'Logout',
          image: logoutIcon,
          clickHandle: this.logoutHandle
        }
      ],

    }
  }

  componentDidMount() {
    profileApi(this.profileApiCallback);
  }


  profileApiCallback = (data) => {
    if (data.profile_image_url) {
      this.setState({
        accountName: data.user_name,
        accountImage: data.profile_image_url
      })
    } else {
      this.setState({
        accountName: data.user_name,
      })
    }
  }

  logoutHandle = () => {
    deleteToken();
    deleteCookie();
    window.location = "/";
  }

  handleUploadImage = () => {
    const file = this.fileUploadRef.current.files[0];
    const data = new FormData();
    data.append('file', file);
    uploadProfilePhoto(data, (img) => this.setState({ accountImage: img }));
  }

  render() {
    return (
      <>
        <CustomNavbar />
        <section className="profile">
          <Grid>


            <PageHeader title="Profile" breadcrumbData={this.state.breadcrumbData} />

            <div className="assign-flex profile-content">

              <div className="dsk-1 tab-1">
                <div className="account-details">
                  <div className="profile-image-wrapper">
                    <Image src={this.state.accountImage} className="profile-image" />
                    <div className="change-photo-wrapper" onClick={() => this.fileUploadRef.current.click()}>
                      <Image src={changePhoto} className="change-photo" />
                      <input
                        type="file"
                        accept="image/*"
                        ref={this.fileUploadRef}
                        style={{ display: 'none' }}
                        onChange={this.handleUploadImage} />
                    </div>
                  </div>
                  <h3 className="h3-desktop">{this.state.accountName}</h3>
                </div>
              </div>

              {
                this.state.profileItems.map((item, index) => {

                  const itemMarkup =
                    <div className="profile-item">
                      <Image src={item.image} />
                      <h6 className="product-card-title">{item.name}</h6>
                    </div>

                  return (
                    <div className="dsk-2 tab-2 mob-1" key={index}>
                      {
                        item.href ?
                          <Link to={item.href}>
                            {itemMarkup}
                          </Link>
                          :
                          <div onClick={item.clickHandle}>
                            {itemMarkup}
                          </div>
                      }
                    </div>
                  )
                })
              }

            </div>
          </Grid>

        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  profileState: state.ProfileState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);