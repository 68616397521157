import React from 'react';
import { connect } from "react-redux";
import { BaseReactComponent, FormValidator, CustomTextControl, FormElement, Form, FormSubmitButton } from '../../utils/form';
import { toggleForgotPasswordSidebar, toggleLoginSidebar } from '../common/CommonAction'
import { loginApi } from './Api';
import { withRouter } from 'react-router-dom';
import { addUpdateProductToCartApi } from '../common/Api';


class Login extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    }
  }

  componentDidMount() { }

  onValidSubmit = () => {
    const data = new URLSearchParams();
    data.append('email', this.state.email);
    data.append('password', this.state.password);

    loginApi(data, this.loginCallback);
  }

  loginCallback = () => {
    const savedProduct = localStorage.getItem("addProductToCart");
    if (savedProduct) {
      const data = new URLSearchParams(savedProduct);
      addUpdateProductToCartApi(data, () => {
        localStorage.removeItem("addProductToCart");
        this.props.history.go(0)
      });
    } else {
      this.props.history.go(0);
    }
  }


  render() {
    return (
      <div className="login-wrapper">
        <h3 className="h3-desktop">Sign in</h3>
        <h6 className="light-desktop">
          or
          <span onClick={() => { this.props.toggleLoginSidebar(); this.props.history.push('/signup') }}
            className="dark-desktop signup-link">
            Create New Account
          </span>
        </h6>
        <Form onValidSubmit={this.onValidSubmit}>
          <FormElement
            valueLink={this.linkState(this, "email")}
            label="Email"
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "This field is mandatory"
              },
              {
                validate: FormValidator.isEmail,
                message: "Invalid Email"
              }
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                placeholder: "Enter your Email",
              }
            }}
          />

          <FormElement
            valueLink={this.linkState(this, "password")}
            label="Password"
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "This field is mandatory"
              },
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                type: "password",
                placeholder: "Enter Password"
              }
            }}
          />

          <FormSubmitButton customClass="custom-btn" formError={this.state.inProgress}
            progressMessage="Validating..">
            SIGN IN
          </FormSubmitButton>

        </Form>
        <span
          onClick={() => { this.props.toggleLoginSidebar(); this.props.toggleForgotPasswordSidebar() }}
          className="forgot-password">
          Forgot Password?
        </span>
      </div>
    )
  }
}

const mapDispatchToProps = {
  // getPosts: fetchPosts,
  toggleForgotPasswordSidebar,
  toggleLoginSidebar
}

export default connect(null, mapDispatchToProps)(withRouter(Login));