import React, { PureComponent } from 'react';
import { Checkbox, Glyphicon, Button, Image } from "react-bootstrap";
import { TotalWeight, CenterStoneShape, PRICE_MIN, PRICE_MAX, stoneSizeOptions } from '../../utils/Constant';
import { Range } from 'rc-slider';
import { CustomButton } from '../common';
import backArrow from '../../assets/icons/back-arrow.svg';

class MobileFilter extends PureComponent {
  constructor(props) {
    super(props);

    // Set the params from URL
    const params = new URLSearchParams(this.props.history.location.search);
    const searchText = params.get('searchText');
    const is_lab_grown = params.get('is_lab_grown') === "true";
    const has_video = params.get('has_video') === "true";
    // Convert from string to array of string and then to array of numbers
    const ct_weight_range_type = params.get('ct_weight_range_type') ? params.get('ct_weight_range_type').split(',').map(Number) : [];
    // Convert from string to array of string and then to array of numbers
    const stone_shapes = params.get('stone_shapes') ? params.get('stone_shapes').split(',').map(Number) : [];
    // Convert from string to array of string and then to array of numbers
    const stone_sizes = params.get('stone_sizes') ? params.get('stone_sizes').split(',').map(Number) : [];

    this.state = {
      searchText,
      min: params.get("from_price_range") ? Number(params.get("from_price_range")) : PRICE_MIN,
      max: params.get("to_price_range") ? Number(params.get("to_price_range")) : PRICE_MAX,
      showPriceRange: true,
      showLabGrown: true,
      showTotalWeight: true,
      showCenterStoneShape: true,
      showCenterStoneSize: true,
      showVideoAvailable: true,
      is_lab_grown,
      has_video,
      ct_weight_range_type,
      stone_shapes,
      stone_sizes
    }
  }

  componentDidMount() {
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  }

  handleCheckboxWithValue = (e) => {
    let { name, value } = e.target;
    value = Number(value);
    let presentValues = [...this.state[name]];
    if (presentValues.includes(value)) {
      presentValues = presentValues.filter(val => val !== value);
    } else {
      presentValues.push(value);
    }
    this.setState({ [name]: presentValues });
  }

  handleRangeChange = ([min, max]) => {
    this.setState({ min, max });
  }

  handleApplyFilter = () => {
    const { searchText, min, max, is_lab_grown, has_video, ct_weight_range_type, stone_shapes, stone_sizes } = this.state;
    const params = new URLSearchParams();
    if (this.props.page === "search") {
      params.append('searchText', searchText);
    }
    params.append("from_price_range", min);
    params.append("to_price_range", max);
    if (is_lab_grown)
      params.append("is_lab_grown", is_lab_grown);
    if (has_video)
      params.append("has_video", has_video);
    if (ct_weight_range_type.length > 0)
      params.append("ct_weight_range_type", `${ct_weight_range_type.join(",")}`);
    if (stone_shapes.length > 0)
      params.append("stone_shapes", `${stone_shapes.join(",")}`);
    if (stone_sizes.length > 0)
      params.append("stone_sizes", `${stone_sizes.join(",")}`);

    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: params.toString()
    })
    // Close mobile filter
    this.props.handleClose();
  }

  clearFilter = () => {
    // this.props.history.replace({ pathname: this.props.history.location.pathname });
    // Reset the entire set but do not redirect as myntra, flipkart does not redirect
    this.setState({
      min: PRICE_MIN,
      max: PRICE_MAX,
      is_lab_grown: false,
      has_video: false,
      ct_weight_range_type: [],
      stone_shapes: [],
      stone_sizes: []
    });
  }

  render() {
    return (
      <div className="mobile-filter-wrapper">
        <div className="mobile-filter">
          <div className="filter-title">
            <Image src={backArrow} onClick={this.props.handleClose} className="back-icon" />
            Filter
            <Button bsStyle="link" onClick={this.clearFilter}>CLEAR ALL</Button></div>
          <div className={this.state.showPriceRange ? "filter-item-wrapper open" : "filter-item-wrapper"}>
            <div
              className="filter-item-header"
              onClick={() => this.setState(prevState => ({ showPriceRange: !prevState.showPriceRange }))}
            >
              <h3>Price Range</h3>
              <Glyphicon glyph={this.state.showPriceRange ? "minus" : "plus"} />
              <p className="product-desc">14KT Gold &amp; I2 Diamond</p>
            </div>
            <div className="filter-item-options">
              <h5>${this.state.min} - ${this.state.max}{this.state.max === PRICE_MAX ? "+" : ""}</h5>
              <div className="price-range-wrapper">
                <Range
                  className="price-range"
                  min={PRICE_MIN}
                  max={PRICE_MAX}
                  defaultValue={[PRICE_MIN, PRICE_MAX]}
                  value={[this.state.min, this.state.max]}
                  allowCross={false}
                  onChange={this.handleRangeChange}
                />
              </div>
            </div>
          </div>
          <div className={this.state.showLabGrown ? "filter-item-wrapper open" : "filter-item-wrapper"}>
            <div
              className="filter-item-header"
              onClick={() => this.setState(prevState => ({ showLabGrown: !prevState.showLabGrown }))}
            >
              <h3>Lab-Grown Diamonds</h3>
              <Glyphicon glyph={this.state.showLabGrown ? "minus" : "plus"} />
            </div>
            <div className="filter-item-options">
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.is_lab_grown}
                name="is_lab_grown"
              >
                <span className="checkmark"></span>
                YES
            </Checkbox>
            </div>
          </div>
          {
            (this.props.page === "search") || this.props.matchParams.subcategory || this.props.matchParams.categoryId
              || this.props.matchParams.stoneId || this.props.matchParams.navStoneId || this.props.matchParams.categoryIdOnly ?
              <div className={this.state.showTotalWeight ? "filter-item-wrapper open" : "filter-item-wrapper"}>
                <div
                  className="filter-item-header"
                  onClick={() => this.setState(prevState => ({ showTotalWeight: !prevState.showTotalWeight }))}
                >
                  <h3>Total Weight</h3>
                  <Glyphicon glyph={this.state.showTotalWeight ? "minus" : "plus"} />
                </div>
                <div className="filter-item-options">
                  {
                    TotalWeight.map(item => (
                      <Checkbox
                        key={item.value}
                        onChange={this.handleCheckboxWithValue}
                        name="ct_weight_range_type"
                        value={item.value}
                        checked={this.state.ct_weight_range_type.includes(item.value)}
                      >
                        <span className="checkmark"></span>
                        {item.label}
                      </Checkbox>
                    ))
                  }
                </div>
              </div>
              :
              null
          }
          {
            (this.props.page === "search") || this.props.matchParams.subcategory || this.props.matchParams.categoryId || this.props.matchParams.categoryIdOnly ?
              <div className={this.state.showCenterStoneShape ? "filter-item-wrapper open" : "filter-item-wrapper"}>
                <div
                  className="filter-item-header"
                  onClick={() => this.setState(prevState => ({ showCenterStoneShape: !prevState.showCenterStoneShape }))}
                >
                  <h3>Center Stone Shape</h3>
                  <Glyphicon glyph={this.state.showCenterStoneShape ? "minus" : "plus"} />
                </div>
                <div className="filter-item-options">
                  {
                    CenterStoneShape.map(item => (
                      <Checkbox
                        key={item.value}
                        onChange={this.handleCheckboxWithValue}
                        name="stone_shapes"
                        value={item.value}
                        checked={this.state.stone_shapes.includes(item.value)}
                      >
                        <span className="checkmark"></span>
                        {item.label}
                      </Checkbox>
                    ))
                  }
                </div>
              </div>
              :
              null
          }
          {
            (this.props.page === "search") || this.props.matchParams.subcategory || this.props.matchParams.categoryId
              || this.props.matchParams.stoneId || this.props.matchParams.navStoneId || this.props.matchParams.categoryIdOnly
              ?
              <div className={this.state.showCenterStoneSize ? "filter-item-wrapper open" : "filter-item-wrapper"}>
                <div
                  className="filter-item-header"
                  onClick={() => this.setState(prevState => ({ showCenterStoneSize: !prevState.showCenterStoneSize }))}
                >
                  <h3>Center Stone Size</h3>
                  <Glyphicon glyph={this.state.showCenterStoneSize ? "minus" : "plus"} />
                </div>
                <div className="filter-item-options">
                  {
                    stoneSizeOptions.map(item => (
                      <Checkbox
                        key={item.value}
                        onChange={this.handleCheckboxWithValue}
                        value={item.value}
                        name="stone_sizes"
                        checked={this.state.stone_sizes.includes(item.value)}
                      >
                        <span className="checkmark"></span>
                        {item.label}
                      </Checkbox>
                    ))
                  }
                </div>
              </div>
              :
              null
          }
          <div className={this.state.showVideoAvailable ? "filter-item-wrapper open" : "filter-item-wrapper"}>
            <div
              className="filter-item-header"
              onClick={() => this.setState(prevState => ({ showVideoAvailable: !prevState.showVideoAvailable }))}
            >
              <h3>Video Available</h3>
              <Glyphicon glyph={this.state.showVideoAvailable ? "minus" : "plus"} />
            </div>
            <div className="filter-item-options">
              <Checkbox
                onChange={this.handleCheckbox}
                name="has_video"
                checked={this.state.has_video}
              >
                <span className="checkmark"></span>
                YES
              </Checkbox>
            </div>
          </div>
          <div className="mobile-btns sticky-mobile-bottom-buttons">
            <CustomButton inverse onClick={this.props.handleClose}>Close</CustomButton>
            <CustomButton onClick={this.handleApplyFilter}>Apply</CustomButton>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileFilter;