import React from 'react';
import CustomButton from './CustomButton';

const InformationModal = props => {
  return (
    <div onClick={props.toggleHandle}
      className={`information-modal ${props.show ? "open" : ""}`}>
      <div className={`information-modal-body ${props.className ? props.className : ""}`} onClick={(e) => e.stopPropagation()}>
        {props.children}
        {
          !props.hideDefaultButton &&
          <CustomButton onClick={props.toggleHandle}>OK</CustomButton>
        }
      </div>
    </div>
  );
};

export default InformationModal;