import React, { Component } from 'react';
import Select from "react-select";
import { Checkbox, FormControl, Glyphicon } from "react-bootstrap";
import CustomButton from './_utils/CustomButton';
import { connect } from 'react-redux';
import { toggleEditCartSidebar, toggleCart } from './CommonAction';
import { materialColors, RingSizeOptions } from '../../utils/Constant';
import { getCartItemApi, addUpdateProductToCartApi, getCartDataApi } from './Api';
import { toast } from 'react-toastify';
import { sortArrayOfObjects } from '../../utils/ReusableFunctions';


class EditCart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editCartData: null, // full data from api

      totalCost: null,
      certificationCharge: true,
      laserEngraving: true, // user wants to do laser engraving
      laserEngravingName: null,
      quantity: null,
      personalNote: null,
      selectedColor: null,
      selectedMaterial: null,
      centerStone: null,
      meleeStone: null,
      ringSize: null,

    }
  }

  handleSelectChange = (selectedOption, { name }) => {
    this.setState({
      [name]: selectedOption
    }, this.calculateTotalCost)
  }

  handleCheckbox = (e) => {
    const { name } = e.target;
    this.setState({
      [name]: !this.state[name]
    }, this.calculateTotalCost)
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }

  // ============ when "save changes" is clicked =============

  updateProductInCart = () => {
    const {
      editCartData,
      laserEngravingName,
      certificationCharge,
      laserEngraving,
      centerStone,
      meleeStone,
      ringSize,
      personalNote,
      quantity,
      selectedColor,
      selectedMaterial
    } = this.state;

    let centerStoneObj, meleeStoneObj;
    if (centerStone)
      centerStoneObj = {
        configuration_type: 1, // center stone
        stone_type: 1,  // diamond
        stone_fleet_master_id: centerStone.fleet_code_master_id
      }

    if (meleeStone)
      meleeStoneObj = {
        configuration_type: 2, // melee stone
        stone_type: 1, // diamond
        stone_fleet_master_id: meleeStone.fleet_code_master_id
      }

    const data = new URLSearchParams();
    data.append("sku_number", editCartData.sku_number);
    data.append("quantity", quantity);
    data.append("ring_size", parseFloat(ringSize.value));
    data.append("is_certification_charge_included", certificationCharge);
    data.append("personal_note", personalNote);
    data.append("is_laser_engraving", laserEngraving);
    data.append("laser_engraving_message", laserEngravingName);
    data.append("material_colour", JSON.stringify(selectedColor));
    data.append("material", JSON.stringify(selectedMaterial));
    if (centerStone)
      data.append("center_stone_configuration", JSON.stringify(centerStoneObj));
    if (meleeStone)
      data.append("mele_stone_configuration", JSON.stringify(meleeStoneObj));
    data.append("code", this.props.commonState.editCartId);

    addUpdateProductToCartApi(data, this.updateProductCallback);

  }

  updateProductCallback = () => {
    this.props.toggleEditCartSidebar();
    this.props.getCartDataApi();
    toast.success("Cart item updated");
  }

  // ========== get initial details on mount ================

  componentDidMount() {
    const data = new URLSearchParams();
    data.append('code', this.props.commonState.editCartId);
    getCartItemApi(data, this.getCartItemCallback);
  }

  getCartItemCallback = (data) => {
    const selectedMaterial = data.cart_item_details.material_list.find(material => material.is_selected);
    const selectedColor = data.cart_item_details.material_colour_list.find(color => color.is_selected);

    let centerStone = null,
      centerStoneOptions = null,
      meleeStone = null,
      meleeStoneOptions = null;

    if (data.cart_item_details.center_stone_configurations &&
      data.cart_item_details.center_stone_configurations.stone_quality_master_ids)
      centerStoneOptions = data.cart_item_details.center_stone_configurations.stone_quality_master_ids.map(stone => {
        if (stone.is_selected) centerStone = {
          ...stone,
          value: stone.fleet_code_master_id,
          label: stone.fleet_code
        };
        return {
          ...stone,
          value: stone.fleet_code_master_id,
          label: stone.fleet_code
        }
      })


    if (data.cart_item_details.mele_stone_configurations &&
      data.cart_item_details.mele_stone_configurations.stone_quality_master_ids)
      meleeStoneOptions = data.cart_item_details.mele_stone_configurations.stone_quality_master_ids.map(stone => {
        if (stone.is_selected) meleeStone = {
          ...stone,
          value: stone.fleet_code_master_id,
          label: stone.fleet_code
        };
        return {
          ...stone,
          value: stone.fleet_code_master_id,
          label: stone.fleet_code
        }
      })

    if (centerStoneOptions)
      sortArrayOfObjects(centerStoneOptions, 'stone_cost', true);
    if (meleeStoneOptions)
      sortArrayOfObjects(meleeStoneOptions, 'stone_cost', true);

    const ringSizeObj = {
      value: data.cart_item_details.ring_size.toFixed(2),
      label: data.cart_item_details.ring_size.toFixed(2),
    }

    this.setState({
      editCartData: data.cart_item_details,
      selectedMaterial,
      selectedColor,
      centerStoneOptions,
      centerStone,
      meleeStoneOptions,
      meleeStone,
      laserEngraving: data.cart_item_details.opted_for_laser_engraving,
      laserEngravingName: data.cart_item_details.laser_engraving_message,
      certificationCharge: data.cart_item_details.opted_for_certification,
      quantity: data.cart_item_details.quantity,
      personalNote: data.cart_item_details.personal_note,
      ringSize: ringSizeObj,
    }, this.calculateTotalCost);
  }

  // ========== change total price ==============

  calculateTotalCost = () => {

    let total;

    const {
      centerStone,
      meleeStone,
      editCartData,
      selectedMaterial,
      quantity,
      laserEngraving,
      certificationCharge,
    } = this.state;

    // calculating initial total charges
    const centerStoneCost = centerStone ? centerStone.stone_cost : 0;
    const meleeStoneCost = meleeStone ? meleeStone.stone_cost : 0;
    const labourCost = editCartData.cost_of_labour;
    const materialCost = selectedMaterial.material_cost;
    total = centerStoneCost + meleeStoneCost + labourCost + materialCost;

    // adding additional markup
    const additionalMarkupPercentage = editCartData.additional_markup_percentage;
    const additionalMarkupCost = total * additionalMarkupPercentage / 100;
    total += additionalMarkupCost;

    // subtracting discount
    const discountPercentage = editCartData.discount_percentage;
    const discount = total * discountPercentage / 100;
    total -= discount;

    // adding laser engraving charges
    const laserEngravingCharges = editCartData.cost_of_laser_engraving;
    const optedForLaserEngraving = laserEngraving;
    if (optedForLaserEngraving) total += laserEngravingCharges;

    // adding certification charges
    const certificationCost = editCartData.credit_card_certificate;
    const optedForCertification = certificationCharge;
    if (optedForCertification) total += certificationCost;

    // multiply quantity
    total *= quantity;

    // console.log("===========");
    // console.log("center stone cost: ", centerStoneCost);
    // console.log("melee stone cost: ", meleeStoneCost);
    // console.log("labour cost: ", labourCost);
    // console.log("material cost: ", materialCost);
    // console.log("markup percentage: ", additionalMarkupPercentage);
    // console.log("markup cost: ", additionalMarkupCost);
    // console.log("discount percentage: ", discountPercentage);
    // console.log("discount: ", discount);
    // console.log("final total: ", total);


    this.setState({
      totalCost: total.toFixed(2)
    })

  }


  render() {
    return (
      <>
        {
          this.state.editCartData &&
          <div className="edit-cart" >
            <h4 className="title">Edit Cart</h4>
            <div className="col-product-option">
              <div className="metal-info">
                <h4 className="dark-desktop">Metal: {this.state.selectedMaterial.material_name}</h4>
                {
                  this.state.editCartData.material_list.map(material => (
                    <span
                      key={material.material_id}
                      onClick={() => this.setState({
                        selectedMaterial: material
                      }, this.calculateTotalCost)}
                      className={this.state.selectedMaterial.material_id === material.material_id ? "active" : null}>
                      {material.material_name}
                    </span>
                  ))
                }
              </div>
              <div className="color-info">
                <h4 className="dark-desktop">Color: {this.state.selectedColor.colour_text}</h4>
                {
                  this.state.editCartData.material_colour_list.map((color) => (
                    <span
                      key={color.colour_value}
                      onClick={() => this.setState({ selectedColor: color })}
                      className={this.state.selectedColor.colour_value === color.colour_value ?
                        `${materialColors.getText(color.colour_value).toLowerCase()}-color active`
                        :
                        `${materialColors.getText(color.colour_value).toLowerCase()}-color`
                      }
                    >
                    </span>
                  ))
                }
              </div>
            </div>

            <div className="cus-opt">
              <div className="select-wrapper">
                {
                  this.state.centerStone &&
                  <>
                    <label>Center Stone Quality</label>
                    <Select
                      className="cus-opt-btn custom-select-container"
                      classNamePrefix="custom-select"
                      value={this.state.centerStone}
                      isClearable={false}
                      isSearchable={false}
                      name="centerStone"
                      options={this.state.centerStoneOptions}
                      onChange={this.handleSelectChange}
                      hideSelectedOptions={true}
                      components={{ IndicatorSeparator: () => null }}
                    />
                  </>
                }

                {
                  this.state.meleeStone &&
                  <>
                    <label>Melee Stone Quality</label>
                    <Select
                      className="cus-opt-btn custom-select-container"
                      classNamePrefix="custom-select"
                      value={this.state.meleeStone}
                      isClearable={false}
                      isSearchable={false}
                      name="meleeStone"
                      options={this.state.meleeStoneOptions}
                      onChange={this.handleSelectChange}
                      hideSelectedOptions={true}
                      components={{ IndicatorSeparator: () => null }}
                    />
                  </>
                }

                <label>Ring Size</label>
                <Select
                  className="cus-opt-btn custom-select-container"
                  classNamePrefix="custom-select"
                  value={this.state.ringSize}
                  isClearable={false}
                  isSearchable={false}
                  name="ringSize"
                  options={RingSizeOptions}
                  onChange={this.handleSelectChange}
                  hideSelectedOptions={true}
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>

              {
                this.state.editCartData.is_laser_engraving &&
                <Checkbox
                  onChange={this.handleCheckbox}
                  name="laserEngraving"
                  checked={this.state.laserEngraving}
                >
                  <span className="checkmark"></span>
                  <span>Laser Engraving </span> Cost of including this
                  <span> ${this.state.editCartData.cost_of_laser_engraving}</span>
                </Checkbox>
              }

              {
                this.state.laserEngraving &&
                <FormControl
                  type="text"
                  value={this.state.laserEngravingName}
                  name="laserEngravingName"
                  placeholder="Max 10 Characters"
                  onChange={this.handleChange}
                />
              }

              <Checkbox
                onChange={this.handleCheckbox}
                name="certificationCharge"
                checked={this.state.certificationCharge}
              >
                <span className="checkmark"></span>
                <span>Certification Charge</span> Cost of including this
                <span> ${this.state.editCartData.credit_card_certificate}</span>
              </Checkbox>


              <label className="note">Personal Note</label>
              <FormControl
                value={this.state.personalNote}
                onChange={this.handleChange}
                name="personalNote"
                componentClass="textarea"
                placeholder="Please “Comment” any information you would like to reference this order for your use" rows={5} />


              <div className="quantity-wrapper">
                <h5>Quantity</h5>
                <div className="quantity">
                  <Glyphicon glyph="plus" onClick={() =>
                    this.setState((prevState) => {
                      return {
                        quantity: prevState.quantity + 1
                      }
                    }, this.calculateTotalCost)
                  } />
                  <span className="product-card-title">{this.state.quantity}</span>
                  <Glyphicon glyph="minus" onClick={() => {
                    if (this.state.quantity > 1)
                      this.setState((prevState) => {
                        return {
                          quantity: prevState.quantity - 1
                        }
                      }, this.calculateTotalCost)
                  }} />
                </div>
              </div>

            </div>

            <div style={{ flex: 1 }}></div>

            <div className="bottom-button-wrapper sticky-bottom-buttons">
              <div className="total-price">
                <span>Total</span>
                <h5 className="product-price">${this.state.totalCost}</h5>
              </div>
              <CustomButton onClick={this.updateProductInCart}>
                save changes
              </CustomButton>
            </div>

          </div>
        }

      </>
    );
  };
}


const mapStateToProps = state => ({
  commonState: state.CommonState
});

const mapDispatchToProps = {
  toggleEditCartSidebar,
  toggleCart,
  getCartDataApi
}


export default connect(mapStateToProps, mapDispatchToProps)(EditCart);
