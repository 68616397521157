// import Home from "./app/home/Home";
import { Route } from 'react-router-dom';
import { Home } from './app/home';
import { About } from './app/about';
import { Sandbox } from './utils/form';
import { ProductDetails } from './app/productDetails';
import { Signup } from './app/signup';
import { ProductList } from './app/productList';
import { Address } from './app/address';
import { OrderList } from './app/orderList/';
import { DigitalStore } from './app/digitalStore';
import { PreviousOrders } from './app/previousOrders';
import { OrderDetails } from './app/orderDetails/';
import { SetPassword } from './app/setPassword';
import { Profile } from './app/profile';
import { VerifyEmail } from './app/verifyEmail';
import { Payment, PaymentStatus } from './app/payment';
import { TermsAndConditions } from './app/termsandconditions';
import { PrivacyPolicy } from './app/privacypolicy';
import { Contact } from './app/contact';
import { Search } from './app/search';
import { Catalogs } from './app/catalogs';
import Markups from './app/markups/Markups';
const routes = [
  {
    path: "/",
    name: "home",
    exact: true,
    type: Route,
    component: Home
  },
  {
    path: "/about",
    name: "about",
    exact: true,
    type: Route,
    component: About
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and conditions",
    exact: true,
    type: Route,
    component: TermsAndConditions
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    exact: true,
    type: Route,
    component: PrivacyPolicy
  },
  {
    path: "/contact",
    name: "Contact",
    exact: true,
    type: Route,
    component: Contact
  },
  {
    path: "/catalogs",
    name: "Catalogs",
    exact: true,
    type: Route,
    component: Catalogs
  },
  {
    path: "/signup",
    name: "signup",
    exact: true,
    type: Route,
    component: Signup
  },
  {
    path: "/search",
    name: "search",
    exact: true,
    type: Route,
    component: Search
  },
  {
    // for newly added, recently purchased and top 25
    path: "/products/sorted-category/:categoryId",
    name: "product-by-category",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    // on clicking category name in navbar
    path: "/products/category/:categoryIdOnly",
    name: "product-by-category-only",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    // new arrival from home page
    path: "/products/new-arrival",
    name: "product-new-arrival",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    // curated and featured products from home page
    path: "/products/collection/:collectionPermalink",
    name: "product-list-collection",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    // stone shape from home page
    path: "/products/stone/:stoneId",
    name: "product-list-stone",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    // stone shape from Navbar
    path: "/products/stone/:navCategoryId/:navStoneId",
    name: "product-list-stone",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    path: "/products/:category/:subcategory",
    name: "product-list-subcategory",
    exact: true,
    type: Route,
    component: ProductList
  },
  {
    path: "/product/:permalink",
    name: "Product Details",
    exact: true,
    type: Route,
    component: ProductDetails
  },
  {
    path: "/profile/address",
    name: "address",
    exact: true,
    type: Route,
    component: Address
  },
  {
    path: "/buy/address",
    name: "address",
    exact: true,
    type: Route,
    component: Address
  },
  {
    path: "/buy/payment",
    name: "payment",
    exact: true,
    type: Route,
    component: Payment
  },
  {
    path: "/buy/payment-status",
    name: "payment status",
    exact: true,
    type: Route,
    component: PaymentStatus
  },
  {
    path: "/profile/orders",
    name: "orders",
    exact: true,
    type: Route,
    component: OrderList
  },
  {
    path: "/profile/digital-store",
    name: "store",
    exact: true,
    type: Route,
    component: DigitalStore
  },
  {
    path: "/profile/previous-orders",
    name: "previous-orders",
    exact: true,
    type: Route,
    component: PreviousOrders
  },
  {
    path: "/profile/order/:orderCode",
    name: "order details",
    exact: true,
    type: Route,
    component: OrderDetails
  },
  {
    path: "/set-password",
    name: "set password",
    exact: true,
    type: Route,
    component: SetPassword
  },
  {
    path: "/profile",
    name: "profile",
    exact: true,
    type: Route,
    component: Profile
  },
  {
    path: "/profile/account-details",
    name: "account details",
    exact: true,
    type: Route,
    component: Signup
  },
  {
    path: "/verify-email",
    name: "verify email",
    exact: true,
    type: Route,
    component: VerifyEmail
  },
  {
    path: "/profile/markups",
    name: "Markups",
    exact: true,
    type: Route,
    component: Markups
  },
  {
    path: "/sandbox",
    name: "Sandbox",
    exact: true,
    type: Route,
    component: Sandbox
  }
];
export default routes