import { PAGE_LIMIT } from "../../utils/Constant";
import { PostLoginInstance, PreLoginInstance } from "../../utils";
import { getSearchProducts } from "./SearchAction";
import { getToken } from "../../utils/ManageToken";
import { toast } from "react-toastify";


export const getSearchProductApi = (data) => {
  data.append('limit', PAGE_LIMIT);
  const instance = getToken() ? PostLoginInstance : PreLoginInstance;
  return function (dispatch) {
    return instance.post("offering/product/search-products", data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getSearchProducts(res.data.data));
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }).catch(err => {
        console.log("error", err);
        toast.error(err.message || "Something went wrong");
      });
  };
}