import React from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ProductItem from './ProductItem';
import leftArrowIcon from '../../../assets/icons/left-slider-arrow.svg';
import rightArrowIcon from '../../../assets/icons/right-slider-arrow.svg';
import { Image } from 'react-bootstrap';

const ProductItemsSlider = props => {

  const sliderSettings = {
    dots: false,
    arrows: true,
    slidesToShow: 4,
    infinite: false,
    nextArrow: <Arrow img={rightArrowIcon} />,
    prevArrow: <Arrow img={leftArrowIcon} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.3,
          arrows: false,
          touchThreshold: 50
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.3,
          arrows: false,
          touchThreshold: 50
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.3,
          arrows: false,
          touchThreshold: 50
        }
      }
    ]
  }

  function Arrow(props) {
    const { className, style, onClick, img } = props;
    return (
      <Image
        src={img}
        className={className}
        style={{ ...style, width: '4.7rem', height: '4.7rem', backgroundColor: 'white' }}
        onClick={onClick}
      />
    );
  }


  return (
    <div className="product-items-slider">
      <Slider {...sliderSettings}>
        {
          props.data.map((item) => (
            <div key={item.product_id} className="slide-wrapper">
              <Link to={`/product/${item.permalink}`}>
                <ProductItem product={item} />
              </Link>
            </div>
          ))
        }
      </Slider>
    </div>
  );
};

export default ProductItemsSlider;