const deleteToken = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('token');
    }
};

const getToken = () => {
    // if (typeof window !== 'undefined') {
    //     return localStorage.getItem('token');
    // }
    // return null;
    return getCookieValue();
};

const getCookieValue = (name = "token") => {
    if (typeof window !== 'undefined') {
        if (document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`))) {
            return document.cookie
                .split('; ')
                .find(row => row.startsWith(`${name}`))
                .split('=')[1];
        }
    }
    return null;
}

const deleteCookie = (name = "token") => {
    // setCookie(name, "", { expires: -1 }); One of the way to delete is by
    // setting it empty and expire previous day
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export { getToken, deleteToken, getCookieValue, deleteCookie };