import { getCategoryData, getCartData } from "./CommonAction";
import { PreLoginInstance, PostLoginInstance } from "../../utils";
import { toast } from 'react-toastify';


export const getCategoryDataApi = () => {
  return function (dispatch) {
    return PreLoginInstance.post('customer/home_page/get-all-categories')
      .then(res => {
        if (!res.data.error) {
          dispatch(getCategoryData(res.data.data));
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }).catch(err => {
        console.log("error", err);
        toast.error(err.message);
      });
  };
}


export const addToFavouritesApi = (data, cb) => {
  PostLoginInstance.post('order/favourite/add-to-favourite', data)
    .then(res => {
      if (!res.data.error) {
        cb("Product added to My Digital Store")
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message);
    });
}


export const removeFromFavouritesApi = (data, cb) => {
  PostLoginInstance.post('order/favourite/delete-from-favourite', data)
    .then(res => {
      if (!res.data.error) {
        cb("Product removed from My Digital Store")
      } else {
        toast.error(res.data.message || "Something went wrong")
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message)
    });
}

export const getCartDataApi = () => {
  return function (dispatch) {
    return PostLoginInstance.post('/order/cart/get-cart')
      .then(res => {
        if (!res.data.error) {
          dispatch(getCartData(res.data.data));
        } else {
          console.log("Error ", res.data.error)
        }
      }).catch(err => {
        console.log("error", err);
      });
  };
}

export const addUpdateProductToCartApi = (data, cb) => {
  PostLoginInstance.post('/order/cart/add-update-product-to-cart', data)
    .then(res => {
      if (!res.data.error) {
        cb();
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message);
    });
}

export const updateCartItemQuantityApi = (data) => {
  return function (dispatch) {
    return PostLoginInstance.post('/order/cart/update-cart-item-quantity', data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getCartDataApi());
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }).catch(err => {
        console.log("error", err);
        toast.error(err.message);
      });
  }
}

export const deleteCartItemApi = (data, cb) => {
  return function (dispatch) {
    return PostLoginInstance.post('/order/cart/delete-cart-item', data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getCartDataApi());
          cb();
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }).catch(err => {
        console.log("error", err);
        toast.error(err.message);
      });
  }
}


export const getCartItemApi = (data, cb) => {
  PostLoginInstance.post('/order/cart/get-cart-item-details', data)
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data);
      } else {
        console.log("Error ", res.data.error)
      }
    }).catch(err => {
      console.log("error", err);
    });
}

