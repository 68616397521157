import {
  GET_PRODUCT_DETAILS,
  SET_CUSTOMIZATION_OPTION,
  GET_SIMILAR_PRODUCTS,
  CLEAR_PRODUCT_DETAILS,
  RESET_VALUES
} from "./ActionTypes";

// Action After Payload Is Returned From Api
export const getProductDetails = payload => ({
  type: GET_PRODUCT_DETAILS,
  payload,
});

export const setCustomizationOption = (payload) => ({
  type: SET_CUSTOMIZATION_OPTION,
  payload,
});

export const getSimilarProducts = (payload) => ({
  type: GET_SIMILAR_PRODUCTS,
  payload,
});

export const clearProductDetails = () => ({
  type: CLEAR_PRODUCT_DETAILS
});

export const resetValues = () => ({
  type: RESET_VALUES
});