import React, { Component } from 'react';
import { Grid, Row, Col, Image } from 'react-bootstrap';
import { CustomNavbar } from '../common';
import About1 from '../../assets/images/about/about-1.jpg';
import About2 from '../../assets/images/about/about-2.jpg';
import About3 from '../../assets/images/about/about-3.jpg';
import fastIllustration from '../../assets/images/about/fast-illustration.svg';
import reliableIllustration from '../../assets/images/about/reliable-illustration.svg';
import customIllustration from '../../assets/images/about/custom-illustration.svg';
import iconLowPrice from '../../assets/images/about/about-low-price.png';
import iconFastDelivery from '../../assets/images/about/about-speedy-delivery.png';
import iconTrends from '../../assets/images/about/about-trends.png';
import iconCustomization from '../../assets/images/about/about-customization.png';
import iconSourcing from '../../assets/images/about/about-sourcing.png';

class About extends Component {
  render() {
    return (
      <section className="about">
        <CustomNavbar />
        <Grid>
          <h2 className="subtitle-desktop">Jewel Fleet is a B2B platform created with a simple premise</h2>
          <h1 className="h1">We want to be your one-stop digital shop for custom fine diamond jewelry.</h1>
          <Row>
            <Col xs={12} sm={6}>
              <Image src={About1} className="about-images" />
            </Col>
            <Col xs={6} sm={3}>
              <Image src={About2} className="about-images" />
            </Col>
            <Col xs={6} sm={3}>
              <Image src={About3} className="about-images" />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <p className="paragraph-desktop" style={{ fontSize: '1.6rem' }}>
                All jewelry businesses regardless of geography, size and scale have the right to acquire exquisite jewelry made with high quality diamonds at exceptional prices. We aim to bring the power of global sourcing at your fingertips by bridging the gap between boutique, independent jewelers and large diamond jewelry manufacturers across the globe. Simply put, this means that we can provide you very significant price differentials of 20-25% on custom jewelry, as compared to our competitors.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={5} smPush={6}>
              <h6 className="subtitle-desktop">Having a customer first mentality</h6>
              <h5 className="h1">We pride ourselves on being</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={3} smPush={2}>
              <Image src={fastIllustration} className="illustration-image" />
            </Col>
            <Col sm={5} smPush={3}>
              <h3 className="product-price">Fast</h3>
              <p className="paragraph-desktop pride-para">
                We deliver customized jewelry products within 6 - 8 business days, so that our turnaround can fuel your turnover.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} smPush={2}>
              <Image src={reliableIllustration} className="illustration-image" />
            </Col>
            <Col sm={5} smPush={3}>
              <h3 className="product-price">Reliable</h3>
              <p className="paragraph-desktop pride-para">
                Our platform provides you solutions that enhance not just your profits, but allow your store to compete with major retailers across the country! With effortless navigation across the site allowing you to build your own ‘Digital Store’, as well as easy &amp; practical 4-step order tracking updates, we make it easy for you to focus on what really matters – your customer!
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} smPush={2}>
              <Image src={customIllustration} className="illustration-image" />
            </Col>
            <Col sm={5} smPush={3}>
              <h3 className="product-price">Custom</h3>
              <p className="paragraph-desktop pride-para">
                We aim to provide up to 95 customization variants for a majority of the designs we offer.  Coupled with weekly new product uploads, transparent information and beautiful videography, we are building Jewel Fleet every week to better suit your business needs.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <p className="paragraph-desktop">
                We are constantly working to ensure that you get beautiful diamond jewelry at the right prices &amp; at the right time, ensuring that you have the muscle needed to compete in our consolidating industry.
                <br /><br />
                We have partnered with one of the largest manufacturers of diamond jewelry globally, currently selling to major retailers &amp; large chain stores in USA. This not only gives us decades of experience in the jewelry industry in the US but also, helps us maintain strict quality control effortlessly. Our partner factories are RJC certified and currently producing diamond jewelry with immense global sourcing standards.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h6 className="subtitle-desktop">Reasons</h6>
              <h5 className="h1">Why should you shop with us?</h5>
            </Col>
            <Col md={4}>
              <Image src={iconLowPrice} className="about-icon" />
              <h3 className="product-price">Low Price</h3>
              <p className="paragraph-desktop">
                Save over 20% of custom jewelry purchases, by buying at the same price as the world's largest jewelry retailer, only through Jewel Fleet.
              </p>
            </Col>
            <Col md={4}>
              <Image src={iconFastDelivery} className="about-icon" />
              <h3 className="product-price">Fast Delivery</h3>
              <p className="paragraph-desktop">
                Speedy delivery within 6 - 8 business days across our entire product range.
              </p>
            </Col>
            <Col md={4}>
              <Image src={iconTrends} className="about-icon" />
              <h3 className="product-price">In-tune with Trends</h3>
              <p className="paragraph-desktop">
                Constant growing list of new styles and categories, with weekly new product uploads.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Image src={iconCustomization} className="about-icon" />
              <h3 className="product-price">Hassle-free Customization</h3>
              <p className="paragraph-desktop">
                With up to 95 exciting customization variants to create your ideal design, including the use of lab-grown diamonds for your eco-conscious customers.
              </p>
            </Col>
            <Col md={4}>
              <Image src={iconSourcing} className="about-icon" />
              <h3 className="product-price">Heritage in Diamond Sourcing</h3>
              <p className="paragraph-desktop">
                Guaranteed ability to continuously procure diamonds at extremely competitive prices, so that we can pass these cost savings on to our customers.
              </p>
            </Col>
          </Row>
        </Grid>
      </section>
    )
  }
}

export default About;