import React from 'react';
import { connect } from "react-redux";
import { BaseReactComponent, FormValidator, CustomTextControl, FormElement, Form, FormSubmitButton } from '../../utils/form';
import { CustomNavbar } from '../common'
import { setPasswordApi } from './Api';
import { toggleLoginSidebar } from '../common/CommonAction';
import { toast } from 'react-toastify';


class SetPassword extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: ""
    }
  }

  componentDidMount() { }

  onValidSubmit = () => {
    if (this.state.newPassword === this.state.confirmPassword) {
      const params = new URLSearchParams(this.props.location.search);
      const token = params.get('token');

      const data = new URLSearchParams();
      data.append('token', token);
      data.append('password', this.state.newPassword);
      setPasswordApi(data, this.setPasswordCallback);

    } else {
      toast.error("passwords don't match!")
    }
  }

  setPasswordCallback = () => {
    toast.success("Password changed succesfully. Please login to continue")
    this.props.toggleLoginSidebar();
    this.props.history.replace('/')
  }

  render() {
    return (
      <>
        <CustomNavbar />
        <section className="set-password-wrapper">
          <div className="set-password-content">
            <div className="login-wrapper">
              <h3 className="h3-desktop">Set New Password</h3>
              <Form onValidSubmit={this.onValidSubmit}>

                <FormElement
                  valueLink={this.linkState(this, "newPassword")}
                  label="Enter new password"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "This field is mandatory"
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      type: "password",
                      placeholder: "Enter new Password"
                    }
                  }}
                />

                <FormElement
                  valueLink={this.linkState(this, "confirmPassword")}
                  label="Confirm Password"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "This field is mandatory"
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      type: "password",
                      placeholder: "Re-enter Password"
                    }
                  }}
                />

                <FormSubmitButton customClass="custom-btn" formError={this.state.inProgress}
                  progressMessage="Validating..">
                  CHANGE PASSWORD
              </FormSubmitButton>

              </Form>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  // loginState: state.LoginState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  toggleLoginSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);