import {
  TOGGLE_LOGIN_SIDEBAR,
  CATEGORY_DATA,
  TOGGLE_CART,
  TOGGLE_CUSTOMIZATION_OPTION,
  TOGGLE_FORGOT_PASSWORD_SIDEBAR,
  EMAIL_SENT_MODAL,
  CART_DATA,
  TOGGLE_EDIT_CART,
  UPDATE_VALUES_FROM_SERVER
} from "./ActionTypes";

export const toggleLoginSidebar = () => ({
  type: TOGGLE_LOGIN_SIDEBAR,
});
export const toggleCart = () => ({
  type: TOGGLE_CART,
});
export const toggleCustomizationOption = () => ({
  type: TOGGLE_CUSTOMIZATION_OPTION,
});

export const toggleForgotPasswordSidebar = () => ({
  type: TOGGLE_FORGOT_PASSWORD_SIDEBAR,
});

export const toggleEmailSentModal = () => ({
  type: EMAIL_SENT_MODAL,
});

// Action After Payload Is Returned From Api
export const getCategoryData = (payload) => ({
  type: CATEGORY_DATA,
  payload
})

export const getCartData = (payload) => ({
  type: CART_DATA,
  payload
})

export const toggleEditCartSidebar = (payload) => ({
  type: TOGGLE_EDIT_CART,
  payload
});

export const updateValuesFromServer = (isMobile, token, isMobileAndTablet) => ({
  type: UPDATE_VALUES_FROM_SERVER,
  isMobile,
  token,
  isMobileAndTablet
});
