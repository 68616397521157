import React from 'react';
import { Grid, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import logo1 from '../../../assets/images/footer/footer-logo-1.png';
import logo2 from '../../../assets/images/footer/footer-logo-2.jpg';
import logo3 from '../../../assets/images/footer/footer-logo-3.png';
import logo4 from '../../../assets/images/footer/footer-logo-4.png';

const Footer = () => {
  return (
    <>
      <div style={{ flex: 1 }}></div>
      <div className="footer">
        <Grid>
          <div className="footer-wrapper">
            <div className="left">
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms &amp; Conditions </Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
              <div className="logos-wrapper">
                <Image src={logo1} />
                <Image src={logo2} />
                <Image src={logo3} />
                <Image src={logo4} />
              </div>
            </div>
            <div className="right">
              <h6>Copyrights 2020 Jewel Fleet</h6>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(Footer);