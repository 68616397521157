import React from 'react';
import { Image } from 'react-bootstrap';
import backArrow from '../../../assets/icons/back-arrow.svg';

const SideDrawer = ({ isOpen, toggleHandle, children, title }) => {
  return (
    <div
      className={isOpen ? "side-drawer open" : "side-drawer"}
      onClick={toggleHandle}
    >
      <div className="content-wrapper" onClick={(e) => e.stopPropagation()}>
        <Image
          src={backArrow}
          onClick={toggleHandle}
          className="back-icon" />
        {
          title &&
          <h4 className="title">{title}</h4>
        }
        {children}
      </div>
    </div>
  );
};

export default SideDrawer;