import React from 'react';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CustomButton = props => {
  let generatedClassName = props.className ? "custom-btn " + props.className : "custom-btn"

  if (props.inverse) {
    generatedClassName += " custom-inverse"
  } else if (props.primary) {
    generatedClassName += " custom-primary"
  }
  return (
    props.href ?
      <Link className={generatedClassName} to={props.href}>{props.children}</Link>
      :
      <Button onClick={props.onClick} className={generatedClassName}>
        {props.children}
      </Button>
  );
};

export default CustomButton;