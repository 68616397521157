import { getPageCount } from "../../utils/ReusableFunctions";
import { GET_ORDERS } from "./ActionTypes";

const INITIAL_STATE = {
  orders: null,
  totalPages: null
};
const OrderListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
        totalPages: getPageCount(action.payload.total_order_count)
      };
    default:
      return state
  }
};
export default OrderListReducer