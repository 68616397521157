import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import { CustomNavbar } from '../common';

class PrivacyPolicy extends Component {
  render() {
    return (
      <section className="privacy-policy">
        <CustomNavbar />
        <Grid>
          <h1>Privacy Policy</h1>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Saepe sequi fugiat, facere sed similique possimus maiores atque optio, labore laboriosam perferendis modi doloribus expedita obcaecati totam praesentium cumque deserunt recusandae!</p>
        </Grid>
      </section>
    )
  }
}

export default PrivacyPolicy;