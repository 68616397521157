import React, { Component } from 'react';
import { Grid, Image } from 'react-bootstrap';
import { CustomNavbar, PageHeader } from '../common';
import banner from "../../assets/images/shop-wedding-ring-banner.png"
import { catalogsApi } from './Api';

class Catalogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogs: null,
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Catalogs',
          active: true
        },
      ],
    }
  }

  componentDidMount() {
    catalogsApi((catalogs) => this.setState({ catalogs }));
  }

  render() {
    return (
      <section className="catalogs-wrapper">
        <CustomNavbar />
        <Grid>
          <PageHeader title="Catalogs" breadcrumbData={this.state.breadcrumbData} />
          <div className="catalogs">
            {
              this.state.catalogs &&
              this.state.catalogs.map(item => (
                <a className="catalog-item" key={item.code} href={item.catalog_attachment_url} target="_blank">
                  <Image src={item.cover_attachment_url} />
                  <h5 className="title-desktop">{item.name}</h5>
                </a>
              ))
            }
          </div>
        </Grid>
      </section>
    )
  }
}

export default Catalogs;