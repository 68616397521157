import { PostLoginInstance } from "../../utils";
import { toast } from "react-toastify";

export const getOrderDetailsApi = (data, cb) => {
  PostLoginInstance.post('order/get-order-details', data)
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    }).catch(err => {
      console.log("error", err);
      toast.error(err.message || "Something went wrong");
    });
}
