import { PreLoginInstance } from "../../utils";
import { toast } from "react-toastify";

export const catalogsApi = (cb) => {
  PreLoginInstance.post('/offering/catalog/get-all-catalogs')
    .then(res => {
      if (!res.data.error) {
        cb(res.data.data.catalogs);
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err);
      toast.error(err.message);
    });
}