import React from 'react';
import { connect } from "react-redux";
import { CustomNavbar, PageHeader } from '../common';
import { Grid, Row, Col } from "react-bootstrap";
import { BaseReactComponent, FormValidator, CustomTextControl, FormElement, Form, FormSubmitButton, CheckboxControl, SelectControl, RadioGroupControl } from '../../utils/form';
import { companyEntityOptions } from '../../utils/Constant';
import { signupApi, getAccountDetailsApi, updateAccountDetailsApi } from './Api';
import { toggleEmailSentModal } from '../common/CommonAction';
import { getToken } from '../../utils/ManageToken';
import { toast } from 'react-toastify';



class Signup extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'Account Details',
          active: true
        },
      ],
      showAccountDetails: false,
      accountId: null,
      hiddenBusinessType: "",
      name: "",
      email: "",
      fedralTaxID: "",
      resaleTaxNumber: "",
      JBTNumber: "",
      password: "",
      confirmPassword: "",

      legalName: "",
      directorName: "",
      entityType: "",
      entityOptions: companyEntityOptions,
      stateOfRegistration: "",
      phoneNumber: "",
      retailer: "",
      wholesaler: "",
      manufacture: "",

      tradeName: "",
      bankName: "",
      alternatePhoneNumber: "",

      alternateEmail1: "",
      alternateEmail2: "",
      antiMoneyLaundering: "false",

      shippingShopName: "",
      shippingStreet: "",
      shippingCityName: "",
      shippingStateName: "",
      shippingZipcode: "",
      billingShopName: "",
      billingStreet: "",
      billingCityName: "",
      billingStateName: "",
      billingZipcode: "",
      isBillingAddress: false
    }
  }

  componentDidMount() {
    if (getToken()) {
      getAccountDetailsApi(this.accountDetailsCallback);
    }
  }

  accountDetailsCallback = (data) => {
    console.log("account details ", data);
    const { account, billing_address, user } = data;

    // business type
    let retailer = "", wholesaler = "", manufacture = ""
    account.business_types.map(type => {
      if (type.business_type === 1) retailer = true
      else if (type.business_type === 2) wholesaler = true
      else if (type.business_type === 3) manufacture = true
      return null;
    })

    this.setState({
      showAccountDetails: true,
      accountId: account.id,
      name: user.user_name,
      email: user.user_email,
      fedralTaxID: account.federal_tax_id,
      resaleTaxNumber: account.resale_tax_id,
      JBTNumber: account.jbt_number,

      legalName: account.name,
      directorName: account.billing_person,
      entityType: account.company_entity_type,
      stateOfRegistration: account.state_of_registration,
      phoneNumber: account.phone_number,
      retailer,
      wholesaler,
      manufacture,

      tradeName: account.dba_trade_name,
      bankName: account.bank_name,
      alternatePhoneNumber: account.alternate_phone_number,

      alternateEmail1: account.alternate_email_id_1,
      alternateEmail2: account.alternate_email_id_2,
      antiMoneyLaundering: account.instituted_laundering_program.toString(),

      billingShopName: billing_address.store_name,
      billingStreet: billing_address.street,
      billingCityName: billing_address.city,
      billingStateName: billing_address.state,
      billingZipcode: billing_address.pincode,
      billingAddressId: billing_address.id,
    })
  }


  renderBillingAddress = () => {
    return (
      <>
        <FormElement
          valueLink={this.linkState(this, "billingShopName")}
          label="Shop Name"
          required
          validations={[
            {
              validate: FormValidator.isRequired,
              message: "This field is mandatory"
            }
          ]}
          control={{
            type: CustomTextControl,
            settings: {
              placeholder: "Enter Shop Name",
            }
          }}
        />
        <FormElement
          valueLink={this.linkState(this, "billingStreet")}
          label="Street / Post box no."
          required
          validations={[
            {
              validate: FormValidator.isRequired,
              message: "This field is mandatory"
            }
          ]}
          control={{
            type: CustomTextControl,
            settings: {
              placeholder: "Enter Street / Post box no.",
            }
          }}
        />
        <Row>
          <Col sm={6}>
            <FormElement
              valueLink={this.linkState(this, "billingCityName")}
              label="City"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter City Name",
                }
              }}
            />
          </Col>
          <Col sm={6}>
            <FormElement
              valueLink={this.linkState(this, "billingStateName")}
              label="State"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter State Name",
                }
              }}
            />
          </Col>
          <Col sm={6}>
            <FormElement
              valueLink={this.linkState(this, "billingZipcode")}
              label="Zip Code"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                },
                {
                  validate: FormValidator.isPositiveInt,
                  message: "Zip Code should be a number"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter Zip Code",
                }
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  renderShippingAddress = () => {
    return (
      <>
        <FormElement
          valueLink={this.linkState(this, "shippingShopName")}
          label="Shop Name"
          required
          validations={[
            {
              validate: FormValidator.isRequired,
              message: "This field is mandatory"
            }
          ]}
          control={{
            type: CustomTextControl,
            settings: {
              placeholder: "Enter Shop Name",
            }
          }}
        />
        <FormElement
          valueLink={this.linkState(this, "shippingStreet")}
          label="Street / Post box no."
          required
          validations={[
            {
              validate: FormValidator.isRequired,
              message: "This field is mandatory"
            }
          ]}
          control={{
            type: CustomTextControl,
            settings: {
              placeholder: "Enter Street / Post box no.",
            }
          }}
        />
        <Row>
          <Col sm={6}>
            <FormElement
              valueLink={this.linkState(this, "shippingCityName")}
              label="City"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter City Name",
                }
              }}
            />
          </Col>
          <Col sm={6}>
            <FormElement
              valueLink={this.linkState(this, "shippingStateName")}
              label="State"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter State Name",
                }
              }}
            />
          </Col>
          <Col sm={6}>
            <FormElement
              valueLink={this.linkState(this, "shippingZipcode")}
              label="Zip Code"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "This field is mandatory"
                },
                {
                  validate: FormValidator.isPositiveInt,
                  message: "Zip Code should be a number"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter Zip Code",
                }
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  onValidSubmit = () => {
    if (!this.state.showAccountDetails && this.state.password !== this.state.confirmPassword) {
      return toast.error("Passwords don't match")
    }

    const data = new URLSearchParams();
    data.append('user_name', this.state.name);
    data.append('user_email', this.state.email);
    data.append('account_registered_legal_name', this.state.legalName);
    data.append('account_phone_number', this.state.phoneNumber);
    data.append('account_alternate_phone_number', this.state.alternatePhoneNumber);
    data.append('account_alternate_email_1', this.state.alternateEmail1);
    data.append('account_alternate_email_2', this.state.alternateEmail2);
    data.append('account_owner_name', this.state.directorName);
    data.append('company_entity_type', this.state.entityType);

    const businessTypeSelected = [];
    if (this.state.retailer) businessTypeSelected.push(1)
    if (this.state.wholesaler) businessTypeSelected.push(2)
    if (this.state.manufacture) businessTypeSelected.push(3)
    data.append('business_types', JSON.stringify(businessTypeSelected));

    data.append('account_jbt_number', this.state.JBTNumber);
    data.append('federal_tax_id', this.state.fedralTaxID);
    data.append('resale_tax_id', this.state.resaleTaxNumber);
    data.append('dba_trade_name', this.state.tradeName);
    data.append('state_of_registration', this.state.stateOfRegistration);
    data.append('bank_account_name', this.state.bankName);
    data.append('instituted_laundering_program', this.state.antiMoneyLaundering);


    if (this.state.isBillingAddress) {
      data.append('shipping_address_shop_name', this.state.billingShopName);
      data.append('shipping_address_street', this.state.billingStreet);
      data.append('shipping_address_city', this.state.billingCityName);
      data.append('shipping_address_state', this.state.billingStateName);
      data.append('shipping_address_pincode', this.state.billingZipcode);
    } else {
      data.append('shipping_address_shop_name', this.state.shippingShopName);
      data.append('shipping_address_street', this.state.shippingStreet);
      data.append('shipping_address_city', this.state.shippingCityName);
      data.append('shipping_address_state', this.state.shippingStateName);
      data.append('shipping_address_pincode', this.state.shippingZipcode);
    }


    data.append('billing_address_shop_name', this.state.billingShopName);
    data.append('billing_address_street', this.state.billingStreet);
    data.append('billing_address_city', this.state.billingCityName);
    data.append('billing_address_state', this.state.billingStateName);
    data.append('billing_address_pincode', this.state.billingZipcode);

    if (this.state.showAccountDetails) {
      data.append("account_id", this.state.accountId);
      data.append("billing_address_id", this.state.billingAddressId);
      data.append("shipping_address_id", this.state.shippingAddressId);
      updateAccountDetailsApi(data, this.updateDetailsCallback);
    } else {
      data.append('user_password', this.state.password);
      signupApi(data, this.props.toggleEmailSentModal);
    }

  }

  updateDetailsCallback = () => {
    toast.success("details updated successfully");
  }


  render() {
    return (
      <>
        <CustomNavbar />

        {/*
        <InformationModal
          toggleHandle={this.props.toggleEmailSentModal}
          show={this.props.commonState.emailSentModal} >
          <Image src={mailSentImage} />
          <h5 className="h2-desktop">We have sent you an email!</h5>
          <h6 className="paragraph-desktop">Please check your mail. We have sent you a confirmation link.</h6>
        </InformationModal>
         */}
        <div className="signup">

          <Grid>
            <Row>
              <Col sm={12}>
                {
                  this.state.showAccountDetails ?
                    <PageHeader title="Account Details" breadcrumbData={this.state.breadcrumbData} />
                    :
                    <PageHeader title="Create New Account" />
                }
                <Form onValidSubmit={this.onValidSubmit}>
                  <h3 className="product-card-title">ACCOUNT HOLDER DETAILS</h3>
                  <Row>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "name")}
                        label="Name"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Name",
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "email")}
                        label="Email ID"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          },
                          {
                            validate: FormValidator.isEmail,
                            message: "Invalid Email"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter your email",
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "fedralTaxID")}
                        label="Federal Tax ID"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Federal Tax ID",
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "resaleTaxNumber")}
                        label="Resale Tax Number"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          },
                          {
                            validate: FormValidator.isPositiveInt,
                            message: "Resale Tax Number should be a number"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Resale Tax Number"
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "JBTNumber")}
                        label="JBT Number"
                        validations={[
                          {
                            validate: FormValidator.isPositiveIntOptional,
                            message: "JBT Number should be a number"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter JBT Number"
                          }
                        }}
                      />
                    </Col>
                    {
                      !this.state.showAccountDetails &&
                      <>
                        <Col sm={4}>
                          <FormElement
                            valueLink={this.linkState(this, "password")}
                            label="Password"
                            required
                            validations={[
                              {
                                validate: FormValidator.isRequired,
                                message: "This field is mandatory"
                              },
                            ]}
                            control={{
                              type: CustomTextControl,
                              settings: {
                                type: "password",
                                placeholder: "Enter Password"
                              }
                            }}
                          />
                        </Col>
                        <Col sm={4}>
                          <FormElement
                            valueLink={this.linkState(this, "confirmPassword")}
                            label="Confirm Password"
                            required
                            validations={[
                              {
                                validate: FormValidator.isRequired,
                                message: "This field is mandatory"
                              },
                            ]}
                            control={{
                              type: CustomTextControl,
                              settings: {
                                type: "password",
                                placeholder: "Enter Confirm Password"
                              }
                            }}
                          />
                        </Col>
                      </>
                    }

                  </Row>

                  <h3 className="product-card-title">COMPANY DETAILS</h3>
                  <Row>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "legalName")}
                        label="Registered Legal Name"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Register Legal Name",
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "directorName")}
                        label="Owner/Director Name"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Owner/Director Name",
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "entityType")}
                        label="Type of Entity"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: SelectControl,
                          settings: {
                            options: this.state.entityOptions,
                            multiple: false,
                            searchable: false,
                            placeholder: "Select the Entity Type",
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.entityType);
                              console.log('Hello world!');
                            }
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "stateOfRegistration")}
                        label="State of Registration/Incorporation"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter State of Registration/Incorporation",
                          }
                        }}
                      />
                    </Col>

                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "phoneNumber")}
                        label="Phone Number"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "This field is mandatory"
                          },
                          {
                            validate: FormValidator.isPositiveInt,
                            message: "This field should be number"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Phone Number"
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <label className="control-label">Type of Business <span style={{ color: "#d0021b" }}>*</span></label>
                      <div className="checkbox-wrapper">
                        <FormElement
                          valueLink={this.linkState(this, "retailer")}
                          label=""
                          control={{
                            type: CheckboxControl,
                            settings: {
                              onLabel: "Retailer",
                              onChangeCallback: () => {
                                // console.log('Callback executed');
                              }
                            }
                          }}
                        />
                        <FormElement
                          valueLink={this.linkState(this, "wholesaler")}
                          label=""
                          control={{
                            type: CheckboxControl,
                            settings: {
                              onLabel: "Wholesaler",
                              onChangeCallback: () => {
                                // console.log('Callback executed');
                              }
                            }
                          }}
                        />
                        <FormElement
                          valueLink={this.linkState(this, "manufacture")}
                          label=""
                          control={{
                            type: CheckboxControl,
                            settings: {
                              onLabel: "Manufacture",
                              onChangeCallback: () => {
                                // console.log('Callback executed');
                              }
                            }
                          }}
                        />
                      </div>
                      {/* hidden element for validating business types */}
                      <FormElement
                        valueLink={this.linkState(this, "hiddenBusinessType")}
                        validations={[
                          {
                            validate: FormValidator.atLeastOneTrue([this.state.retailer, this.state.manufacture, this.state.wholesaler]),
                            message: "This field is mandatory"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "hidden"
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "tradeName")}
                        label="DBA/Trade Name"
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter DBA/Trade Name",
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "bankName")}
                        label="Name of Bank"
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Name of Bank",
                          }
                        }}
                      />
                    </Col>

                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, "alternatePhoneNumber")}
                        label="Alternate Phone Number"
                        validations={[
                          {
                            validate: FormValidator.isPositiveIntOptional,
                            message: "Phone Number should be number"
                          }
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Alternate Phone Number"
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={8}>
                      <label className="control-label">Alternative Emails Ids will only be sent order confirmations &amp; delivery updates &amp; marketing information. Only the primary email ID can be used to sign in to your account</label>
                      <Row>
                        <Col sm={6}>
                          <FormElement
                            valueLink={this.linkState(this, "alternateEmail1")}
                            label="Alternate Email ID 1"
                            validations={[
                              {
                                validate: FormValidator.isEmailOptional,
                                message: "Invalid Email"
                              }
                            ]}
                            control={{
                              type: CustomTextControl,
                              settings: {
                                placeholder: "Enter Alternate Email ID",
                              }
                            }}
                          />
                        </Col>
                        <Col sm={6}>
                          <FormElement
                            valueLink={this.linkState(this, "alternateEmail2")}
                            label="Alternate Email ID 2"
                            validations={[
                              {
                                validate: FormValidator.isEmailOptional,
                                message: "Invalid Email"
                              }
                            ]}
                            control={{
                              type: CustomTextControl,
                              settings: {
                                placeholder: "Enter Alternate Email ID",
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <label className="control-label" style={{ marginBottom: "3rem" }}>Have You Instituted An Anti-Money Laundering Program?</label>
                      <FormElement
                        valueLink={this.linkState(this, "antiMoneyLaundering")}
                        control={{
                          type: RadioGroupControl,
                          settings: {
                            columns: 1,
                            options: [
                              {
                                key: true,
                                label: "YES"
                              },
                              {
                                key: false,
                                label: "NO"
                              }
                            ]
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <p className="info">- I confirm that the above information is true and correct.</p>
                  <p className="info">- I confirm that I have provided/will provide all the required documentation.</p>
                  <p className="info">- I will provide any changes made to the above stated information.</p>
                  <p className="info">- I confirm that I have disclosed all relevant information.</p>

                  <Row>
                    <Col sm={6}>
                      <h3 className="product-card-title">BILLING DETAILS</h3>
                      {this.renderBillingAddress()}
                    </Col>
                    {
                      !this.state.showAccountDetails &&
                      <Col sm={6}>
                        <h3 className="product-card-title">SHIPPING ADDRESS</h3>
                        <h6 className="product-desc">Multipe shipping address can be added after account is created from profile section.</h6>
                        <FormElement
                          valueLink={this.linkState(this, "isBillingAddress")}
                          label=""
                          control={{
                            type: CheckboxControl,
                            settings: {
                              onLabel: "Same as billing address",
                              onChangeCallback: () => {
                                console.log('Callback executed');
                              }
                            }
                          }}
                        />
                        {!this.state.isBillingAddress && this.renderShippingAddress()}
                      </Col>
                    }

                  </Row>

                  <div className="btn-wrapper sticky-mobile-bottom-buttons">
                    <FormSubmitButton customClass="custom-btn" formError={this.state.inProgress}
                      progressMessage="Validating..">
                      {
                        this.state.showAccountDetails ?
                          "Update details" : "Create my account"
                      }
                    </FormSubmitButton>
                  </div>

                </Form>
              </Col>
            </Row>
          </Grid>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  signupState: state.SignupState,
  commonState: state.CommonState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  toggleEmailSentModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
