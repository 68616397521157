import {
  GET_PRODUCT_DETAILS,
  SET_CUSTOMIZATION_OPTION,
  GET_SIMILAR_PRODUCTS,
  CLEAR_PRODUCT_DETAILS,
  RESET_VALUES,
} from "./ActionTypes";
import { DefaultRingSize } from "../../utils/Constant";

const INITIAL_STATE = {
  productDetails: null,
  centerStone: null,
  meleeStone: null,
  ringSize: { label: DefaultRingSize, value: DefaultRingSize },
  laserEngraving: false,
  laserEngravingName: "",
  certificationCharge: false,
  similarProducts: null,
  totalCost: 0,
  customizationButtonText: "Continue",
  personalNote: "",
  quantity: 1,
  resetValues: {}
};

const ProductDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload
      }
    case SET_CUSTOMIZATION_OPTION:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    case GET_SIMILAR_PRODUCTS:
      return {
        ...state,
        similarProducts: action.payload
      }
    case CLEAR_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: null
      }
    case RESET_VALUES:
      return {
        ...state,
        centerStone: state.resetValues.centerStone,
        meleeStone: state.resetValues.meleeStone,
        ringSize: null,
        laserEngraving: false,
        laserEngravingName: "",
        certificationCharge: false,
        quantity: 1,
        personalNote: "",
        ringSize: { label: DefaultRingSize, value: DefaultRingSize },
      }
    default:
      return state
  }
};
export default ProductDetailsReducer