import { PreLoginInstance } from "../../utils";
import { toast } from 'react-toastify';


export const forgotPasswordApi = (data, cb) => {
  PreLoginInstance.post('/customer/account/forgot-user-password', data)
    .then(res => {
      if (!res.data.error) {
        cb();
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      toast.error(err.message);
      console.log('error', err)
    });
}

