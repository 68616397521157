import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ data }) => {
  return (
    <div className="custom-breadcrumb">
      {
        data.map((item, index) => (
          item.url ?
            <Link
              key={index}
              to={item.url}
              className={item.active ? 'dark-desktop' : 'light-desktop'}>
              {item.text}
            </Link>
            :
            <span key={index} className={item.active ? 'dark-desktop' : 'light-desktop'}>
              {item.text}
            </span>
        ))
      }
    </div>
  );
};

export default Breadcrumb;