import React from 'react';
import { Grid } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SecondaryNavbar = props => {
  return (
    <div className="secondary-navbar">
      <Grid>
        <div className="content-wrapper">
          <h4 className="email-text">Email us at order@jewelfleet.com</h4>
          <Link to="/catalogs"><h6>Catalogs</h6></Link>
          <Link to="/about"><h6>About Us</h6></Link>
          {
            props.data.slice(0, 4).map(material => (
              <h6 key={material.id}>
                {material.name === "Gold" ? "Weekly Gold Fix" : material.name}: {' '}
                <span>{material.currency}{material.current_lock_price}</span> per ounce
              </h6>
            ))
          }
        </div>
      </Grid>
    </div>
  );
};

export default SecondaryNavbar;