import React from 'react';
import { orderStatus } from '../../../utils/Constant';

const FulfillmentDetails = ({ fulfillmentDetails, trackingId }) => {

  const availableStatuses = [
    {
      "order_tracking_order_status": "Order Placed"
    },
    {
      "order_tracking_order_status": "confirmed"
    },
    {
      "order_tracking_order_status": "dispatched"
    },
    {
      "order_tracking_order_status": "out for delivery"
    },
    {
      "order_tracking_order_status": "delivered"
    }
  ]


  return (
    <div className="fulfillment-details">
      <h3>Fulfillment Details</h3>
      <div className="timeline-wrapper">
        {
          fulfillmentDetails.order_tracking_list.map((item, index) => (
            <div
              className="timeline-item green"
              key={index}>
              <h4>{item.order_tracking_order_status}</h4>
              <h5>{item.order_tracking_created_on}</h5>
              {
                index + 1 === fulfillmentDetails.order_tracking_list.length &&
                <span className="paragraph-desktop">Tracking no. {trackingId}</span>
              }
            </div>
          ))
        }
        {
          availableStatuses.map((item, index) => {
            if (index >= fulfillmentDetails.order_tracking_list.length)
              return <div
                className="timeline-item"
                key={index}>
                <h4>{item.order_tracking_order_status}</h4>
              </div>
          })
        }
      </div>
    </div>
  );
};

export default FulfillmentDetails;