import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ProductItemsSlider, FullImageSlider } from '../common';
import {
  CustomNavbar, CustomButton,
  DiamondShapesList, BannerSlider,
} from '../common';
import ShopBanner from './_utils/ShopBanner';
import { Grid } from 'react-bootstrap';
import { getHomePageDataApi } from "./Api";
import {
  toggleLoginSidebar,
  toggleCart,
  toggleForgotPasswordSidebar,
  toggleEmailSentModal
} from '../common/CommonAction';
import { getCategoryDataApi } from '../common/Api';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  static loadData(match, dispatch) {
    return Promise.all([dispatch(getHomePageDataApi()), dispatch(getCategoryDataApi())]);
  }

  componentDidMount() {
    if (!this.props.homeState.homeData) {
      this.props.getHomePageDataApi();
    }
  }

  render() {
    const { homeData } = this.props.homeState;
    return (
      <Fragment>
        <CustomNavbar />
        <section>
          {
            homeData && <BannerSlider banner={homeData.slider_images} />
          }
        </section>

        <section className="shop-banners">
          <Grid>
            <div className="assign-grid">
              {
                homeData && homeData.feature_collections.map(collection => (
                  <ShopBanner
                    key={collection.id}
                    title={collection.title}
                    description={collection.description}
                    image={collection.attachment_url}
                    href={`/products/collection/${collection.permalink}`}
                    buttonText="SHOP NOW"
                  />
                ))
              }
            </div>
          </Grid>
        </section>

        <section className="new-arrivals">
          <Grid>
            <h3>discover</h3>
            <h2>New Arrivals</h2>
            {
              homeData &&
              <ProductItemsSlider data={homeData.new_arrivals} />
            }
            <CustomButton
              inverse
              className="all-products-button" href="/products/new-arrival">see all products</CustomButton>
          </Grid>
        </section>

        <section className="shop-banners">
          <Grid>
            <h4>shop</h4>
            <h3>Curated Assortment</h3>
            <div className="assign-grid">
              {
                homeData && homeData.curated_collections.map(collection => (
                  <ShopBanner
                    key={collection.id}
                    title={collection.title}
                    description={collection.description}
                    image={collection.attachment_url}
                    href={`/products/collection/${collection.permalink}`}
                    buttonText="Shop all" />
                ))
              }
            </div>
          </Grid>
        </section>

        <section className="diamond-shapes">
          <div className="container">
            <h3>Shop by Diamond Shape</h3>
            <h4>Select the Center Diamond Shape of Your Choice</h4>
            <DiamondShapesList />
          </div>
        </section>
        <section>
          {
            homeData &&
            <FullImageSlider data={homeData.sales_banners} />
          }
        </section>
      </Fragment>
    )
  }
};

const mapStateToProps = state => ({
  homeState: state.HomeState,
  commonState: state.CommonState
});

const mapDispatchToProps = {
  getHomePageDataApi,
  toggleLoginSidebar,
  toggleCart,
  toggleForgotPasswordSidebar,
  toggleEmailSentModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);