import { GET_PRODUCT_LIST_BY_CATEGORY, CLEAR_PRODUCT_LIST, IS_PRODUCT_LIST_SERVER_API_CALLED } from "./ActionTypes";

// Action Creator After Payload Is Returned From Api
export const getProductListByCategory = (payload, key) => ({
  type: GET_PRODUCT_LIST_BY_CATEGORY,
  payload,
  key
});

export const clearProductList = () => ({
  type: CLEAR_PRODUCT_LIST,
});

export const isProductListServerApiCalled = (payload) => ({
  type: IS_PRODUCT_LIST_SERVER_API_CALLED,
  payload
});