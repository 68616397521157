import { getProductDetails, getSimilarProducts } from "./ProductDetailsAction";
import { PreLoginInstance, PostLoginInstance } from "../../utils";
import { getToken } from "../../utils/ManageToken";

export const getProductDetailsApi = (data) => {
  const instance = getToken() ? PostLoginInstance : PreLoginInstance;
  return function (dispatch) {
    return instance.post('offering/product/get-product-details-by-permalink', data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getProductDetails(res.data.data));
          return res.data.data;
        } else {
          console.log("Error here", res.data.error)
        }
      }).catch(err => {
        console.log("error", err);
      });
  };
}


export const getSimilarProductsApi = (data) => {
  return function (dispatch) {
    const instance = getToken() ? PostLoginInstance : PreLoginInstance;
    return instance.post('/offering/product/get-all-similar-products', data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getSimilarProducts(res.data.data));
        } else {
          console.log("Error ", res.data.error)
        }
      }).catch(err => {
        console.log("error", err);
      });
  };
}

