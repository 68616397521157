import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import { CustomNavbar, PageHeader } from '../common'
import OrderSummary from './_utils/OrderSummary';
import CustomerInfo from './_utils/CustomerInfo';
import FulfillmentDetails from './_utils/FulfillmentDetails';
import ProductsBought from './_utils/ProductsBought';
import { getOrderDetailsApi } from './Api';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'Orders',
          url: '/profile/orders'
        },
        {
          text: 'Order Details',
          active: true
        },
      ],
      trackingId: '1234567890',
      orderDetails: null,
    }
  }

  componentDidMount() {
    const code = this.props.match.params.orderCode;
    const data = new URLSearchParams();
    data.append("order_code", code);
    getOrderDetailsApi(data, (res) => this.setState({ orderDetails: res }));
  }

  render() {
    const { orderDetails } = this.state;
    return (
      <>
        <CustomNavbar />

        {
          orderDetails &&
          <section className="order-details">
            <Grid>
              <PageHeader title="Order Details" breadcrumbData={this.state.breadcrumbData} />
              <div className="order-details-content">
                <OrderSummary summary={orderDetails} />
                <CustomerInfo customerInfo={orderDetails} />
                <hr />
                <FulfillmentDetails
                  fulfillmentDetails={orderDetails}
                  trackingId={this.state.trackingId}
                />
                <hr />
                <ProductsBought products={orderDetails.products} />
              </div>
            </Grid>
          </section>
        }

      </>
    )
  }
}

export default OrderDetails;