import React from 'react';
import CustomButton from './CustomButton';
import { getToken } from '../../../utils/ManageToken';
import { connect } from 'react-redux';
import { toggleLoginSidebar } from '../CommonAction';


const SigninRibbon = props => {
  return (
    props.commonState.token || getToken() ?
      null
      :
      <div className="signin-ribbon">
        <span>Sign In / Sign Up to view the prices</span>
        <CustomButton onClick={props.toggleLoginSidebar}>sign in</CustomButton>
        <CustomButton inverse href="/signup">sign up</CustomButton>
      </div>
  );
};

const mapStateToProps = state => ({
  commonState: state.CommonState
});

const mapDispatchToProps = {
  toggleLoginSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninRibbon);