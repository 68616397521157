import { CLEAR_SEARCH_PRODUCTS, GET_SEARCH_PRODUCTS } from "./ActionTypes";

// Action Creator After Payload Is Returned From Api
export const getSearchProducts = payload => ({
  type: GET_SEARCH_PRODUCTS,
  payload,
});

export const clearSearchProducts = () => ({
  type: CLEAR_SEARCH_PRODUCTS,
});