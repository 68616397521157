import React, { useState, useRef } from 'react';
import { Image } from 'react-bootstrap'
import VideoModal from './VideoModal';
import { Favourite } from '../../common';
import { connect } from 'react-redux';


const ProductImages = ({ images, videos, sku, isFavourite, ...props }) => {

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showZoom, setShowZoom] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const zoomedImageRef = useRef(null);

  const zoomHandle = e => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / e.target.offsetWidth) * 100;
    const y = ((e.clientY - rect.top) / e.target.offsetHeight) * 100;
    zoomedImageRef.current.style.transformOrigin = `${x}% ${y}%`;
  };

  const toggleVideoModal = () => {
    setShowVideo(!showVideo);
  }

  return (
    <>

      <VideoModal
        video={videos[selectedImageIndex - images.length]}
        show={showVideo}
        handleClose={toggleVideoModal} />

      <div className="product-images">

        {/* small thumbnails */}
        <div className="product-thumbnails">
          {
            images.map((image, index) => (
              <div
                key={index}
                className={selectedImageIndex === index ? "active" : ""}
                onTouchEnd={props.commonState.isMobileAndTablet ? () => setSelectedImageIndex(index) : null}
                onMouseEnter={props.commonState.isMobileAndTablet ? null : () => setSelectedImageIndex(index)}>
                <Image src={image} />
              </div>
            ))
          }

          {
            videos.map((video, index) => (
              <div
                key={index}
                className={selectedImageIndex === index + images.length ? "active video-thumbnail" : "video-thumbnail"}
                onTouchEnd={props.commonState.isMobileAndTablet ? () => setSelectedImageIndex(images.length + index) : null}
                onMouseEnter={props.commonState.isMobileAndTablet ? null : () => setSelectedImageIndex(images.length + index)}
              >
              </div>
            ))
          }



        </div>

        {/* main big image */}
        <div className="product-selected">
          <div className="image-wrapper">
            <Favourite productSku={sku} isFavourite={isFavourite} bordered />

            {
              selectedImageIndex >= images.length ?

                <div className="video-wrapper" onClick={toggleVideoModal} >
                  <video src={videos[selectedImageIndex - images.length]} />
                </div>

                :
                <>
                  <Image src={images[selectedImageIndex]}
                    onMouseEnter={() => setShowZoom(true)}
                    onMouseLeave={() => setShowZoom(false)}
                    onMouseMove={typeof window !== 'undefined' ?
                      window.matchMedia(`(min-device-width: 1200px)`).matches ? zoomHandle : null : null}
                  />

                  {/* zoomed image only visible when the main image is hovered */}
                  <div className={showZoom ? "zoomed-image-wrapper show" : "zoomed-image-wrapper"}>
                    <img alt="zoomed" ref={zoomedImageRef} src={images[selectedImageIndex]} />
                  </div>
                </>

            }

          </div>
        </div>


      </div>
    </>
  );
};

const mapStateToProps = state => ({
  commonState: state.CommonState
});


export default connect(mapStateToProps, null)(ProductImages);