import React from 'react';
import { Button, Image } from "react-bootstrap";
import { SortBy } from '../../utils/Constant';
import backArrow from '../../assets/icons/back-arrow.svg';

const MobileSort = props => {

  const handleSortClick = (value) => {
    const params = new URLSearchParams(props.history.location.search);
    params.set("sort_by", value);
    props.history.replace({
      pathname: props.history.location.pathname,
      search: params.toString()
    })
    // Close mobile Sort
    props.handleClose();
  }

  return (
    <div className="mobile-filter-wrapper">
      <div className="mobile-filter">
        <div className="filter-title">
          <Image src={backArrow} onClick={props.handleClose}
            className="back-icon" />Sort</div>
        <div className="sort-item-wrapper">
          <ul>
            {
              SortBy.map(item => (
                <li key={item.value}>
                  <Button className="paragraph-desktop" onClick={() => handleSortClick(item.value)}>{item.label}</Button>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileSort;