import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { CustomNavbar } from '../common';
import { Form, FormElement, FormValidator, CustomTextControl, FormSubmitButton, BaseReactComponent } from '../../utils/form';
import TextAreaControl from '../../utils/form/CustomTextAreaControl';
import axios from 'axios';
import { toast } from 'react-toastify';

class Contact extends BaseReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      companyName: "",
      phone: "",
      message: ""
    }
  }

  onValidSubmit = () => {
    const data = {
      name: this.state.name,
      email: this.state.email,
      companyName: this.state.companyName,
      phone: this.state.phone,
      message: this.state.message
    }

    const toastId = toast.info("Sending Message. Please wait...", { autoClose: false });
    const url = "https://script.google.com/macros/s/AKfycbwsJ1zuHtr6yMzmf8i9v07ESklaNEMLhPY2G-AJNoB4JhxW3FzE/exec";

    axios.get(url, { params: data })
      .then(res => {
        if (res.data.result === 'success') {
          toast.update(toastId, {
            render: 'Message Received Successfully!',
            type: toast.TYPE.SUCCESS,
            autoClose: 6000
          });
          this.setState({
            name: "",
            email: "",
            phone: "",
            companyName: "",
            message: ""
          });
        } else {
          toast.update(toastId, {
            render: 'Please try again!',
            type: toast.TYPE.ERROR,
            autoClose: 6000
          });
        }
      })
      .catch(err => {
        toast.update(toastId, {
          render: 'Please try again!',
          type: toast.TYPE.ERROR,
          autoClose: 6000
        });
      })

  }


  render() {
    return (
      <>
        <CustomNavbar />
        <section className="contact">
          <Grid>
            <Row>
              <Col sm={6}>
                <h2 className="h1">How Can We Help?</h2>
                <p>
                  For any inquires regarding existing orders, please email us at <span>order@jewelfleet.com</span> with your Order ID number.
                </p>
                <p>
                  For any inquiries or questions regarding our service, please email us at <span>customerservice@jewelfleet.com</span>
                </p>
              </Col>
              <Col sm={5} smPush={1}>
                <h2 className="h1">Leave a Message</h2>
                <Form onValidSubmit={this.onValidSubmit}>
                  <FormElement
                    valueLink={this.linkState(this, "name")}
                    label="Name"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Name is mandatory"
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter your Name",
                      }
                    }}
                  />

                  <FormElement
                    valueLink={this.linkState(this, "email")}
                    label="Email"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Email is mandatory"
                      },
                      {
                        validate: FormValidator.isEmail,
                        message: "Invalid Email"
                      }
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter your Email",
                      }
                    }}
                  />

                  <FormElement
                    valueLink={this.linkState(this, "companyName")}
                    label="Company Name"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Company Name is mandatory"
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Company Name"
                      }
                    }}
                  />

                  <FormElement
                    valueLink={this.linkState(this, "phone")}
                    label="Phone (optional)"
                    validations={[
                      {
                        validate: FormValidator.isPhoneOptional,
                        message: "Phone number is invalid"
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Phone number"
                      }
                    }}
                  />

                  <FormElement
                    valueLink={this.linkState(this, "message")}
                    label="Message"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Message is mandatory"
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Message",
                      }
                    }}
                  />

                  <FormSubmitButton customClass="custom-btn" formError={this.state.inProgress}
                    progressMessage="Validating..">
                    SEND
                </FormSubmitButton>

                </Form>
              </Col>
            </Row>
          </Grid>
        </section>
      </>
    )
  }
}

export default Contact;