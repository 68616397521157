import axios from 'axios';

const PreLoginInstance = axios.create(
  {
    baseURL: process.env.REACT_APP_API_BASEURL,
    // baseURL: 'http://sweb.jewelfleet.com/api/',  // Url for Dev
    // baseURL: 'https://api.jewelfleet.com/api/',  // Url for Prod
  }
);

export default PreLoginInstance;
