import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import DiamondShapesList from './DiamondShapesList';
import { FilterProductsBy } from '../../../utils/Constant';
import { withRouter } from 'react-router-dom';

const NavbarMenu = ({ categoryId, categoryName, tags, closeHandle, ...props }) => {
  const tagsInOneColumn = Math.ceil(tags.length / 3);
  const col1 = tags.slice(0, tagsInOneColumn);
  const col2 = tags.slice(tagsInOneColumn, tagsInOneColumn * 2);
  const col3 = tags.slice(tagsInOneColumn * 2, tags.length);
  // const col4 = tags.slice(tagsInOneColumn * 3, tags.length);
  const arrangedTags = [col1, col2, col3];

  return (
    <div className="custom-navbar-menu" onClick={e => e.stopPropagation()}>
      <div className="tag-names-wrapper">
        {
          arrangedTags.map((column, columnIndex) => (
            <div className="tag-column" key={columnIndex}>
              {
                column.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <Image
                      onClick={e => {
                        e.stopPropagation();
                        closeHandle();
                        props.history.push(`/products/${categoryName.replace(" ", "-")}/${tag.permalink}`);
                      }}
                      src={tag.attachment_url}
                      className="tag-image" />
                    <Link onClick={e => { e.stopPropagation(); closeHandle() }} to={`/products/${categoryName.replace(" ", "-")}/${tag.permalink}`}>{tag.name}</Link>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
      <div className="extra-links-wrapper">
        <Link onClick={e => { e.stopPropagation(); closeHandle() }} to={`/products/sorted-category/${categoryId}-${FilterProductsBy.NEWLY_ADDED}`}>Newly added</Link>
        <Link onClick={e => { e.stopPropagation(); closeHandle() }} to={`/products/sorted-category/${categoryId}-${FilterProductsBy.RECENTLY_PURCHASED}`}>Recently Purchased</Link>
        <Link onClick={e => { e.stopPropagation(); closeHandle() }} to={`/products/sorted-category/${categoryId}-${FilterProductsBy.TOP_25}`}>top 25</Link>
      </div>
      <div className="menu-stone-shapes">
        <h6 className="dark-desktop">SHOP BY STONE SHAPE</h6>
        <DiamondShapesList categoryId={categoryId} />
      </div>
    </div>
  );
};

export default withRouter(NavbarMenu);