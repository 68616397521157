import { combineReducers } from 'redux';
import HomeReducer from '../app/home/HomeReducer';
/* PLOP_INJECT_REDUCER_IMPORT */
import { MarkupsReducer } from '../app/markups';
import { SearchReducer } from '../app/search';
import { ProfileReducer } from '../app/profile';
import { DigitalStoreReducer } from '../app/digitalStore';
import { OrderListReducer } from '../app/orderList';
import { AddressReducer } from '../app/address';
import { ProductListReducer } from '../app/productList';
import { SignupReducer } from '../app/signup';
import { ProductDetailsReducer } from '../app/productDetails';
import { CommonReducer } from '../app/common';

export default combineReducers({
  CommonState: CommonReducer,
  HomeState: HomeReducer,
  /* PLOP_INJECT_REDUCER */
	MarkupsState: MarkupsReducer,
  SearchState: SearchReducer,
  ProfileState: ProfileReducer,
  DigitalStoreState: DigitalStoreReducer,
  OrderListState: OrderListReducer,
  AddressState: AddressReducer,
  ProductListState: ProductListReducer,
  SignupState: SignupReducer,
  ProductDetailsState: ProductDetailsReducer,
});