const TOGGLE_LOGIN_SIDEBAR = 'TOGGLE_LOGIN_SIDEBAR';
const TOGGLE_CART = 'TOGGLE_CART';
const CATEGORY_DATA = 'CATEGORY_DATA';
const TOGGLE_CUSTOMIZATION_OPTION = 'TOGGLE_CUSTOMIZATION_OPTION';
const TOGGLE_FORGOT_PASSWORD_SIDEBAR = 'TOGGLE_FORGOT_PASSWORD_SIDEBAR';
const EMAIL_SENT_MODAL = 'EMAIL_SENT_MODAL';
const CART_DATA = 'CART_DATA';
const TOGGLE_EDIT_CART = 'TOGGLE_EDIT_CART';
const UPDATE_VALUES_FROM_SERVER = 'UPDATE_VALUES_FROM_SERVER';

export {
  TOGGLE_LOGIN_SIDEBAR,
  CATEGORY_DATA,
  TOGGLE_CART,
  TOGGLE_CUSTOMIZATION_OPTION,
  TOGGLE_FORGOT_PASSWORD_SIDEBAR,
  EMAIL_SENT_MODAL,
  CART_DATA,
  TOGGLE_EDIT_CART,
  UPDATE_VALUES_FROM_SERVER
}
