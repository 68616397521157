import React from 'react';
import { connect } from 'react-redux';
import { BaseReactComponent, Form, FormElement, FormValidator, CustomTextControl, FormSubmitButton } from '../../utils/form';
import { Modal, Col, Row } from 'react-bootstrap';
import { AddressType } from '../../utils/Constant';
import { AddUpdateAddress } from './Api';

class AddEditAddress extends BaseReactComponent {
  constructor(props) {
    super(props);
    const {
      id = "",
      name: addressName = "",
      phone_number: phoneNumber = "",
      street = "",
      city = "",
      store_name: storeName = "",
      state = "", pincode: zipcode = "" } = this.props.address || {};

    this.state = {
      id,
      addressName,
      phoneNumber,
      storeName,
      street,
      city,
      state,
      zipcode,
    }
  }

  componentDidMount() { }

  onSubmit = () => {
    const { id, addressName, phoneNumber, storeName, street, city, state, zipcode } = this.state;
    const data = new URLSearchParams();
    // In case of edit add id
    if (this.props.address) {
      data.append("id", id);
    }
    data.append("type", AddressType.SHIPPING);
    data.append("name", addressName);
    data.append("phone_number", Number(phoneNumber));
    data.append("store_name", storeName);
    data.append("street", street);
    data.append("city", city);
    data.append("state", state);
    data.append("pincode", Number(zipcode));
    this.props.AddUpdateAddress(data, this.props.handleClose);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{this.props.address ? 'Edit ' : 'Add '}Shipping Address</Modal.Title>
        </Modal.Header>
        <Form onValidSubmit={this.onSubmit}>
          <Modal.Body>

            <FormElement
              valueLink={this.linkState(this, "addressName")}
              label="Save Address As"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "Field is required"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "e.g. Walnut Ave Store",
                }
              }}
            />
            <FormElement
              valueLink={this.linkState(this, "phoneNumber")}
              label="Phone Number"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "Field is required"
                },
                {
                  validate: FormValidator.isPositiveInt,
                  message: "Field should be a number"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter Phone Number",
                }
              }}
            />
            <FormElement
              valueLink={this.linkState(this, "storeName")}
              label="Store Name"
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "Field is required"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter Store Name",
                }
              }}
            />
            <FormElement
              valueLink={this.linkState(this, "street")}
              label="Street / Post box no."
              required
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "Field is required"
                }
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  placeholder: "Enter Street / Post box no.",
                }
              }}
            />

            <Row>
              <Col sm={6}>
                <FormElement
                  valueLink={this.linkState(this, "city")}
                  label="City"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field is required"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter City Name",
                    }
                  }}
                />
              </Col>
              <Col sm={6}>
                <FormElement
                  valueLink={this.linkState(this, "state")}
                  label="State"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field is required"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter State Name",
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormElement
                  valueLink={this.linkState(this, "zipcode")}
                  label="Zipcode"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field is required"
                    },
                    {
                      validate: FormValidator.isPositiveInt,
                      message: "Field should be a number"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter Zip Code",
                    }
                  }}
                />
              </Col>
            </Row>

            {
              this.state.errorMessage && <p className="error-text">{this.state.errorMessage}</p>
            }
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={this.handleClose}>Add</Button> */}
            <FormSubmitButton
              customClass="custom-btn"
              formError={this.state.inProgress}
              progressMessage="Validating.." fullWidth
            >
              {this.props.address ? 'Save' : 'Add'}
            </FormSubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  // ...state
});
const mapDispatchToProps = {
  AddUpdateAddress
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditAddress);