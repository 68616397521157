import { getAddresses } from "./AddressAction";
import { PostLoginInstance } from "../../utils";
import { AddressType } from "../../utils/Constant";

export const getAddressByType = (type = AddressType.SHIPPING) => {
  const data = new URLSearchParams();
  data.append("address_type", type);
  return function (dispatch) {
    return PostLoginInstance.post('customer/account/get-addresses-by-type', data)
      .then(res => {
        if (!res.data.error) {
          dispatch(getAddresses(res.data.data));
        } else {
          console.log("Error ", res.data.error)
        }
      }).catch(err => {
        console.log("error", err);
      });
  };
}

export const AddUpdateAddress = (data, closeModal) => {
  return function (dispatch) {
    return PostLoginInstance.post('customer/account/add-update-address', data)
      .then(res => {
        if (!res.data.error) {
          closeModal();
          dispatch(getAddressByType());
        } else {
          console.log("Error ", res.data.error)
        }
      }).catch(err => {
        console.log("error", err);
      });
  };
}


export const deleteAddressApi = (data, closeModal) => {
  return function (dispatch) {
    return PostLoginInstance.post('customer/account/delete-address', data)
      .then(res => {
        if (!res.data.error) {
          closeModal();
          dispatch(getAddressByType());
        } else {
          console.log("Error ", res.data.error)
        }
      }).catch(err => {
        console.log("error", err);
      });
  };
}