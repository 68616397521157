import React, { useState, useEffect } from 'react';
import { CustomNavbar, CustomButton } from '../common';
import { Grid } from 'react-bootstrap';
import { verifyEmailApi } from './Api';

const VerifyEmail = props => {

  const [msg, setMsg] = useState('Verifying email...');


  // did mount
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");

    if (token) {
      const data = new URLSearchParams();
      data.append("token", token);
      verifyEmailApi(data, verifyEmailCallback);
    } else {
      // when token is not found
      setMsg("Please click on the link provided in the email.");
    }
  }, [props.location.search]);



  const verifyEmailCallback = (msg) => {
    setMsg(msg);
  }

  return (
    <>
      <CustomNavbar />

      <section className="verify-email">
        <Grid>
          <h2 className="h2-desktop">{msg}</h2>
          <CustomButton href="/">Go to Home</CustomButton>
        </Grid>
      </section>
    </>
  );
};

export default VerifyEmail;