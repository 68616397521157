import React, { Component } from 'react';
import { connect } from "react-redux";
import { CustomNavbar, PageHeader, Pagination, ProductItem } from '../common';
import { Grid } from "react-bootstrap";
import newArrivalYellow from '../../assets/images/new-arrival-product-1.png';
import newArrivalPink from '../../assets/images/new-arrival-product-2.png';
import { getPageCount } from '../../utils/ReusableFunctions';
import { getPreviousOrdersApi } from './Api';
import { Link } from 'react-router-dom';

class PreviousOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      totalPages: null,
      breadcrumbData: [
        {
          text: 'home',
          url: '/'
        },
        {
          text: 'Profile',
          url: '/profile'
        },
        {
          text: 'Previously Ordered Styles',
          active: true
        },
      ]
    }
  }

  componentDidMount() {
    this.getPreviousOrders();
  }

  componentDidUpdate(prevProps) {
    // search string inside location is unique for every new params in routes.
    if (this.props.location.search !== prevProps.location.search) {
      // Call Favourite data when page number changes
      this.getPreviousOrders();
    }
  }

  getPreviousOrders = () => {
    const data = new URLSearchParams(this.props.location.search);
    data.append('page_no', data.get("p") || 1);
    getPreviousOrdersApi(data, this.previousOrdersCallback);
  }

  previousOrdersCallback = (data) => {
    this.setState({
      orders: data.products,
      totalPages: getPageCount(data.total_product_count)
    });
  }

  render() {
    const { totalPages, orders } = this.state;
    const params = new URLSearchParams(this.props.location.search);
    return (
      <>
        <CustomNavbar />
        <Grid>
          <PageHeader title="Previously Ordered Styles" breadcrumbData={this.state.breadcrumbData} />
          <div className="digital-store">
            {
              orders ?
                orders.map((product) => (
                  <Link to={`/product/${product.permalink}`} key={product.id}>
                    <ProductItem product={product} />
                  </Link>
                ))
                :
                null
            }
          </div>
          {
            totalPages && totalPages > 1 &&
            <Pagination
              currentPage={params.get("p") ? Number(params.get("p")) : 1}
              totalPages={totalPages}
              history={this.props.history}
            />
          }
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  // digitalStoreState: state.DigitalStoreState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousOrders);