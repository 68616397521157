import React from 'react';
import { Image } from 'react-bootstrap';
import backArrow from '../../../assets/icons/back-arrow.svg';
import { BreadCrumb } from '..';
import { withRouter } from 'react-router-dom';

const PageHeader = ({ breadcrumbData, title, history, showBackArrow = true }) => {
  return (
    <div className="custom-page-header">
      {
        showBackArrow ?
          <div className="arrow-wrapper">
            <Image src={backArrow} onClick={history.goBack} />
          </div>
          : null
      }
      <div className="header-content-wrapper">
        <h2 className="h2-desktop">{title}</h2>
        {
          breadcrumbData &&
          <BreadCrumb data={breadcrumbData} />
        }
      </div>
    </div>
  );
};

export default withRouter(PageHeader);