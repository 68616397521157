import { GET_HOME_PAGE_DATA } from "./ActionTypes";

const INITIAL_STATE = {
  homeData: null
};

const HomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOME_PAGE_DATA:
      return {
        ...state,
        homeData: action.payload
      }
    default:
      return state
  }
};

export default HomeReducer