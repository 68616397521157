import { PreLoginInstance } from "../../utils";
import { toast } from 'react-toastify';


export const setPasswordApi = (data, cb) => {
  PreLoginInstance.post('/customer/account/reset-user-password', data)
    .then(res => {
      if (!res.data.error) {
        cb();
      } else {
        toast.error(res.data.message || "Something went wrong");
      }
    })
    .catch(err => {
      console.log('error', err);
      toast.error(err.response.data.message);
    });
}

