import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { CustomButton, OrderStatus } from '../../common';
import { Link } from 'react-router-dom';

const OrderItem = ({ order }) => {
  return (
    <div className="order-item">
      <div className="order-item-header">
        <Row>
          <Col xs={6} sm={6} md={8} >
            <h5>Order placed on</h5>
            <h6>{order.created_on}</h6>
          </Col>
          <Col xs={6} sm={3} md={2} >
            <OrderStatus status={order.status_code} />
          </Col>
          <Col xs={12} sm={3} md={2} >
            <h5>Order Id</h5>
            <h6>{order.code}</h6>
          </Col>
        </Row>
      </div>
      <div className="order-item-body">
        <Row>
          <Col md={10}>
            <div className="images-wrapper">
              {
                order.products.slice(0, 6).map((image, index) => (
                  <Image key={index} src={image} />
                ))
              }
              {
                order.products.length > 6 &&
                <Link className="more-images" to={`/profile/order/${order.code}`}>
                  {order.products.length - 6} more
                </Link>

              }
            </div>
          </Col>
          <Col md={2}>
            <CustomButton href={`/profile/order/${order.code}`} primary>Track your order</CustomButton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderItem;